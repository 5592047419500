<template>
  <div class="research_info_wrap">
    <navbar_ :Lucency="false" :isBlack="true" :activeIndex="'/aboutUs'" />

    <div class="info_container">
      <div class="content_box" v-if="infoList.enableStatus == 1">
        <div class="head_box">
          <div class="title">{{ infoList.title }}</div>

          <div class="time">{{ infoList.createTime }}</div>
        </div>

        <div class="content" v-html="infoList.content"></div>

        <div class="mianze">
          免责声明<br />
          以上内容（包括但不限于图片、文章、音视频等）及操作仅供您参考和学习使用，不构成投资建议，不具有投资咨询性质，不构成对任何金融产品的购买、抛售或持有的建议，不作为您做出任何投资的依据，不承诺您获得投资收益，本平台不作任何担保。您应自主做出投资决策，自行承担投资风险和损失，投资有风险，入市需谨慎！
        </div>
      </div>

      <div class="empty_box" v-if="infoList.enableStatus == 0">
        <el-empty description="内容已下架 ~" />
      </div>
    </div>

    <footer_ />
  </div>
</template>

<script>
import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部

export default {
  data() {
    return {
      infoList: {},
    };
  },
  components: {
    navbar_,
    footer_,
  },
  created() {
    this.getResearchInfoList(); // 获取 研学 list
  },
  mounted() {},
  methods: {
    // 获取 研学 详情
    getResearchInfoList() {
      let data = {
        id: this.$route.query.id,
      };

      this.API.CLOUDGet(
        "cms/api/website/material/research-material",
        data
      ).then((res) => {
        // console.log(res);
        let datas = res.data.data;
        console.log(datas);

        this.infoList = datas;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.research_info_wrap {
  padding-top: 60px;

  .info_container {
    width: 980px;
    min-height: calc(100vh - 60px);
    box-sizing: border-box;
    margin: 0 auto;

    .content_box {
      padding: 36px 0;

      .head_box {
        padding-bottom: 18px;
        margin-bottom: 36px;
        text-align: center;
        border-bottom: 1px solid #f3f3f3;

        .title {
          font-size: 20px;
          font-weight: 700;
          color: #1285ff;
        }

        .time {
          font-size: 14px;
          color: #c8c8c8;
          margin-top: 12px;
        }
      }

      .content {
        font-size: 16px;
        line-height: 28px;
        color: #000;
        text-align: justify;

        /deep/img {
          width: 100%;
          height: auto;
        }
      }

      .mianze {
        margin-top: 24px;
        font-size: 14px;
        border-radius: 10px;
        color: #b6b6b6;
        line-height: 24px;
        background: #f8faf9;
        padding: 8px 12px;
        text-align: justify;
        box-sizing: border-box;
      }
    }

    .empty_box {
      min-height: calc(100vh - 120px);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>