<template>
  <div class="index_wrap">
    <navbar_ />

    <el-carousel
      class="index_carousel"
      ref="index_carousel"
      trigger="click"
      :height="screenHeight + 'px'"
      direction="vertical"
      :autoplay="false"
      @change="handleCarouselChange"
    >
      <el-carousel-item>
        <screen :screenHeight="screenHeight" @carousel_next="next" />
      </el-carousel-item>

      <el-carousel-item>
        <about v-if="aboutShow" />
      </el-carousel-item>

      <el-carousel-item>
        <product v-if="productShow" />
      </el-carousel-item>

      <el-carousel-item>
        <system v-if="systemShow" />
      </el-carousel-item>

      <el-carousel-item>
        <teacher v-if="teacherShow" />
      </el-carousel-item>

      <el-carousel-item>
        <footer_ />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import screen from "@/views/index/screen/index.vue"; // 首屏
import about from "@/views/index/about/index.vue"; // 关于益学 / 发展历程
import product from "@/views/index/product/index.vue"; // 核心产品
import system from "@/views/index/system/index.vue"; // 五大交易体系
import teacher from "@/views/index/teacher/index.vue"; // 专业团队

import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部

export default {
  data() {
    return {
      screenHeight: window.innerHeight, // 初始化屏幕高度
      carouselIndex: 0, // 初始索引
      debounceTimer: null,

      aboutShow: true,
      productShow: true,
      systemShow: true,
      teacherShow: true,
    };
  },
  components: {
    screen,
    about,
    product,
    system,
    teacher,
    navbar_,
    footer_,
  },
  created() {},
  mounted() {
    window.addEventListener("resize", this.updateScreenHeight); // 监听窗口大小变化事件

    this.addWheelListener();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateScreenHeight); // 组件销毁前移除事件监听器

    this.removeWheelListener();
  },
  deactivated() {
    this.removeWheelListener();
  },
  methods: {
    // 屏幕高度
    updateScreenHeight() {
      this.screenHeight = window.innerHeight; // 更新屏幕高度
    },

    // 滚动 滚动条切换 轮播
    addWheelListener() {
      const carousel = this.$refs.index_carousel;
      if (carousel) {
        carousel.$el.addEventListener("wheel", this.throttledHandleWheel());
      }
    },
    removeWheelListener() {
      const carousel = this.$refs.index_carousel;
      if (carousel) {
        carousel.$el.removeEventListener("wheel", this.throttledHandleWheel());
      }
    },

    // 防抖处理后的滚动事件方法
    debouncedHandleWheel() {
      return this.debounce(this.handleWheel, 300);
    },
    // 节流处理后的滚动事件方法
    throttledHandleWheel() {
      return this.throttle(this.handleWheel, 1000);
    },

    handleWheel(event) {
      // console.log(event);

      // 清除之前的定时器（如果有）
      // clearTimeout(this.debounceTimer);

      // // 设置一个新的定时器，延迟执行切换动作
      // this.debounceTimer = setTimeout(() => {
      //   if (event.deltaY < 0) {
      //     if (this.carouselIndex == 0) return;

      //     this.prev(); // 上一个
      //   } else {
      //     if (this.carouselIndex == 5) return;

      //     this.next(); // 下一个
      //   }
      // }, 300); // 防抖延迟的时间

      if (event.deltaY < 0) {
        if (this.carouselIndex == 0) return;

        this.prev(); // 上一个
      } else {
        if (this.carouselIndex == 5) return;

        this.next(); // 下一个
      }
    },

    // 上一个
    prev() {
      this.$refs.index_carousel.prev();
    },
    // 下一个
    next() {
      this.$refs.index_carousel.next();
    },

    handleCarouselChange(current) {
      // console.log(current);

      this.carouselIndex = current;

      switch (current) {
        case 1:
          this.aboutShow = false;
          this.$nextTick(() => {
            this.aboutShow = true;
          });
          break;
        case 2:
          this.productShow = false;
          this.$nextTick(() => {
            this.productShow = true;
          });
          break;
        case 3:
          this.systemShow = false;
          this.$nextTick(() => {
            this.systemShow = true;
          });
          break;
        case 4:
          this.teacherShow = false;
          this.$nextTick(() => {
            this.teacherShow = true;
          });
          break;
      }

      // 轮播图切换时手动刷新 aos
      // if (window.AOS) {
      //   this.$nextTick(() => {
      //     window.AOS.init();
      //     window.AOS.refresh();
      //     console.log(window.AOS);
      //   });
      // }
    },

    // 防抖函数
    debounce(func, wait) {
      let timeout;
      return function (...args) {
        const context = this;
        clearTimeout(timeout);
        timeout = setTimeout(() => {
          func.apply(context, args);
        }, wait);
      };
    },

    // 节流函数
    throttle(func, limit) {
      let inThrottle;
      return function (...args) {
        const context = this;
        if (!inThrottle) {
          func.apply(context, args);
          inThrottle = true;
          setTimeout(() => (inThrottle = false), limit);
        }
      };
    },
  },
};
</script>

<style lang="less" scoped>
.index_wrap {
  .index_carousel {
    /deep/.el-carousel__indicators {
      .el-carousel__indicator--vertical {
        padding: 3px 12px;

        .el-carousel__button {
          width: 4px;
          height: 4px;
          border-radius: 4px;
        }

        &.is-active {
          button {
            height: 16px;
          }
        }
      }
    }
  }
}
</style>