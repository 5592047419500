<template>
  <div class="qyzh_wrap">
    <div class="head_box">
      <img src="@/assets/about_us/contact/lxfs/bg1.png" />

      <div class="title">企业账户</div>
    </div>

    <div class="qyzh_container">
      <div class="qyzh_box">
        <div class="list_box">
          <img src="@/assets/about_us/contact/qyzh/icon1.png" class="icon" />

          <div class="item_box">
            <div class="name">户名：</div>
            <div class="text">上海益学智迅科技发展有限公司</div>
          </div>
        </div>

        <div class="list_box list_box1">
          <img src="@/assets/about_us/contact/qyzh/icon3.png" class="icon" />

          <div class="item_box">
            <div class="name">招商银行行号：</div>
            <div class="text">308290003564</div>
          </div>
        </div>
      </div>

      <div class="qyzh_box qyzh_box1">
        <div>
          <div class="list_box">
            <img src="@/assets/about_us/contact/qyzh/icon2.png" class="icon" />

            <div class="item_box">
              <div class="name">开户行：</div>
              <div class="text">招商银行上海田林支行</div>
            </div>
          </div>

          <div class="list_box list_box1">
            <img src="@/assets/about_us/contact/qyzh/icon4.png" class="icon" />

            <div class="item_box">
              <div class="name">银行账号：</div>
              <div class="text">
                121925633110404 (请按销售引导 打入指定账户)
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.qyzh_wrap {
  .head_box {
    position: relative;

    img {
      width: 100%;
      display: block;
    }

    .title {
      font-size: 30px;
      color: #fff;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .qyzh_container {
    background: url("@/assets/about_us/contact/qyzh/bg2.png") no-repeat #fff;
    background-size: 100%;
    padding: 160px 0;
    display: flex;
    justify-content: center;

    .qyzh_box {
      width: 30%;

      .list_box {
        display: flex;
        align-items: center;

        .item_box {
          margin-left: 25px;

          .name {
            font-size: 16px;
          }

          .text {
            margin-top: 10px;
            font-size: 12px;
            line-height: 20px;
          }
        }

        &.list_box1 {
          margin-top: 150px;
        }
      }

      &.qyzh_box1 {
        margin-left: 30px;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
}

@media (min-width: 1921px) {
  .qyzh_wrap {
    .qyzh_container {
      padding: 240px 0;
    }
  }
}
</style>
