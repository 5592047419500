<template>
  <div></div>
</template>

<script>
export default {
  data() {
    return {
      isLogin: localStorage.getItem("yx_isLogin") || false,
      userInfo: JSON.parse(localStorage.getItem("yx_userInfo")) || null,

      websock: null,
      reconnectData: null,
      lockReconnect: false, //避免重复连接，因为onerror之后会立即触发 onclose
      timeout: 60000, //60s一次心跳检测
      timeoutObj: null,
      serverTimeoutObj: null,

      notify: null,
    };
  },
  created() {
    if (this.isLogin) {
      this.notify = new Notify({
        effect: "scroll",
        interval: 500,
        disableFavicon: true,
      });

      // 判断浏览器弹框通知是否被阻止
      console.log("浏览器弹框通知是否被阻止：" + this.notify.isPermission());
      if (!this.notify.isPermission()) {
        return;
      }

      this.buyedServicesTokenGen();
    }
  },
  methods: {
    // 初始化weosocket
    initWebSocket() {
      console.log("启动中");
      // let wsurl = `ws://172.20.10.13:9099/h5ws?userNo=${this.userInfo.userNo}&channel=sellsAll`;
      let wsurl = `wss://yxcloudsocket.yxcps.cn/h5ws?userNo=${this.userInfo.userNo}&channel=sellsAll`;
      this.websock = new WebSocket(wsurl);
      this.websock.onopen = this.websocketonopen; //连接成功
      this.websock.onmessage = this.websocketonmessage; //广播成功
      this.websock.onerror = this.websocketonerror; //连接断开，失败
      this.websock.onclose = this.websocketclose; //连接关闭
    },
    // 连接成功
    websocketonopen() {
      console.log("连接成功");
      this.heatBeat();
    },
    // 连接失败
    websocketonerror() {
      console.log("连接失败");
      this.reconnect();
    },
    // 各种问题导致的 连接关闭
    websocketclose() {
      console.log("断开连接");
      this.reconnect();
    },
    // 数据接收
    websocketonmessage(data) {
      this.heatBeat(); //收到消息会刷新心跳检测，如果一直收到消息，就推迟心跳发送

      // if (data.data == "Hello world!") {
      //   return;
      // }
      // let msgData = JSON.parse(data.data);
      // console.log(msgData);

      this.receive(data);
    },
    // 数据发送
    websocketsend(data) {
      this.websock.send(JSON.stringify(data));
    },
    // socket重连
    reconnect() {
      if (this.lockReconnect) {
        //这里很关键，因为连接失败之后之后会相继触发 连接关闭，不然会连接上两个 WebSocket
        return;
      }
      this.lockReconnect = true;
      this.reconnectData && clearTimeout(this.reconnectData);
      this.reconnectData = setTimeout(() => {
        this.initWebSocket();
        this.lockReconnect = false;
      }, 40000);
    },
    // 心跳检测
    heatBeat() {
      this.timeoutObj && clearTimeout(this.timeoutObj);
      this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
      this.timeoutObj = setTimeout(() => {
        this.websocketsend({ type: "心跳检测" }); //根据后台要求发送
        this.serverTimeoutObj = setTimeout(() => {
          this.websock.close(); //如果  40秒之后我们没有收到 后台返回的心跳检测数据 断开socket，断开后会启动重连机制
        }, 40000);
      }, this.timeout);
    },

    // 浏览器 通知
    receive(data) {
      let that = this;

      if ("Hello world!" !== data.data) {
        let msg = JSON.parse(data.data);
        console.log(msg);

        let redirect = this.productList.find(
          (item) => item.sellsId === msg.channel
        );

        console.log(redirect);
        // console.log(this.notify);

        if (redirect && redirect.pcredirect) {
          this.notify.setTitle("您有一条新消息，请注意查看");

          let domParser = new DOMParser();
          let doc = domParser.parseFromString(msg.content, "text/html");
          let body = doc.body.textContent || "";

          // https://stocksf.yxcps.com/teaching_pc_hncl3_bbwy_1/home?subtabId=jx&contentId=cdf1d551885c4014a3fc88817eeea433 步步为赢

          let redirectUrl = redirect.pcredirect;
          if (redirectUrl.includes("?")) {
            // 如果包含 ?，则使用 & 添加参数
            redirectUrl = `${redirectUrl}&subtabId=jx&contentId=${msg.teachContentId}`;
          } else {
            // 如果不包含 ?，则使用 ? 添加参数
            redirectUrl = `${redirectUrl}?subtabId=jx&contentId=${msg.teachContentId}`;
          }

          console.log("跳转链接：" + redirectUrl);

          this.notify.notify({
            title: msg.title,
            body: body,
            openurl: redirectUrl,
            onclick: function () {
              console.log("notify click");

              // let tsInput = document.createElement("input");
              // tsInput.setAttribute("type", "hidden");
              // tsInput.setAttribute("name", "ts");
              // tsInput.setAttribute("value", new Date().getTime());

              // let formId = "redirectForm_" + new Date().getTime();
              // let redirectForm = document.createElement("form");
              // redirectForm.action = redirectUrl;
              // redirectForm.id = formId;
              // redirectForm.target = "_blank";
              // redirectForm.method = "get";
              // document.body.appendChild(redirectForm);

              // document.getElementById(formId).appendChild(tsInput);
              // redirectForm.submit();
            },
          });

          this.notify.player();

          setTimeout(function () {
            that.notify.setTitle();
          }, 10000);
        }
      }
      // console.log("websocket 接收消息" + data.data);
    },

    // 获取 用户 有权限的 产品
    buyedServicesTokenGen() {
      let data = {
        userNo: this.userInfo.userNo,
      };

      this.API.CLOUDGet("stock-common/api/sells/sells_list", data).then(
        (res) => {
          // console.log(res);
          let datas = res.data.data;
          // console.log(datas);

          let list = datas.find((item) => item.categoryName === "已购服务");

          this.productList = list.list.filter((item) => item.pcredirect);

          // console.log(this.productList);

          this.initWebSocket();
        }
      );
    },
  },
  destroyed() {
    this.lockReconnect = true;
    this.websock.close(); //离开路由之后断开websocket连接
    clearTimeout(this.reconnectData); //离开清除 timeout
    clearTimeout(this.timeoutObj); //离开清除 timeout
    clearTimeout(this.serverTimeoutObj); //离开清除 timeout
  },
};
</script>