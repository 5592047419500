<template>
  <div class="fxpc_wrap">
    <div class="title">风险评测</div>

    <div class="container_box">
      <div class="container">
        <div class="title_box">风险评测</div>

        <div class="form_box">
          <div class="noevaluated_box" v-if="isLevel == 1">
            <img src="@/assets/home/fxpc/fxpc.png" class="icon" />

            <div class="text">暂无评测数据，点击去评测</div>

            <div class="btn_box">
              <div class="btn" @click="levelBtnClick()">开始评测</div>

              <div class="btn" @click="refreshClick()">如已测评，点击刷新</div>
            </div>
          </div>

          <div class="evaluated_box" v-if="isLevel == 2">
            <div class="level_box">
              <img :src="levelItem.img_bg" />

              <div class="info_box">
                <div class="level">您的风险等级为 {{ levelItem.level }}</div>

                <div class="type">【{{ levelItem.type }}】风险等级</div>
              </div>
            </div>

            <div class="btn_box">
              <div class="btn" @click="levelBtnClick()">重新评测</div>

              <div class="btn" @click="refreshClick()">如已测评，点击刷新</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("yx_userInfo")) || null,

      isLevel: 0,
      levelItem: {},
      levelList: [
        {
          level: "C1",
          type: "保守型",
          img_bg: require("@/assets/home/fxpc/1.png"),
        },
        {
          level: "C2",
          type: "谨慎型",
          img_bg: require("@/assets/home/fxpc/2.png"),
        },
        {
          level: "C3",
          type: "稳健型",
          img_bg: require("@/assets/home/fxpc/3.png"),
        },
        {
          level: "C4",
          type: "积极型",
          img_bg: require("@/assets/home/fxpc/4.png"),
        },
        {
          level: "C5",
          type: "激进型",
          img_bg: require("@/assets/home/fxpc/5.png"),
        },
      ],

      isRefresh: false,
    };
  },
  created() {
    this.getUserAuthInfo(); // 获取 是否 评测
  },
  mounted() {},
  methods: {
    // 获取 是否 评测
    getUserAuthInfo() {
      let data = {
        userNo: this.userInfo.userNo,
      };

      this.API.CLOUDGet("account/api/user/security/userAuthInfo", data).then(
        (res) => {
          // console.log(res);
          let datas = res.data.data;
          // console.log(datas);

          if (datas.level) {
            this.levelItem = this.levelList.find(
              (item) => item.level === datas.level
            );
            this.isLevel = 2;
          } else {
            this.isLevel = 1;
          }

          if (this.isRefresh) {
            this.isRefresh = false;

            ElMessage({
              message: "刷新成功",
              type: "success",
            });
          }
        }
      );
    },

    // 开始评测 / 重新评测
    levelBtnClick() {
      let url =
        "//stocksf.yxcps.com/qsapp/userRisk/#/evaluating/pc/" +
        btoa(this.userInfo.userNo) +
        "?t=" +
        new Date().getTime();

      window.open(url);
    },

    // 已评测，刷新
    refreshClick() {
      this.isRefresh = true;

      this.getUserAuthInfo(); // 获取 是否 评测
    },
  },
};
</script>

<style lang="less" scoped>
.fxpc_wrap {
  min-height: calc(100vh - 60px);
  background: #eff1f3;

  .title {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: bold;
    background: #fff;
    padding-left: 28px;
  }

  .container_box {
    min-height: calc(100vh - 110px);
    padding: 16px;
    box-sizing: border-box;

    .container {
      min-height: calc(100vh - 142px);
      padding: 30px 20px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;

      .title_box {
        font-size: 16px;
        font-weight: bold;
      }

      .form_box {
        margin-top: 30px;

        .noevaluated_box {
          padding: 80px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-size: 14px;

          .icon {
            width: 320px;
            display: block;
          }

          .text {
            margin-top: 30px;
            color: #999;
          }

          .btn_box {
            margin-top: 24px;

            .btn {
              margin-top: 18px;
              height: 42px;
              line-height: 42px;
              text-align: center;
              border-radius: 10px;
              border: 2px solid #5dacff;
              // font-size: 16px;
              color: #5dacff;
              padding: 0 14px;
              cursor: pointer;

              &:first-child {
                margin-top: 0;
              }
            }
          }
        }

        .evaluated_box {
          .level_box {
            width: 800px;
            position: relative;

            img {
              width: 100%;
              display: block;
            }

            .info_box {
              position: absolute;
              left: 12%;
              top: 50%;
              transform: translateY(-50%);
              color: #446a9d;

              .level {
                font-weight: bold;
                font-size: 24px;
                padding-left: 10px;
              }
              
              .type {
                margin-top: 20px;
                font-size: 20px;
              }
            }
          }

          .btn_box {
            margin-top: 30px;
            padding-left: 30px;

            .btn {
              margin-top: 18px;
              width: 180px;
              height: 42px;
              line-height: 42px;
              text-align: center;
              border-radius: 10px;
              border: 2px solid #5dacff;
              color: #5dacff;
              cursor: pointer;

              &:first-child {
                margin-top: 0;
              }
            }
          }
        }
      }
    }
  }
}
</style>