// 益学研究苑
import yxStudy from '@/views/yx_study/index.vue' // 首页

const routes = [
    {
        path: '/yxStudy',
        name: 'yxStudy',
        component: yxStudy,
        meta: {
            title: '益学堂-益学研究苑',
        },
    },
]

export default routes;