<template>
  <div class="yxdsj_wrap">
    <!-- <div class="video_box">
      <div class="video">
        <video
          ref="myVideo"
          class="myVideo"
          poster="//stocksf.yxcps.com/cc/media/about_yxdsj_bg.png"
          src="//stocksf.yxcps.com/cc/media/about_yxdsj.mp4"
          :muted="shouldMute"
          loop
        ></video>

        <div class="mute_box" @click="shouldMute = !shouldMute">
          <img
            :src="
              shouldMute
                ? require('@/assets/about_us/about/nosound.png')
                : require('@/assets/about_us/about/sound.png')
            "
            class="sound"
          />
        </div>
      </div>
    </div> -->

    <div class="yxdsj_container">
      <div class="head_box">益学大事记</div>

      <div class="yxdsj_box">
        <div class="yxdsj_list">
          <div
            class="yxdsj_item"
            v-for="(item, index) in yxdsjList"
            :key="index"
          >
            <div class="dsj_box dsj_box1">
              <div class="year" v-if="index % 2 === 0">{{ item.year }}</div>

              <div class="dsj_list" v-else>
                <div
                  class="dsj_item"
                  v-for="(item1, index1) in item.list"
                  :key="index1"
                >
                  <div class="text">{{ item1.text }}</div>

                  <div class="month">{{ item1.month }}</div>
                </div>
              </div>
            </div>

            <img src="@/assets/about_us/about/yxdsj/icon.png" class="icon" />

            <div class="dsj_box dsj_box2">
              <div class="dsj_list" v-if="index % 2 === 0">
                <div
                  class="dsj_item"
                  v-for="(item1, index1) in item.list"
                  :key="index1"
                >
                  <div class="month">{{ item1.month }}</div>

                  <div class="text">{{ item1.text }}</div>
                </div>
              </div>

              <div class="year" v-else>{{ item.year }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="yxdsj_text">更多发展，敬请期待</div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shouldMute: true,

      yxdsjList: [
        {
          year: "2013",
          list: [
            {
              month: "10月",
              text: "益学堂作为金融教育平台，正式成立",
            },
          ],
        },
        {
          year: "2014",
          list: [
            {
              month: "04月",
              text: "益学堂荣获经济金额信息化委员会颁发的《软件企业认定证书》",
            },
          ],
        },
        {
          year: "2015",
          list: [
            {
              month: "03月",
              text: "益学堂被多家媒体采访报道",
            },
            {
              month: "04月",
              text: "益学堂成为央广网合作平台",
            },
            {
              month: "09月",
              text: "益学堂荣获“中国十佳商业模式创新平台”",
            },
          ],
        },
        {
          year: "2016",
          list: [
            {
              month: "04月",
              text: "益学堂联合央广经济之声推出《大师讲堂》系列课程，著名经济学家管清友、任泽平、边风炜做客益学堂",
            },
            {
              month: "06月",
              text: "益学堂正式收购北京盛世华商证券",
            },
            {
              month: "07月",
              text: "益学堂与安信证券达成战略合作",
            },
            {
              month: "08月",
              text: "益学堂成功登陆第一财经《市场零距离》",
            },
            {
              month: "11月",
              text: "益学堂成功登陆中国教育电视台",
            },
          ],
        },
        {
          year: "2017",
          list: [
            {
              month: "05月",
              text: "益学堂成功登陆新华网",
            },
            {
              month: "06月",
              text: "益学堂证券资质正式变更为上海益学投资咨询有限公司",
            },
          ],
        },
        {
          year: "2018",
          list: [
            {
              month: "11月",
              text: "益学堂荣获《高新技术企业证书》",
            },
          ],
        },
        {
          year: "2019",
          list: [
            {
              month: "10月",
              text: "益学堂获得上海证券交易所颁发的《上证所Level1行情展示许可证》",
            },
          ],
        },
        {
          year: "2020",
          list: [
            {
              month: "04月",
              text: "益学堂获得深证证券交易所颁发的《专有信息经营许可证》",
            },
            {
              month: "07月",
              text: "益学堂再度成为央广网合作伙伴，登陆中国唯一覆盖全国的财经专业广播频率——经济之声",
            },
            {
              month: "08月",
              text: "益学堂成功登陆央视CCTV2、CCTV4",
            },
            {
              month: "09月",
              text: "益学堂与第一财经联合举办第十三届《未来理财师大赛》",
            },
            {
              month: "10月",
              text: "益学堂荣获上海市经济和信息化委员会颁发的2020年度上海市“专精特新”荣誉称号",
            },
            {
              month: "11月",
              text: "益学堂被喜马拉雅评为最佳合作伙伴",
            },
          ],
        },
        {
          year: "2021",
          list: [
            {
              month: "01月",
              text: "益学堂与央视达成协议，2021年全年登陆央视频道",
            },
          ],
        },
        {
          year: "2022",
          list: [
            {
              month: "04月",
              text: "益学堂推出益学智投VIP全新产品服务",
            },
            {
              month: "06月",
              text: "益学堂累计听课人次突破1.2亿次",
            },
            {
              month: "11月",
              text: "益学堂证券资质正式变更为上海益学智迅科技发展有限公司",
            },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.yxdsj_wrap {
  .video_box {
    min-height: calc(100vh - 109px);
    background: url("@/assets/about_us/about/yxdsj/bg.png") no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    .video {
      width: 76%;
      padding: 1.6% 2.3% 2.65%;
      box-sizing: border-box;
      background: url("@/assets/about_us/about/yxdsj/bg1.png") no-repeat;
      background-size: 100% 100%;
      position: relative;

      .myVideo {
        width: 100%;
        display: block;
      }

      .mute_box {
        cursor: pointer;
        position: absolute;
        bottom: 6%;
        right: 4%;

        .sound {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .yxdsj_container {
    background: url("@/assets/about_us/about/yxdsj/bg2.png") no-repeat #fff;
    background-size: 100%;
    padding: 60px 0 50px;

    .head_box {
      font-size: 30px;
      color: #1285ff;
      text-align: center;
    }

    .yxdsj_box {
      margin-top: 80px;

      .yxdsj_list {
        padding-bottom: 30px;
        position: relative;
        z-index: 1;

        &::after {
          content: "";
          width: 2px;
          height: 100%;
          background: linear-gradient(0deg, #a541ff 0%, #3fbbfe 100%);
          position: absolute;
          top: 0;
          left: 50%;
          transform: translateX(-50%);
          z-index: -1;
        }

        .yxdsj_item {
          margin-top: 30px;
          display: flex;
          justify-content: center;
          align-items: center;

          .icon {
            width: 26px;
            height: 26px;
            margin: 0 16px;
          }

          .dsj_box {
            width: 30%;

            .year {
              font-weight: bold;
              font-size: 20px;
              color: #1285ff;
            }

            .dsj_list {
              background: #f2f7ff;
              box-shadow: 6px 7px 26px 1px rgba(136, 200, 255, 0.4);
              border-radius: 10px;
              padding: 18px;

              .dsj_item {
                display: flex;
                margin-top: 16px;
                font-size: 14px;

                &:first-child {
                  margin-top: 0;
                }

                .month {
                  width: 50px;
                  font-weight: bold;
                }

                .text {
                  width: 80%;
                }
              }
            }

            &.dsj_box1 {
              text-align: right;

              .dsj_list {
                .dsj_item {
                  justify-content: flex-end;
                }
              }
            }
          }
        }
      }
    }
  }

  .yxdsj_text {
    margin-top: 24px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    color: #1285ff;
  }
}
</style>
