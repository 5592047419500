<template>
  <div class="join_us_wrap">
    <navbar_ :Lucency="false" :isBlack="true" :activeIndex="'/joinUs'" />

    <!-- <div class="swiper_wrap" ref="swiper_wrap">
      <img src="@/assets/join_us/swiper_bg.png" class="swiper_bg" />

      <el-carousel
        class="swiper_carousel"
        indicator-position="outside"
        arrow="never"
        :interval="5000"
      >
        <el-carousel-item
          class="swiper_item"
          v-for="(item, index) in swiperList"
          :key="index"
        >
          <div class="swiper_box">
            <div class="title_box">
              <div class="title">{{ item.title }}</div>

              <div class="sub_title">{{ item.subTitle }}</div>
            </div>

            <img :src="item.img" class="sub_img" />
          </div>
        </el-carousel-item>
      </el-carousel>
    </div> -->

    <div class="join_container">
      <div class="head_box">加入我们</div>

      <div class="info_list1">
        <div class="info_item" v-for="(item, index) in infoList1" :key="index">
          <div class="name">
            <span>{{ item.name }}</span>
          </div>

          <div class="sub_name" v-html="item.subName"></div>
        </div>
      </div>

      <div class="info_list2">
        <div class="info_list">
          <div
            class="info_item info_item1"
            v-for="(item, index) in infoList2"
            :key="index"
          >
            <div class="year" v-if="index % 2 === 0">{{ item.year }}</div>

            <div class="text" v-else v-html="item.text"></div>
          </div>
        </div>

        <div class="info_list_wire"></div>

        <div class="info_list">
          <div
            class="info_item info_item2"
            v-for="(item, index) in infoList2"
            :key="index"
          >
            <img src="@/assets/join_us/icon.png" class="icon" />

            <div class="text" v-if="index % 2 === 0" v-html="item.text"></div>

            <div class="year" v-else>{{ item.year }}</div>
          </div>
        </div>
      </div>

      <div class="head_box">招贤纳士</div>

      <div class="job_box">
        <el-collapse v-model="activeNames">
          <el-collapse-item
            :name="item.id"
            v-for="(item, index) in jobList"
            :key="index"
          >
            <template #title>
              <span class="title_">{{ item.name }}</span>
            </template>

            <div class="intro_box" v-html="item.intro"></div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </div>

    <footer_ />
  </div>
</template>

<script>
import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部

export default {
  data() {
    return {
      swiperList: [
        {
          title: "乐在益学",
          subTitle:
            "丰富的员工活动，包括团建旅游活动，篮球赛，运动会鼓励每位员工全请工作、快乐生活",
          img: require("@/assets/join_us/swiper1.png"),
        },
        {
          title: "学在益学",
          subTitle:
            "借助完备的线上知识管理平台，我们实现了将线上与线下的学习和培训相结合，构建了职业培训课程体系、内部培训师体系的“人才培养模式，帮助每位员工不断进步和提升",
          img: require("@/assets/join_us/swiper2.png"),
        },
        {
          title: "爱在益学",
          subTitle:
            "我们持续为员工提供有温度的关爱措施，生日会、儿童节礼物、各种福利发放。在疫情期间更突破重重困难，为员工发放生活物品",
          img: require("@/assets/join_us/swiper3.png"),
        },
      ],
      infoList1: [
        {
          name: "我们的企业文化",
          subName: "学无止境，教学相长<br/>勿忘初心，方得始终",
        },
        {
          name: "我们的使命",
          subName: "提升客户的抗风险能力",
        },
        {
          name: "我们的价值观",
          subName: "守法、合规、专业、敬业、感恩、互助",
        },
        {
          name: "我们的愿景",
          subName: "具有专业品质和职业精神的投资者教育专业机构",
        },
      ],
      infoList2: [
        {
          year: "2013",
          text: "注册了益学堂品牌，我们的长期规划是做中国投资者最信赖的在线金融实战平台",
        },
        {
          year: "2014",
          text: "在线听课人数达2000万人次，场均人数在7-8千",
        },
        {
          year: "2015",
          text: "成为中央人民广播电台央广网指定的投资者教育平台<br/><br/>获得“中国十佳商业模式创新平台”",
        },
        {
          year: "2016",
          text: "与第一财经《市场零距离》合作，这是第一财经首次与投资教育机构合作，同年与安信证券达成战略合作",
        },
        {
          year: "2017",
          text: "与中国最具影响力的网络媒体和具有全球影响力的中文网站新华网达成深度合作关系",
        },
        {
          year: "2020",
          text: "携手CCTV2、CCTV4与央广网再次达成深度战略合作",
        },
        {
          year: "持续发展",
          text: "我们邀请优秀的你一起同行",
        },
      ],
      activeNames: 1,
      jobList: [
        {
          id: 1,
          name: "业务部--销售顾问",
          intro: `<div class="intro_content">
                    <div class="t_title">岗位要求：</div>
                    <div class="t_text_box">
                      <div class="t_text">1、大专及以上学历，有证从，经验丰富者可放宽学历</div>
                      <div class="t_text">2、有1年及以上证券股票等金融行业工作经验</div>
                      <div class="t_text">3、热爱金融行业，有强烈的赚钱欲望，有较强的团队合作精神</div>
                    </div>
                  </div>
                  <div class="intro_content">
                    <div class="t_title">岗位内容：</div>
                    <div class="t_text_box">
                      <div class="t_text">1、利用公司提供的优质推广资源与客户沟通，寻找销售机会，电网结合的形式完成销售业绩</div>
                      <div class="t_text">2、根据公司投资顾问提供的最新行情研究，市场动态，及时为客户传递投资顾问分享内容</div>
                    </div>
                  </div>`,
        },
        {
          id: 2,
          name: "策划中心--财经主播",
          intro: `<div class="intro_content">
                    <div class="t_title">岗位要求：</div>
                    <div class="t_text_box">
                      <div class="t_text">1、3年及以上证券投资行业相关工作经验，必须有证券从业资格证书</div>
                      <div class="t_text">2、大专及以上学历，具备1年以上证券网络视频直播讲课经验者</div>
                      <div class="t_text">3、抖音等平台丰富经验者，有同行业公司工作经验优先</div>
                      <div class="t_text">4、形象端正，口齿伶俐、有较强的演讲功底</div>
                    </div>
                  </div>
                  <div class="intro_content">
                    <div class="t_title">岗位内容：</div>
                    <div class="t_text_box">
                      <div class="t_text">1、负责公司股票类视频号、抖音等自媒体直播导流、短视频录制等工作，全程独立进行内容输出</div>
                      <div class="t_text">2、负责日常证券市场趋势行情分析，拥有自己完整的交易体系，传递交易理念，达到自媒体粉丝增量目的</div>
                      <div class="t_text">3、撰写市场主流趋势，热点，主题投资策略分析以及撰写投资策略，输出相关文字、视频内容</div>
                      <div class="t_text">4、配合策划和销售部门，撰写专题报告、投资方法介绍等，完成资源引流、营销等任务</div>
                    </div>
                  </div>`,
        },
        {
          id: 3,
          name: "策划中心--营销策划",
          intro: `<div class="intro_content">
                    <div class="t_title">岗位要求：</div>
                    <div class="t_text_box">
                      <div class="t_text">1、本科及以上学历，广告、市场营销、新闻传播等相关专业，2年以上策划工作经验</div>
                      <div class="t_text">2、对互联网教育产品及用户需求有深刻的理解认识，有过在线教育或者金融证券行业经验优先</div>
                      <div class="t_text">3、对自媒体营销或运营策划感兴趣，较强数据分析能力，对方案投放分析具有把控能力</div>
                      <div class="t_text">4、执行力强，跟进执行市场活动，快速的学习能力和活跃的创造力，优秀的沟通能力，善于归纳总结</div>
                    </div>
                  </div>
                  <div class="intro_content">
                    <div class="t_title">岗位内容：</div>
                    <div class="t_text_box">
                      <div class="t_text">1、根据部门所属产品线的经营目标，制订多平台具体营销活动计划，保障年度销售目标实现</div>
                      <div class="t_text">2、制订重要节点的促销、推广方案，整合资源输出活动策划方案与执行</div>
                      <div class="t_text">3、能洞察用户真实需求，对金融培训产品产出创新活动形式，全盘跟进执行，推动营销方案执行落地，对传播文案及效果做精细化把控</div>
                      <div class="t_text">4、善于挖掘产品亮点和爆点，负责活动介绍、宣传引流等文案策划和创作，根据投放效果，持续迭代文案，提升用户体验</div>
                      <div class="t_text">5、能够用数据分析评估活动效果，不断输出改进方案，完善金融培训产品推广策略，提升产品竞争力</div>
                    </div>
                  </div>`,
        },
        {
          id: 4,
          name: "投资顾问部--自媒体讲师",
          intro: `<div class="intro_content">
                    <div class="t_title">岗位要求：</div>
                    <div class="t_text_box">
                      <div class="t_text">1、3年及以上证券投资行业相关工作经验，必须有证券投资顾问证书</div>
                      <div class="t_text">2、本科及以上学历，具备1年以上证券网络视频直播讲课经验者</div>
                      <div class="t_text">3、抖音等平台丰富经验者，有同行业公司工作经验优先</div>
                      <div class="t_text">4、形象端正，口齿伶俐、有较强的演讲功底</div>
                    </div>
                  </div>
                  <div class="intro_content">
                    <div class="t_title">岗位内容：</div>
                    <div class="t_text_box">
                      <div class="t_text">1、负责公司股票类视频号、抖音等自媒体直播导流、短视频录制等工作，全程独立进行内容输出</div>
                      <div class="t_text">2、负责日常证券市场趋势行情分析，拥有自己完整的交易体系，传递交易理念，达到自媒体粉丝增量目的</div>
                      <div class="t_text">3、撰写市场主流趋势，热点，主题投资策略分析以及撰写投资策略，输出相关文字、视频内容</div>
                      <div class="t_text">4、配合策划和销售部门，撰写专题报告、投资方法介绍等，完成资源引流、营销等任务</div>
                    </div>
                  </div>`,
        },
        {
          id: 5,
          name: "产研部--大数据开发工程师",
          intro: `<div class="intro_content">
                    <div class="t_title">岗位要求：</div>
                    <div class="t_text_box">
                      <div class="t_text">1、统招本科以上学历，2年相关工作经验</div>
                      <div class="t_text">2、熟练使用MySQL、StarRocks等主流数据库</div>
                      <div class="t_text">3、熟悉常见的而大数据生态工具，例如Linux、Hadoop、Datax、Flink、StarRocks等</div>
                      <div class="t_text">4、熟练使用FineReport等报表工具进行数据可视化报表制作，有Python和Java开发经验</div>
                      <div class="t_text">5、有较强的学习能力和独立工作能力、理解能力、善于表达和沟通，较强的抗压能力</div>
                    </div>
                  </div>
                  <div class="intro_content">
                    <div class="t_title">岗位内容：</div>
                    <div class="t_text_box">
                      <div class="t_text">1、负责数据研发工作，理解数据逻辑和要求，并制定个人工作推进规划</div>
                      <div class="t_text">2、梳理业务场景，设计数据模型，从源头系统数据表开始抽取至ODS层，在加工至模型层</div>
                      <div class="t_text">3、分析业务报表需求，从模型层加工形成业务端所需报表</div>
                      <div class="t_text">4、深入研究ETL工具和数据库的特性，不断优化数据加工性能</div>
                      <div class="t_text">5、负责数据日常运维，确保数据准确、稳定、可用</div>
                    </div>
                  </div>`,
        },
      ],
    };
  },
  components: {
    navbar_,
    footer_,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.join_us_wrap {
  .swiper_wrap {
    position: relative;

    .swiper_bg {
      width: 100%;
      display: block;
    }

    .swiper_carousel {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      /deep/ .el-carousel__container {
        height: calc(100% - 50px);

        .swiper_box {
          height: 100%;
          padding: 60px 6% 0 12%;
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .title_box {
            width: 40%;
            color: #fff;

            .title {
              font-size: 30px;
            }

            .sub_title {
              margin-top: 25px;
              font-size: 14px;
              text-align: justify;
              line-height: 24px;
            }
          }

          .sub_img {
            width: 56%;
          }
        }
      }

      /deep/ .el-carousel__indicators {
        .el-carousel__indicator {
          padding: 22px 4px;

          .el-carousel__button {
            width: 4px;
            height: 4px;
            border-radius: 4px;
          }

          &.is-active {
            button {
              width: 16px;
            }
          }
        }
      }
    }
  }

  .join_container {
    background: url("@/assets/join_us/bg.png") no-repeat #fff;
    background-size: 100%;
    padding: 60px 8% 50px;

    .head_box {
      padding-top: 50px;
      font-size: 40px;
      text-align: center;
      color: #1285ff;
    }

    .info_list1 {
      margin-top: 45px;
      display: flex;
      justify-content: space-between;

      .info_item {
        width: 23.5%;
        background: #f2f7ff;
        box-shadow: 6px 7px 26px 1px rgba(136, 200, 255, 0.4);
        border-radius: 10px;
        padding: 24px;
        box-sizing: border-box;

        .name {
          font-size: 20px;

          span {
            border-bottom: 2px solid;
            border-image: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%) 1;
          }
        }

        .sub_name {
          margin-top: 14px;
          font-size: 14px;
        }
      }
    }

    .info_list2 {
      margin-top: 50px;

      .info_list {
        display: flex;
        justify-content: space-between;

        .info_item {
          width: 13.8%;
          text-align: center;

          .year {
            font-weight: bold;
            font-size: 20px;
            color: #1285ff;
          }

          .text {
            font-size: 14px;
          }

          &.info_item1 {
            display: flex;
            justify-content: center;
            align-items: flex-end;
            padding-bottom: 32px;
          }

          &.info_item2 {
            margin-top: -12px;

            .icon {
              width: 24px;
              display: block;
              margin: 0 auto 20px;
            }
          }
        }
      }

      .info_list_wire {
        height: 2px;
        background: linear-gradient(0deg, #3fbbfe 0%, #a541ff 100%);
      }
    }

    .job_box {
      margin-top: 45px;

      /deep/.el-collapse {
        border: 0;

        .el-collapse-item {
          margin-top: 30px;
          box-shadow: 6px 7px 26px 1px rgba(136, 200, 255, 0.4);
          border-radius: 5px;

          .el-collapse-item__header {
            border: 0;
            border-radius: 5px;
            height: 60px;
            line-height: normal;
            padding: 0 12px 0 20px;
            font-size: 16px;
            // font-weight: 400;

            span {
              border-bottom: 2px solid;
              border-image: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%) 1;
            }
          }

          .el-collapse-item__wrap {
            border-radius: 5px;
            border: 0;

            .el-collapse-item__content {
              padding: 0;

              .intro_box {
                padding: 10px 20px 25px;
                text-align: justify;

                .intro_content {
                  margin-top: 24px;

                  &:first-child {
                    margin-top: 0;
                  }

                  .t_title {
                    font-size: 16px;
                    font-weight: 700;
                  }

                  .t_text_box {
                    margin-top: 8px;
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
