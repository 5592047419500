<template>
  <div class="product_center_wrap">
    <navbar_ :Lucency="false" :isBlack="true" :activeIndex="'/product'" />

    <div class="product_box">
      <div
        class="product"
        v-for="(item, index) in productList"
        :key="index"
        :data-aos="item.aos"
      >
        <div class="product_cont" :class="item.type">
          <div class="tag_box">
            <div class="tag">{{ item.tag_text }}</div>

            <div class="price">{{ item.prices }}</div>
          </div>

          <div class="product_title">{{ item.title }}</div>

          <div class="content_box">
            <div
              class="content_list"
              v-for="(list, index) in item.list"
              :key="index"
            >
              <div class="name">{{ list.name }}</div>

              <div class="text">{{ list.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer_ />
  </div>
</template>

<script>
import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部

export default {
  data() {
    return {
      productList: [
        {
          title: "恒牛-步步为赢",
          type: "product1",
          aos: "fade-right",
          tag_text: "入门首选",
          tag: require("@/assets/index/product/tag1.png"),
          prices: "29800元/6个月",
          list: [
            {
              name: "交易日陪伴",
              text: "股票交流圈：早盘解读、午盘点评、收盘总结、问题解答",
            },
            {
              name: "强势股票池",
              text: "每周精选教案标的：符合选股战法，精选股票标的",
            },
            {
              name: "选股课程",
              text: "每周2节内部选股逻辑课：贴合当下盘面及热点选出符合战法的股票",
            },
            {
              name: "教学课程",
              text: "股市教学课程：由浅入深精品课程，打造操盘手思维",
            },
            {
              name: "精品特刊",
              text: "消息面解读：把握当下热点板块，精选板块热股",
            },
          ],
        },
        {
          title: "恒牛私享家",
          type: "product2",
          aos: "fade-left",
          tag_text: "VIP私享",
          tag: require("@/assets/index/product/tag2.png"),
          prices: "",
          list: [
            {
              name: "上市公司研学社",
              text: "走进了上市公司：获得机会参与上市公司走访，设身处地了解持股情况",
            },
            {
              name: "私享策略池",
              text: "私人定制：每位用户可获得量身定制的服务方案，符合自身操作特性",
            },
            {
              name: "vip策略早鸟报",
              text: "特刊分析：宏观方向把控，周行情回顾",
            },
            {
              name: "实战私享课",
              text: "服务课程：盘中服务课，实战选股课，跟踪盘面情况",
            },
            {
              name: "益学大咖课程",
              text: "益学大咖指导：对盘面和复盘的指导。对市场的点评，学习选股学习方法",
            },
          ],
        },
      ],
    };
  },
  components: {
    navbar_,
    footer_,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.product_center_wrap {
  .product_box {
    height: 100vh;
    background: url("@/assets/index/product/bg.png") no-repeat;
    background-size: cover;

    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;

    .product {
      width: 33%;
      cursor: default;

      &:first-child {
        margin-right: 4.6%;
      }

      .product_cont {
        padding: 30px 40px 80px;
        box-sizing: border-box;
        border-radius: 10px;
        transition: all 0.3s;
        position: relative;

        .tag_box {
          width: calc(100% - 4px);
          height: 50px;
          padding: 0 40px;
          box-sizing: border-box;
          font-size: 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-radius: 0 0 10px 10px;

          position: absolute;
          bottom: 2px;
          left: 2px;

          .price {
            font-weight: bold;
          }
        }

        .product_title {
          font-size: 20px;
          font-weight: 700;
        }

        .content_box {
          margin-top: 24px;
          // margin-top: 58px;

          .content_list {
            margin-top: 20px;
            // margin-top: 42px;

            .name {
              font-size: 16px;
              font-weight: 500;
              padding-left: 20px;
              position: relative;

              &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 3px;
                height: 14px;
                background-color: #fff;
              }
            }

            .text {
              font-size: 14px;
              margin-top: 12px;
              // margin-top: 24px;

              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        &.product1 {
          background: url("@/assets/index/product/product3.png") no-repeat;
          background-size: 100% 100%;

          .tag_box {
            // background: linear-gradient(162deg, #1285ff 0%, #2cdbff 100%);
            // opacity: 0.2;
            background: linear-gradient(
              162deg,
              rgba(18, 133, 255, 0.2) 0%,
              rgba(44, 219, 255, 0.2) 100%
            );
          }

          .content_box {
            .content_list {
              .name {
                &::before {
                  background-color: #1285ff;
                }
              }
            }
          }

          &:hover {
            box-shadow: 16px 17px 57px 0px rgba(136, 200, 255, 0.26);
            transform: scale(1.1);
          }
        }

        &.product2 {
          background: url("@/assets/index/product/product4.png") no-repeat;
          background-size: 100% 100%;

          .tag_box {
            // background: linear-gradient(162deg, #FFE8CE 0%, #B18B65 98%);
            // opacity: 0.2;
            background: linear-gradient(
              162deg,
              rgba(255, 232, 206, 0.2) 0%,
              rgba(177, 139, 101, 0.2) 98%
            );
          }

          .content_box {
            .content_list {
              .name {
                &::before {
                  background-color: #dbbd9d;
                }
              }
            }
          }

          &:hover {
            box-shadow: 16px 17px 57px 0px rgba(219, 189, 157, 0.26);
            transform: scale(1.1);
          }
        }
      }
    }
  }
}

@media (min-width: 1921px) {
  .product_center_wrap {
    .product_box {
      .product {
        .product_cont {
          .content_box {
            margin-top: 58px;

            .content_list {
              margin-top: 42px;

              .text {
                margin-top: 24px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
