<template>
  <div class="contact_main">
    <el-tabs v-model="activeName" @tab-change="tabChange">
      <el-tab-pane :name="item.id" v-for="(item, index) in tabs" :key="index">
        <template v-slot:label>
          <div class="head">
            <img :src="item.icon" />
            {{ item.name }}
          </div>
        </template>

        <component :is="item.comName"></component>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import lxfs_ from "@/views/about_us/contact/lxfs/index.vue"; // 联系方式
import qyzh_ from "@/views/about_us/contact/qyzh/index.vue"; // 企业账户
import yxsm_ from "@/views/about_us/contact/yxsm/index.vue"; // 益学声明

export default {
  data() {
    return {
      activeName: Number(this.$route.query.subTabId) || 1,
      tabs: [
        {
          id: 1,
          name: "联系方式",
          icon: require("@/assets/about_us/contact/icon1.png"),
          comName: "lxfs_",
        },
        {
          id: 2,
          name: "企业账户",
          icon: require("@/assets/about_us/contact/icon2.png"),
          comName: "qyzh_",
        },
        {
          id: 3,
          name: "益学声明",
          icon: require("@/assets/about_us/contact/icon3.png"),
          comName: "yxsm_",
        },
      ],
    };
  },
  components: {
    lxfs_,
    qyzh_,
    yxsm_,
  },
  created() {},
  mounted() {},
  methods: {
    // 顶部导航 切换
    tabChange(name) {
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth", // 平滑滚动
      // });

      this.$router.push({
        path: "/aboutUs",
        query: {
          tabId: this.$route.query.tabId || 0,
          subTabId: name,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.contact_main {
  /deep/ .el-tabs {
    --el-tabs-header-height: 50px;

    .el-tabs__header {
      margin: 0;
      position: sticky;
      top: 60px;

      .el-tabs__nav-wrap {
        .el-tabs__nav-scroll {
          .el-tabs__nav {
            width: 100%;
            background: #1a1a1a;

            .el-tabs__active-bar {
              height: 2px;
              bottom: 4px;
              background: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%);
            }

            .el-tabs__item {
              color: #fff;
              font-weight: 400;

              &.is-active {
                font-weight: 600;
              }

              &:nth-child(2) {
                padding-left: 20px;
              }

              .head {
                font-size: 14px;
                display: flex;
                align-items: center;

                img {
                  width: 14px;
                  height: 14px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
