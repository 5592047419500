// 加入我们
import joinUs from '@/views/join_us/index.vue' // 首页

const routes = [
    {
        path: '/joinUs',
        name: 'joinUs',
        component: joinUs,
        meta: {
            title: '益学堂-加入我们',
        },
    },
]

export default routes;