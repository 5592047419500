<template>
  <div class="order_wrap">
    <navbar_ :Lucency="false" :isBlack="true" :activeIndex="''" />

    <div class="title">我的订单</div>

    <div class="order_container">
      <div class="container">
        <div class="title_box">我的订单</div>

        <div class="order_list_box">
          <el-table :data="orderList.rows">
            <el-table-column type="index" width="60" align="center" />
            <el-table-column
              prop="courseName"
              label="课程名称"
              min-width="120"
              align="center"
            />
            <el-table-column
              prop="className"
              label="班级名称"
              min-width="120"
              align="center"
            />
            <el-table-column
              prop="orderNo"
              label="订单号"
              min-width="180"
              align="center"
            />
            <el-table-column
              prop="orderTime"
              label="下单时间"
              min-width="180"
              align="center"
            >
              <template v-slot="scope">
                {{ COMMON.getTimes(scope.row.orderTime) }}
              </template>
            </el-table-column>
            <el-table-column
              prop="payTime"
              label="支付时间"
              min-width="180"
              align="center"
            >
              <template v-slot="scope">
                {{
                  scope.row.payTime ? COMMON.getTimes(scope.row.payTime) : "--"
                }}
              </template>
            </el-table-column>
            <el-table-column
              prop="lessonHours"
              label="课时"
              min-width="60"
              align="center"
            />
            <el-table-column
              prop="orderAmount"
              label="订单金额"
              min-width="120"
              align="center"
            >
              <template v-slot="scope">
                ￥{{ scope.row.orderAmount }}
              </template>
            </el-table-column>
            <el-table-column
              prop="realAmount"
              label="实付金额"
              min-width="120"
              align="center"
            >
              <template v-slot="scope"> ￥{{ scope.row.realAmount }} </template>
            </el-table-column>
            <el-table-column
              prop="orderType"
              label="订单类型"
              min-width="80"
              align="center"
            >
              <template v-slot="scope">
                {{ orderType[scope.row.orderType] }}
              </template>
            </el-table-column>
            <el-table-column
              prop="orderStatus"
              label="订单状态"
              min-width="80"
              align="center"
            >
              <template v-slot="scope">
                {{ orderStatus[scope.row.orderStatus] }}
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination_box">
            <el-pagination
              background
              layout="prev, pager, next"
              :page-size="pageSize"
              :total="orderList.total"
              prev-text="上一页"
              next-text="下一页"
              :hide-on-single-page="true"
              @current-change="currentChange"
            />
          </div>
        </div>
      </div>
    </div>

    <footer_ />

    <login_ v-if="isLoginVisible" :isCloseShow="false" />
  </div>
</template>

<script>
import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部
import login_ from "@/components/login/index.vue"; // 登录

export default {
  data() {
    return {
      isLoginVisible: false,
      isLogin: localStorage.getItem("yx_isLogin") || false,
      userInfo: JSON.parse(localStorage.getItem("yx_userInfo")) || null,

      pageNum: 1,
      pageSize: 15,
      orderList: [],

      orderType: {
        0: "常规订单",
        1: "升级订单",
        2: "差额订单",
        3: "课程卡订单",
        4: "赠送订单",
        5: "常规订单",
      },
      orderStatus: {
        0: "未支付",
        1: "已关闭",
        2: "已退课",
        3: "已退课",
        4: "未支付",
        5: "部分支付",
        6: "待发货",
        7: "交易成功",
        8: "休学",
      },
    };
  },
  components: {
    navbar_,
    footer_,
    login_,
  },
  created() {
    if (this.isLogin) {
      this.getOrderList(); // 获取 订单 list
      return;
    }

    this.isLoginVisible = true; // 显示 登录框
  },
  mounted() {},
  methods: {
    // 获取 订单 list
    getOrderList() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        userNo: this.userInfo.userNo,
      };

      this.API.CLOUDGet("cms/api/website/order/list/page", data).then((res) => {
        // console.log(res);
        let datas = res.data;
        console.log(datas);

        this.orderList = datas;
      });
    },

    // 分页 改变
    currentChange(val) {
      this.pageNum = val;

      this.getOrderList(); // 获取 订单 list
    },
  },
};
</script>

<style lang="less" scoped>
.order_wrap {
  min-height: 100vh;
  padding-top: 60px;
  box-sizing: border-box;
  background: #eff1f3;

  .title {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: bold;
    background: #fff;
    padding-left: 28px;
  }

  .order_container {
    min-height: calc(100vh - 110px);
    padding: 16px;
    box-sizing: border-box;

    .container {
      min-height: calc(100vh - 142px);
      padding: 30px 20px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;

      .title_box {
        font-size: 16px;
        font-weight: bold;
      }

      .order_list_box {
        margin-top: 12px;

        .pagination_box {
          margin-top: 16px;
          display: flex;
          justify-content: center;

          /deep/.el-pagination {
            .btn-prev,
            .btn-next {
              margin: 0 8px;
              padding: 0 10px;
            }

            .el-pager {
              li {
                margin: 0 8px;
              }
            }
          }
        }
      }
    }
  }
}
</style>