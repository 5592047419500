<template>
  <div class="yxsm_wrap">
    <div class="head_box">
      <img src="@/assets/about_us/contact/yxsm/bg1.png" />

      <div class="title">益学声明</div>
    </div>

    <div class="yxsm_container">
      <div class="yxsm_list">
        <div class="item_title">
          <img src="@/assets/about_us/contact/yxsm/1.png" />
          益学声明
        </div>

        <div class="text">
          近期发现有不法分子在微博、微信和QQ等平台上假借“益学堂”、“益学讲师杨凯、张帅、曾海龙、邹海洋”及“益学讲师助理”的名义，进行非法代客理财、荐股等业务。特此声明，益学堂以投资者教育为根本，重视传授正确的投资方法，从未涉及代客理财等一切非法业务，请投资者注意甄别！
        </div>

        <div class="text">
          用户如收到以上名义的非法业务推介，上海地区用户可拨打上海市公安局经侦总队电话举报，其他地区请直接拨打当地110。
        </div>

        <div class="text">上海市经侦局举报电话：<b>021-22029018</b></div>
      </div>

      <img src="@/assets/about_us/contact/yxsm/line.png" class="yxsm_line" />

      <div class="yxsm_list">
        <div class="item_title">
          <img src="@/assets/about_us/contact/yxsm/2.png" />
          风险提示
        </div>

        <div class="text">
          1.用户应了解证券投资面临的各种市场风险和政策风险，了解证券投资顾问业务的内涵和基本规则，并了解用户应自主做出投资决策，并独立承担投资风险。
        </div>

        <div class="text">
          2.益学堂所有平台（官网、微信等）发布的内容以及向学员提供在线培训服务中，所使用方法、案例仅作为教学之用，我们再次重申，益学堂所教授的内容都是炒股的方法，不涉及任何股票咨询、股票推荐、代客理财等行为。
        </div>

        <div class="text">
          3.接受在线培训服务的学员需自行承担股票投资的风险、损失等后果。
        </div>
      </div>

      <img src="@/assets/about_us/contact/yxsm/line.png" class="yxsm_line" />

      <div class="yxsm_list">
        <div class="item_title">
          <img src="@/assets/about_us/contact/yxsm/3.png" />
          益学堂免责声明
        </div>

        <div class="text"><b>讲师声明</b></div>

        <div class="text">
          讲师分别声明，本人具有中国证券业协会授予的证券投资咨询执业资格，勤勉尽责、诚实守信。本人对教学的课程和观点负责，保证信息来源合法合规、研究方法专业审慎、研究观点独立公正、分析结论具有合理依据，特此声明。
        </div>

        <div class="text"><b>免责声明</b></div>

        <div class="text">
          教学课程包含教学视频及教学材料，仅供益学堂的客户使用，益学堂不会因为任何机构或个人接收到本教学课程而视其为益学堂的当然客户，教学内容分为免费和付费两类。
        </div>

        <div class="text">
          本教学课程基于已公开的行情、资料或信息进行教学，但本公司不保证该等信息及资料的完整性、准确性。本教学课程所载的信息、资料、建议及推测仅反映益学堂于本发布当日的判断，本教学课程中的证券或投资标的价格、价值及投资带来的收入可能会波动。在不同时期，益学堂可能撰写并发布与本教学课程所载资料、建议及推测不一致的报告。益学堂不保证本教学课程所含信息及资料保持在最新状态，益学堂将随时补充、更新和修订有关信息及资料，但不保证及时发布。
        </div>

        <div class="text">
          同时，益学堂有权对本教学课程所含信息在不发出通知的情形下做出修改，投资者应当自行关注相应的更新或修改。任何有关本教学课程的摘要或节选都不代表正式完整的观点，一切须以益学堂向客户发布的本教学课程为准，
          如有需要，客户可以向益学堂课程顾问进一步咨询。
          在法律许可的情况下，益学堂及所属关联机构可能会持有教学课程中提到的公司所发行的证券或期权并进行证券或期权交易，也可能为这些公司提供或者争取提供投资银行、财务顾问或者金融产品等相关服务，提请客户充分注意。客户不应将本教学课程为作出其投资决策的惟一参考因素，亦不应认为本教学课程可以取代客户自身的投资判断与决策。
        </div>

        <div class="text">
          在任何情况下，本教学课程中的信息或所表述的意见均不构成对任何人的投资建议，无论是否已经明示或暗示，本教学课程不能作为道义的、责任的和法律的依据或者凭证。在任何情况下，益学堂亦不对任何人因使用本教学课程中的任何内容所引致的任何损失负任何责任。
        </div>

        <div class="text">
          本教学课程版权仅为益学堂所有， 未经事先书面许可，
          任何机构和个人不得以任何形式翻版、复制、发表、转发或引用本教学课程的任何部分。如征得益学堂同意进行引用、刊发的，需在允许的范围内使用，并注明出处为“益学堂”，且不得对本教学课程进行任何有悖原意的引用、删节和修改。
        </div>

        <div class="text">益学堂对本声明条款具有惟一修改权和最终解释权。</div>
      </div>

      <img src="@/assets/about_us/contact/yxsm/line.png" class="yxsm_line" />

      <div class="yxsm_list">
        <div class="item_title">
          <img src="@/assets/about_us/contact/yxsm/4.png" />
          经营证券投资咨询业务资格的说明
        </div>

        <div class="text">
          上海益学智迅科技发展有限公司获得证监会颁发的《经营证券期货业务许可证》，经营证券期货业务许可证统一社会信用代码(境外机构编号)：91310109MA1G5BK839。
        </div>

        <div class="text">
          益学堂及其教学讲师可以为证券投资人或客户提供证券投资分析、建议等直接或间接的有偿教学服务。投资者教育，是证券投资咨询业务的一种基本形式，益学堂可以对证券及证券相关产品的价值、市场走势或者相关影响因素进行分析，形成证券估值、投资评级等投资分析意见，制作证券研究报告，并向本公司的客户进行教学。
        </div>
      </div>

      <img src="@/assets/about_us/contact/yxsm/line.png" class="yxsm_line" />

      <div class="yxsm_list">
        <div class="item_title">
          <img src="@/assets/about_us/contact/yxsm/5.png" />
          提醒
        </div>

        <div class="text">
          上海益学智迅科技发展有限公司以及相关分子公司从未参与任何形式不同的现货类期货市场的经营。
        </div>
      </div>

      <img src="@/assets/about_us/contact/yxsm/line.png" class="yxsm_line" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.yxsm_wrap {
  .head_box {
    position: relative;

    img {
      width: 100%;
      display: block;
    }

    .title {
      font-size: 30px;
      color: #fff;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .yxsm_container {
    padding: 50px 8% 0;

    .yxsm_list {
      .item_title {
        font-size: 16px;
        color: #1285FF;
        font-weight: bold;
        padding-bottom: 4px;
        display: inline-flex;
        align-items: center;

        border-bottom: 2px solid;
        border-image: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%) 1;

        img {
          width: 18px;
          margin-right: 6px;
        }
      }

      .text {
        margin-top: 30px;
        font-size: 14px;
        text-align: justify;
      }
    }

    .yxsm_line {
      width: 100%;
      margin: 30px 0 50px;
    }
  }
}
</style>
