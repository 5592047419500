// 关于我们
import aboutUs from '@/views/about_us/index.vue' // 首页
import researchInfo from '@/views/about_us/focus/shgsyx/research_info.vue' // 研学 详情页

const routes = [
    {
        path: '/aboutUs',
        name: 'aboutUs',
        component: aboutUs,
        meta: {
            title: '益学堂-关于我们',
        },
    },
    {
        path: '/aboutUs/researchInfo',
        name: 'researchInfo',
        component: researchInfo,
    },
]

export default routes;