<template>
  <div class="login_wrap">
    <div class="login_container">
      <div class="close_box" v-if="isCloseShow" @click="$emit('closeLogin')">
        <el-icon :size="24"><Close /></el-icon>
      </div>

      <div class="type_box">
        <div
          class="type"
          :class="{ type_active: loginType == index }"
          v-for="(item, index) in loginMode"
          :key="index"
          @click="
            // userCode = '';
            loginType = index;
            this.$refs.formRef.resetFields();
          "
        >
          {{ item }}
        </div>
      </div>

      <div class="input_box">
        <el-form
          :model="form"
          ref="formRef"
          :rules="rules"
          @keydown.enter="onLogin"
        >
          <el-form-item
            prop="phoneNum"
            :rules="[
              { required: true, message: '请输入手机号' },
              {
                pattern: reg_tel,
                message: '请输入正确的手机号',
              },
            ]"
          >
            <el-input
              v-model="form.phoneNum"
              placeholder="请输入手机号"
              maxlength="11"
              @input="form.phoneNum = form.phoneNum.replace(/[^0-9]/g, '')"
            />
          </el-form-item>

          <el-form-item
            prop="userCode"
            v-if="loginType == 0"
            :rules="[{ required: true, message: '请输入验证码' }]"
          >
            <div class="code_box">
              <el-input
                v-model="form.userCode"
                placeholder="请输入验证码"
                maxlength="6"
                @input="form.userCode = form.userCode.replace(/[^0-9]/g, '')"
              />

              <div
                class="code_text"
                :class="{ code_text_zi: codeTimer == null }"
                @click="getCodeClick()"
              >
                {{ codeText }}
              </div>
            </div>
          </el-form-item>

          <el-form-item
            prop="userCode"
            v-if="loginType == 1"
            :rules="[{ required: true, message: '请输入密码' }]"
          >
            <el-input
              v-model="form.userCode"
              type="password"
              placeholder="请输入密码"
              show-password
            />
          </el-form-item>

          <el-form-item prop="checked">
            <div class="protocol_box">
              <el-checkbox v-model="form.checked">
                我已阅读并同意
                <span class="sp" @click.prevent="protocolClick()">
                  《益学用户服务协议》
                </span>
              </el-checkbox>
            </div>
          </el-form-item>

          <el-button type="primary" @click="onLogin">立即登录</el-button>
        </el-form>
      </div>

      <div class="forget_box">
        <span @click="COMMON.routerSkip('/set_pwd')">忘记密码？</span>
      </div>
    </div>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      loginType: 0, // 0-验证码 1-密码
      loginMode: ["短信快捷登录", "手机密码登录"],

      reg_tel:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, // 正则匹配手机号
      form: {
        phoneNum: "", // 手机号
        userCode: "", // 短信验证码
        checked: false, // 协议状态
      },
      rules: {
        checked: [
          {
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请阅读并同意用户服务协议"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
      },

      codeText: "获取验证码",
      codeNum: 59,
      codeTimer: null,
    };
  },
  props: {
    isCloseShow: {
      type: Boolean,
      default: true, // 为false时，不显示 关闭按钮
    },
  },
  created() {},
  mounted() {},
  methods: {
    // 获取验证码 btn click
    getCodeClick() {
      if (this.form.phoneNum == "") {
        ElMessage.error("请输入手机号");
        return;
      }

      if (!this.reg_tel.test(this.form.phoneNum)) {
        ElMessage.error("请输入正确的手机号");
        return;
      }

      if (this.codeTimer != null) {
        return;
      }

      this.getCode(); // 获取验证码
    },

    // 获取验证码
    getCode() {
      let data = {
        mobile: this.form.phoneNum,
        deviceNo: "YX" + this.form.phoneNum,
        acquireType: "autoLogin",
        isRes: true,
        clientType: "1",
      };
      this.API.quanGet("vcV5/acquireVCodeThird", data).then((res) => {
        // console.log(res);
        if (res.data.status == 1) {
          this.codeTime(); // 60秒 倒计时

          ElMessage({
            message: "验证码发送成功",
            type: "success",
          });
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    },

    // 60秒 倒计时
    codeTime() {
      this.codeText = "60 秒";

      this.codeTimer = setInterval(() => {
        if (this.codeNum > 0 && this.codeNum <= 60) {
          this.codeText =
            (this.codeNum < 10 ? "0" + this.codeNum : this.codeNum) + " 秒";
          this.codeNum--;
        } else {
          clearInterval(this.codeTimer);
          this.codeTimer = null;
          this.codeNum = 60;
          this.codeText = "获取验证码";
        }
      }, 1000);
    },

    // 登录 按钮 click
    onLogin() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          // console.log(this.form);

          switch (this.loginType) {
            case 0:
              this.yzmLogin(); // 验证码 登录
              break;
            case 1:
              this.mmLogin(); // // 密码 登录
              break;
          }
        } else {
          console.log("表单验证失败");
          return false;
        }
      });
    },

    // 验证码 登录
    yzmLogin() {
      let data = {
        mobile: this.form.phoneNum,
        vCode: this.form.userCode,
        deviceNo: "YX" + this.form.phoneNum,
        acquireType: "autoLogin",
        isRes: true,
        clientType: "1",
        version: "",
        supplierName: "",
        channel: "",
        agencyId: "",
        partnerFrom: "",
      };
      this.API.quanGet("coopqacct/autoLoginByVCode", data).then((res) => {
        // console.log(res);
        if (res.data.status == 1) {
          // let yx_userInfo = {
          //   userNo: "1724865",
          //   userName: "Y.",
          //   userPhoto:
          //     "http://stocksf.yxcps.com/cloud_upload/2024/08/08/24a4bf58-6ce4-4b80-b243-af97d39440f0.jpg",
          // };

          let yx_userInfo = {
            userNo: res.data.result.userNo,
            userName: res.data.result.nickName,
            userPhoto: res.data.result.headPhotoUrl,
          };
          localStorage.setItem("yx_isLogin", true);
          localStorage.setItem("yx_userInfo", JSON.stringify(yx_userInfo));

          this.Cookies.set(
            "pc_advanced_lessons_user_id",
            res.data.result.userId,
            {
              expires: 365,
              path: "/",
              domain: ".yxcps.com",
              sameSite: "Strict",
            }
          );

          this.loginLater(); // 登录 后 做什么
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    },

    // 密码 登录
    mmLogin() {
      let data = {
        version: "",
        productCode: "yixue",
        clientType: 1,
        mobile: this.form.phoneNum,
        password: this.form.userCode,
        verifyType: 1,
        smsVerifyCode: "",
      };
      this.API.CLOUDPost("account/api/user/login/login", data).then((res) => {
        console.log(res);
        if (res.data.code == 200) {
          let datas = res.data.data;

          if (datas) {
            let yx_userInfo = {
              userNo: datas.userNo,
              userName: datas.nickName,
              userPhoto: datas.headPhotoUrl,
            };
            localStorage.setItem("yx_isLogin", true);
            localStorage.setItem("yx_userInfo", JSON.stringify(yx_userInfo));

            this.Cookies.set("pc_advanced_lessons_user_id", datas.userId, {
              expires: 365,
              path: "/",
              domain: ".yxcps.com",
              sameSite: "Strict",
            });

            this.loginLater(); // 登录 后 做什么
          }
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    },

    // 登录 后 做什么
    loginLater() {
      ElMessage({
        message: "登录成功",
        type: "success",
      });

      // 如果是 会员中心、我的订单 - 登录，刷新页面
      if (this.$route.path == "/vip" || this.$route.path == "/order") {
        location.reload();
        return;
      }

      this.$emit("loginSucceed");
    },

    // 用户协议 click
    protocolClick() {
      this.COMMON.routerSkip("/protocol");
    },
  },
};
</script>

<style lang="less" scoped>
.login_wrap {
  width: 100%;
  height: 100%;
  color: #fff;
  // background: rgba(0, 0, 0, 0.8);

  z-index: 110;
  position: fixed;
  top: 60px;
  left: 0;

  .login_container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 35%;
    min-width: 680px;
    padding: 70px 50px 60px;
    box-sizing: border-box;

    background: linear-gradient(-32deg, #1e2e40 0%, #07090f 47%, #161d25 99%);
    box-shadow: 13px 14px 78px 23px rgba(0, 0, 0, 0.39);
    border-radius: 10px;

    .close_box {
      position: absolute;
      top: 20px;
      right: 15px;

      .el-icon {
        cursor: pointer;
      }
    }

    .type_box {
      border: 1px solid #595b5d;
      border-radius: 10px;
      padding: 4px;
      display: flex;

      .type {
        width: 50%;
        padding: 12px 0;
        text-align: center;
        font-weight: bold;
        font-size: 16px;
        cursor: pointer;

        &.type_active {
          background: rgba(93, 172, 255, 0.2);
          border-radius: 10px;
        }
      }
    }

    .input_box {
      margin-top: 60px;

      /deep/ .el-form {
        .el-form-item {
          margin-bottom: 24px;

          .el-form-item__content {
            line-height: normal;

            .el-input {
              .el-input__wrapper {
                padding: 0 16px;
                background: rgba(255, 255, 255, 0.05);
                border-radius: 10px;
                border: 1px solid #595b5d;
                box-shadow: none;

                &:hover {
                  border: 1px solid #5dacff;
                }

                &.is-focus {
                  border: 1px solid #5dacff;
                }

                .el-input__inner {
                  height: 48px;
                  color: #fff;
                }
              }
            }

            .code_box {
              width: 100%;
              position: relative;

              .el-input {
                .el-input__wrapper {
                  padding-right: 40%;
                }
              }

              .code_text {
                font-size: 14px;
                position: absolute;
                top: 50%;
                right: 16px;
                transform: translateY(-50%);

                &.code_text_zi {
                  cursor: pointer;

                  &:hover {
                    color: #5dacff;
                  }
                }
              }
            }

            .protocol_box {
              .el-checkbox {
                height: auto;

                .el-checkbox__label {
                  color: #fff;
                  font-size: 14px;

                  .sp {
                    color: #5dacff;
                  }
                }
              }
            }
          }
        }

        .el-button {
          width: 100%;
          margin-top: 36px;
          font-size: 16px;
          height: 48px;
          line-height: 48px;
          text-align: center;
          border: none;
          background: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%);
          border-radius: 10px;
          letter-spacing: 2px;
        }
      }
    }

    .forget_box {
      margin-top: 20px;
      font-size: 14px;
      text-align: right;
      color: #5dacff;

      span {
        cursor: pointer;
      }
    }
  }
}
</style>
