<template>
  <div class="yxyj_wrap">
    <div class="head_box">益学堂——<br />证券教育平台的愿景</div>

    <div class="yxyj_container">
      <div class="text">
        中国拥有1.3亿股民，他们为国家经济的发展做出了巨大贡献。然而，许多股民对证券知识、法规和历史了解不足，风险意识薄弱，常常因盲目入市而遭受损失。益学堂致力于为股民提供全面的教育服务，帮助他们提高投资素养，减少不必要的风险。
      </div>

      <div class="text">
        在益学堂，学员不仅可以学习证券知识，还能通过平台享受新闻信息、生活资讯，金融产品等多元化服务。益学堂致力于打造一个以情感为纽带、以证券培训为核心，充满互动与关爱的社区。此外，益学堂还与省级电视台、网络电视台等媒体展开合作，不断拓展更多深度合作机会。
      </div>

      <div class="text">
        追求卓越是我们的使命，也是我们不断前进的动力。客户的认可和正面评价是我们存在的价值，也是推动我们不断进步的重要力量。
      </div>

      <div class="text">
        “不忘初心，方得始终。”展望未来，我们充满希望“一位哲人曾说，如果不能成为别人的礼物，就不要走近别人的生活。我们希望益学堂能成为广大学员生命中的宝贵礼物，共同成长，共创辉煌。”
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.yxyj_wrap {
  min-height: calc(100vh - 109px);
  background: url("@/assets/about_us/focus/yxyj/bg.png") no-repeat;
  background-size: cover;
  color: #fff;
  padding: 130px 4%;
  box-sizing: border-box;

  .head_box {
    font-size: 30px;
    line-height: 48px;
  }

  .yxyj_container {
    margin-top: 120px;

    .text {
      font-size: 14px;
      text-align: justify;
      margin-top: 40px;
      line-height: 24px;
    }
  }
}

@media (min-width: 1921px) {
  .yxyj_wrap {
    padding: 180px 4%;

    .yxyj_container {
      margin-top: 200px;
    }
  }
}
</style>
