<template>
  <div class="about_us_wrap">
    <navbar_ :Lucency="false" :isBlack="true" :activeIndex="'/aboutUs'" />

    <div class="about_container">
      <div class="affix_box">
        <el-affix target=".about_container" :offset="60">
          <div class="tab_list">
            <div
              class="tab_item"
              :class="{ tab_active: index == menuIndex }"
              v-for="(item, index) in menuList"
              :key="index"
              @click="tabClick(index)"
            >
              {{ item.name }}
            </div>
          </div>
        </el-affix>
      </div>

      <div class="main_content">
        <div v-for="(item, index) in menuList" :key="index">
          <component v-show="index == menuIndex" :is="item.comName"></component>
        </div>
      </div>
    </div>

    <footer_ />
  </div>
</template>

<script>
import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部

import about_ from "@/views/about_us/about/index.vue"; // 关于益学
import focus_ from "@/views/about_us/focus/index.vue"; // 益学聚焦
import contact_ from "@/views/about_us/contact/index.vue"; // 联系我们

export default {
  data() {
    return {
      menuIndex: this.$route.query.tabId || 0,
      menuList: [
        {
          name: "关于益学",
          comName: "about_",
        },
        {
          name: "益学聚焦",
          comName: "focus_",
        },
        {
          name: "联系我们",
          comName: "contact_",
        },
      ],
    };
  },
  components: {
    navbar_,
    footer_,
    about_,
    focus_,
    contact_,
  },
  created() {},
  mounted() {},
  methods: {
    // 左侧 tab click
    tabClick(index) {
      if (this.menuIndex == index) return;

      // console.log(index);

      // this.menuIndex = index;
      // window.scrollTo({
      //   top: 0,
      //   // behavior: "smooth", // 平滑滚动
      // });

      this.$router.push({
        path: "/aboutUs",
        query: {
          tabId: index,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.about_us_wrap {
  padding-top: 60px;

  .about_container {
    min-height: calc(100vh - 60px);
    display: flex;

    .affix_box {
      width: 10%;
      background: #000;

      /deep/.el-affix {
        width: auto !important;
      }

      .tab_list {
        height: calc(100vh - 60px);
        background: linear-gradient(
          -20deg,
          #04080f 0%,
          #0e2b4c 53%,
          #040a0e 100%
        );

        .tab_item {
          height: 75px;
          line-height: 75px;
          font-size: 14px;
          color: #fff;
          text-align: center;
          cursor: pointer;
          box-sizing: border-box;

          &.tab_active {
            font-weight: bold;
            background: linear-gradient(
              90deg,
              rgba(18, 133, 255, 0.25) 0%,
              rgba(44, 219, 255, 0.25) 100%
            );
            border-right: 3px solid;
            border-image: linear-gradient(180deg, #2cdbff 0%, #1285ff 100%) 1;
          }
        }
      }
    }

    .main_content {
      width: 90%;
      // padding-left: 10%;
    }
  }
}
</style>
