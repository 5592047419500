<template>
  <div class="mmsz_wrap">
    <div class="title">密码设置</div>

    <div class="container_box">
      <div class="container">
        <div class="title_box">密码设置</div>

        <div class="form_box">
          <el-form :model="form" ref="formRef" label-width="auto">
            <el-form-item
              label="手机号："
              prop="mobile"
              :rules="[
                { required: true, message: '请输入手机号' },
                {
                  pattern: reg_tel,
                  message: '请输入正确的手机号',
                },
              ]"
            >
              <el-input
                v-model="form.mobile"
                placeholder="请输入手机号"
                maxlength="11"
                @input="form.mobile = form.mobile.replace(/[^0-9]/g, '')"
              />
            </el-form-item>

            <el-form-item
              label="验证码："
              prop="vCode"
              :rules="[{ required: true, message: '请输入验证码' }]"
            >
              <div class="code_box">
                <el-input
                  v-model="form.vCode"
                  placeholder="请输入验证码"
                  maxlength="6"
                  @input="form.vCode = form.vCode.replace(/[^0-9]/g, '')"
                />

                <div
                  class="code_text"
                  :class="{ code_text_zi: codeTimer == null }"
                  @click="getCodeClick()"
                >
                  {{ codeText }}
                </div>
              </div>
            </el-form-item>

            <el-form-item
              label="新密码："
              prop="md5Pswd"
              :rules="[{ required: true, message: '请输入密码' }]"
            >
              <el-input
                v-model="form.md5Pswd"
                placeholder="请输入密码"
                show-password
              />
            </el-form-item>

            <el-form-item
              label="确认密码："
              prop="md5PswdConfrim"
              :rules="[{ required: true, message: '请再次输入密码' }]"
            >
              <el-input
                v-model="form.md5PswdConfrim"
                placeholder="请再次输入密码"
                show-password
              />
            </el-form-item>

            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="tipsShow"
      title="提示"
      width="500"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :align-center="true"
      :before-close="tipsClose"
    >
      <span>密码修改成功</span>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="tipsClose()">确认</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { hex_md5 } from "@/utils/valide_k";

import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      reg_tel:
        /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, // 正则匹配手机号

      form: {
        mobile: "",
        vCode: "",
        md5Pswd: "",
        md5PswdConfrim: "",
      },

      tipsShow: false, // 成功 - 提示

      codeText: "获取验证码",
      codeNum: 59,
      codeTimer: null,
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 获取验证码 btn click
    getCodeClick() {
      if (this.form.mobile == "") {
        ElMessage.error("请输入手机号");
        return;
      }

      if (!this.reg_tel.test(this.form.mobile)) {
        ElMessage.error("请输入正确的手机号");
        return;
      }

      if (this.codeTimer != null) {
        return;
      }

      this.getCode(); // 获取验证码
    },

    // 获取验证码
    getCode() {
      let data = {
        deviceNo: "",
        acquireType: "forgetPswd",
        clientType: 1,
        mobile: this.form.mobile,
        version: "",
      };
      this.API.quanGet("vcV5/acquireVCode", data).then((res) => {
        // console.log(res);
        if (res.data.status == 1) {
          this.codeTime(); // 60秒 倒计时

          ElMessage({
            message: "验证码发送成功",
            type: "success",
          });
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    },

    // 60秒 倒计时
    codeTime() {
      this.codeText = "60 秒";

      this.codeTimer = setInterval(() => {
        if (this.codeNum > 0 && this.codeNum <= 60) {
          this.codeText =
            (this.codeNum < 10 ? "0" + this.codeNum : this.codeNum) + " 秒";
          this.codeNum--;
        } else {
          clearInterval(this.codeTimer);
          this.codeTimer = null;
          this.codeNum = 60;
          this.codeText = "获取验证码";
        }
      }, 1000);
    },

    // 提交保存 新密码
    onSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.checkVcode(); // 先验证 短信验证码 是否正确
        } else {
          console.log("表单验证失败");
          return false;
        }
      });
    },

    // 先验证 短信验证码 是否正确
    checkVcode() {
      let data = {
        mobile: this.form.mobile,
        vCode: this.form.vCode,
      };
      this.API.quanGet("coopqacct/checkVcode", data).then((res) => {
        // console.log(res);
        if (res.data.status == 1) {
          this.setPwd(); // 修改密码
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    },

    // 修改密码
    setPwd() {
      let data = {
        mobile: this.form.mobile,
        md5Pswd: hex_md5(this.form.md5Pswd).toUpperCase(),
        md5PswdConfrim: hex_md5(this.form.md5PswdConfrim).toUpperCase(),
        vCode: this.form.vCode,
        clientType: 1,
        version: "1.0.0",
      };
      this.API.quanPost("qacct/resetPwd", data).then((res) => {
        // console.log(res);
        if (res.data.status == 1) {
          // ElMessage({
          //   message: "密码修改成功",
          //   type: "success",
          // });
          // location.reload();

          this.tipsShow = true;
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    },

    // 提示 关闭
    tipsClose() {
      this.tipsShow = false;

      location.reload();
    },
  },
};
</script>

<style lang="less" scoped>
.mmsz_wrap {
  min-height: calc(100vh - 60px);
  background: #eff1f3;

  .title {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: bold;
    background: #fff;
    padding-left: 28px;
  }

  .container_box {
    min-height: calc(100vh - 110px);
    padding: 16px;
    box-sizing: border-box;

    .container {
      min-height: calc(100vh - 142px);
      padding: 30px 20px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;

      .title_box {
        font-size: 16px;
        font-weight: bold;
      }

      .form_box {
        margin-top: 30px;

        /deep/ .el-form {
          .el-form-item {
            margin-bottom: 24px;

            .el-form-item__label-wrap {
              .el-form-item__label {
                font-weight: bold;
                height: 42px;
                line-height: 42px;
              }
            }

            .el-form-item__content {
              .el-input {
                .el-input__wrapper {
                  .el-input__inner {
                    height: 40px;
                  }
                }
              }

              .code_box {
                width: 100%;
                position: relative;

                .el-input {
                  .el-input__wrapper {
                    padding-right: 40%;
                  }
                }

                .code_text {
                  font-size: 14px;
                  position: absolute;
                  top: 50%;
                  right: 16px;
                  transform: translateY(-50%);

                  &.code_text_zi {
                    cursor: pointer;

                    &:hover {
                      color: #5dacff;
                    }
                  }
                }
              }
            }
          }

          .el-button {
            width: 90px;
            margin-top: 36px;
            font-size: 16px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            border: none;
            background: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%);
            letter-spacing: 2px;
          }
        }
      }
    }
  }
}
</style>