<template>
  <div class="qyzz_wrap">
    <img src="@/assets/about_us/about/qyzz/1.png" class="yyzz_img" />

    <div class="qyzz_container">
      <div class="title">
        上海益学智迅科技发展有限公司获证监会颁发的<br />
        《经营证券期货业务许可证》
      </div>

      <div class="sub_title">
        经营证券期货业务许可证统一社会信用代码(境外机构编号)：
        <span>91310109MA1G5BK839</span>
      </div>

      <div class="text1">
        自2016年5月1日起，中国证监会及其派出机构向证券期货经营机构颁发的《经营证券业务许可证》、《证券投资咨询业务资格证书》、《经营外资股业务资格证书》、《合格境外机构投资者证券投资业务许可证》、《证券投资业务许可证》、《基金管理资格证书》、《特定客户资产管理业务资格证书》、《基金销售业务资格证书》、《经营期货业务许可证》、《期货公司营业部经营许可证》等10项许可证统一为《经营证券期货业务许可证》。
      </div>

      <div class="text2">
        <img src="@/assets/about_us/about/qyzz/icon.png" />
        益学堂提醒投资者选择正规合法的证券投资咨询机构
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.qyzz_wrap {
  min-height: calc(100vh - 109px);
  background: url("@/assets/about_us/about/qyzz/bg.png") no-repeat;
  background-size: cover;
  color: #fff;
  box-sizing: border-box;
  position: relative;

  .yyzz_img {
    width: 50%;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .qyzz_container {
    padding: 160px 0 70px 36px;
    font-size: 14px;

    .title {
      font-size: 30px;
      line-height: 48px;
    }

    .sub_title {
      margin-top: 30px;
    }

    .text1 {
      margin-top: 60px;
      width: 48%;
      text-align: justify;
      line-height: 24px;
    }

    .text2 {
      margin-top: 60px;
      display: flex;
      align-items: center;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }
  }
}

@media (min-width: 1921px) {
  .qyzz_wrap {
    .qyzz_container {
      padding: 240px 0 100px 36px;

      .sub_title {
        margin-top: 60px;
      }

      .text1 {
        margin-top: 100px;
      }

      .text2 {
        margin-top: 100px;
      }
    }
  }
}
</style>
