<template>
  <div class="system_wrap">
    <div class="head_box">
      <img src="@/assets/index/system/title_bg.png" />

      <div class="title_box">
        <div class="title" data-aos="zoom-in">五大交易体系</div>

        <div class="sub_title" data-aos="fade-up" data-aos-easing="ease">
          让/您/的/投/资/理/念/更/专/业
        </div>
      </div>
    </div>

    <div class="content_box">
      <div
        class="system_list system_list1"
        data-aos="zoom-in"
        data-aos-delay="400"
      >
        <div class="system_item1">
          <div class="name">买卖</div>

          <div class="text">
            投资就是一门生意，低买高卖永远是真理，买的目的是卖，卖的目的是买，会买的是徒弟，会卖的才是师傅，结合市场趋势、技术分析和基本面分析来确定买卖的最佳时机
          </div>
        </div>

        <div class="system_item2">
          <img src="@/assets/index/system/icon.png" />
        </div>
      </div>

      <div
        class="system_list system_list2"
        data-aos="zoom-in"
        data-aos-delay="600"
      >
        <div class="system_item1">
          <div class="name">选时</div>

          <div class="text">
            成功在于在适宜的时机进行操作，时机的选择对于投资成功至关重要。成功是在正确的时间做正确的事情，没有不好的股票，只有不好的时机，正确时间进场，事半功倍
          </div>
        </div>

        <div class="system_item2">
          <img src="@/assets/index/system/icon.png" />
        </div>
      </div>

      <div
        class="system_list system_list3"
        data-aos="zoom-in"
        data-aos-delay="800"
      >
        <div class="system_item1">
          <div class="name">选股</div>

          <div class="text">
            投资成功的关键在于选股，重视公司基本面，聚焦拥有强大竞争力、良好治理结构和明晰增长路径的企业。结合市场趋势和行业前景，筛选出具有优秀潜力的股票
          </div>
        </div>

        <div class="system_item2">
          <img src="@/assets/index/system/icon.png" />
        </div>
      </div>

      <div
        class="system_list system_list4"
        data-aos="zoom-in"
        data-aos-delay="1000"
      >
        <div class="system_item1">
          <div class="name">风控</div>

          <div class="text">
            通过设置止盈止损点来管理风险，确保单笔交易的潜在损失在可接受范围内，明确每次交易的目标，无论是追求短期利润还是长期增值，并在达到目标后及时调整策略
          </div>
        </div>

        <div class="system_item2">
          <img src="@/assets/index/system/icon.png" />
        </div>
      </div>

      <div
        class="system_list system_list5"
        data-aos="zoom-in"
        data-aos-delay="1200"
      >
        <div class="system_item1">
          <div class="name">心态</div>

          <div class="text">
            不被情绪起伏干扰交易决策，严守交易纪律，避免情绪化冲动决策，严格执行买卖计划。不断学习市场新动态和交易技巧，以提高买卖决策的质量，做有计划的交易决策
          </div>
        </div>

        <div class="system_item2">
          <img src="@/assets/index/system/icon.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.system_wrap {
  height: 100%;
  background: url("@/assets/index/system/bg.png") no-repeat;
  background-size: cover;

  .head_box {
    position: relative;

    img {
      width: 100%;
      display: block;
    }

    .title_box {
      padding-top: 100px;
      text-align: center;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);

      .title {
        font-size: 40px;
        color: #5dacff;
      }

      .sub_title {
        margin-top: 16px;
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .content_box {
    .system_list {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;

      .system_item1 {
        padding: 7%;
        box-sizing: border-box;
        background: url("@/assets/index/system/card.png") no-repeat;
        background-size: 100% 100%;
        color: #fff;
        text-align: justify;
        cursor: default;
        transition: all 0.3s;

        .name {
          font-weight: 600;
          font-size: 18px;
        }

        .text {
          font-size: 14px;
          margin-top: 8px;
        }

        &:hover {
          box-shadow: 16px 17px 57px 0px rgba(110, 163, 255, 0.26);
          transform: scale(1.1);
        }
      }

      .system_item2 {
        width: 12%;
        margin-top: 12px;

        img {
          width: 100%;
          display: block;
        }
      }

      &.system_list1 {
        left: 9%;
        bottom: 16%;
      }

      &.system_list2 {
        left: 27%;
        bottom: 45%;
      }

      &.system_list3 {
        left: 52%;
        bottom: 41%;
      }

      &.system_list4 {
        left: 66%;
        bottom: 13%;
      }

      &.system_list5 {
        left: 38%;
        bottom: 3%;
      }
    }
  }
}
</style>