<template>
  <div class="cyrygs_wrap">
    <div class="cyrygs_container">
      <div class="hint_box">
        <div class="hint fwrx_hint">
          <div class="head">
            <img src="@/assets/about_us/about/cyrygs/icon.png" />
            服务热线
          </div>

          <div class="fwrx_box">
            <div class="fwrx">
              销售服务专线：
              <span>400-673-6018</span>
            </div>
            <div class="fwrx">
              VIP服务专线：
              <span>400-673-6019</span>
            </div>
            <div class="fwrx">
              益学法律事务联系电话：
              <span>021-6084-0777</span>
            </div>
          </div>
        </div>

        <div class="hint risk_hint">
          <div class="head">
            <img src="@/assets/about_us/about/cyrygs/icon.png" />
            风险与提示
          </div>

          <div class="text">
            投资顾问不得提供任何形式的代客理财，不得接受用户任何形式的委托交易，不得以任何形式与用户约定、分享投资收益或分担投资损失，不得以任何方式索要、记录、保存用户的证券或资金账户、密码，严禁向客户承诺投资收益或保证风险
          </div>
        </div>
      </div>

      <div class="cyry_box">
        <el-table :data="dataList.rows">
          <el-table-column type="index" width="80" align="center" />
          <el-table-column
            prop="name"
            label="姓名"
            min-width="120"
            align="center"
          />
          <el-table-column
            prop="workNo"
            label="工号"
            min-width="120"
            align="center"
          />
          <el-table-column
            prop="cardNo"
            label="证书编号"
            min-width="180"
            align="center"
          />
          <el-table-column
            prop="cardName"
            label="持业岗位"
            min-width="180"
            align="center"
          />
          <el-table-column label="持业机构" min-width="180" align="center">
            <template v-slot="header">上海益学智迅科技发展有限公司</template>
          </el-table-column>
        </el-table>

        <div class="pagination_box">
          <el-pagination
            background
            layout="prev, pager, next"
            :page-size="pageSize"
            :total="dataList.total"
            prev-text="上一页"
            next-text="下一页"
            :hide-on-single-page="true"
            @current-change="currentChange"
          />

          <div class="text">
            <a
              href="https://gs.sac.net.cn/pages/registration/sac-publicity-report.html"
              target="_blank"
            >
              从业人员名单来源于中国证券业协会官网，详细可至证监会官网查询
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageNum: 1,
      pageSize: 14,
      dataList: [],
    };
  },
  created() {},
  mounted() {
    this.getPractitionerList(); // 获取 从业人员 list
  },
  methods: {
    // 获取 从业人员 list
    getPractitionerList() {
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      };

      this.API.CLOUDGet(
        "cms/api/website/staff/practitioner/list/page",
        data
      ).then((res) => {
        // console.log(res);
        let datas = res.data;
        // console.log(datas);

        this.dataList = datas;
      });
    },

    // 分页 改变
    currentChange(val) {
      this.pageNum = val;

      this.getPractitionerList();
    },
  },
};
</script>

<style lang="less" scoped>
.cyrygs_wrap {
  min-height: calc(100vh - 109px);
  background: url("@/assets/about_us/about/cyrygs/bg.png") no-repeat #fff;
  background-size: 100%;

  .cyrygs_container {
    padding: 50px 24px 60px;
    display: flex;
    justify-content: space-between;

    .hint_box {
      width: 24.5%;
      padding-top: 100px;

      .hint {
        font-size: 14px;

        .head {
          font-size: 20px;
          font-weight: bold;
          display: flex;
          align-items: center;

          img {
            width: 22px;
            height: 22px;
            margin-right: 8px;
          }
        }

        &.fwrx_hint {
          .fwrx_box {
            margin-top: 24px;

            .fwrx {
              margin-top: 10px;

              span {
                font-weight: bold;
              }
            }
          }
        }

        &.risk_hint {
          margin-top: 120px;

          .text {
            margin-top: 24px;
            text-align: justify;
          }
        }
      }
    }

    .cyry_box {
      width: 74%;

      .pagination_box {
        margin-top: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;

        /deep/.el-pagination {
          .btn-prev,
          .btn-next {
            margin: 0 8px;
            padding: 0 10px;
          }

          .el-pager {
            li {
              margin: 0 8px;
            }
          }
        }

        .text {
          margin-top: 16px;

          a {
            color: #666;
            font-size: 12px;
            text-decoration: none;

            &:hover {
              color: #409eff;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1921px) {
  .cyrygs_wrap {
    .cyrygs_container {
      padding: 160px 36px;
    }
  }
}
</style>
