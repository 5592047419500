// 产品中心
import product from '@/views/product_center/index.vue' // 首页

const routes = [
    {
        path: '/product',
        name: 'product',
        component: product,
        meta: {
            title: '益学堂-产品中心',
        },
    },
]

export default routes;