<template>
  <div
    class="about_wrap"
    :class="{ wrap_bg1: activeName == '1', wrap_bg2: activeName == '2' }"
  >
    <el-tabs v-model="activeName" @tab-change="handleChange">
      <el-tab-pane name="1">
        <template v-slot:label>
          <div class="head">
            <img src="@/assets/index/about/icon1.png" />
            关于益学
          </div>
        </template>

        <div class="tab_wrap1">
          <div class="title_box1">
            <div class="title" data-aos="zoom-in">关于益学</div>

            <div
              class="sub_title"
              data-aos="fade-up"
              data-aos-easing="ease"
            >
              上海益学智迅科技发展有限公司旗下品牌，一家专注于通过互联网和移动互联网为全球华人投资者提供专业投资能力培训的教育机构。作为在线投资者教育平台，为投资者创造更便利、更轻松、更专业的学习服务体验。传递投资知识，发现投资价值。构建专属您的操盘知识体系。
            </div>
          </div>

          <div
            class="content1"
            data-aos="fade-up"
            data-aos-easing="ease"
          >
            <img class="line" src="@/assets/index/about/line1.png" />

            <div class="cont">
              <div class="cont_item cont_item1">
                <div class="text_box">
                  <div class="num">
                    <el-statistic :value="numCount1" group-separator="" />
                  </div>
                  <div class="text">创立时间</div>
                </div>

                <img class="line1" src="@/assets/index/about/line5.png" />
              </div>

              <div class="cont_item cont_item2">
                <div class="text_box">
                  <div class="num">ZX0170</div>
                  <div class="text">业务资格许可证编号</div>
                </div>

                <img class="line1" src="@/assets/index/about/line4.png" />
              </div>
            </div>

            <img class="line" src="@/assets/index/about/line2.png" />

            <div class="cont">
              <div class="cont_item cont_item3">
                <div class="text_box">
                  <div class="num">
                    <el-statistic :value="numCount2">
                      <template #suffix> + </template>
                    </el-statistic>
                  </div>
                  <div class="text">听课人次</div>
                </div>

                <img class="line1" src="@/assets/index/about/line4.png" />
              </div>

              <div class="cont_item cont_item4">
                <div class="text_box">
                  <div class="num">
                    <el-statistic :value="numCount3">
                      <template #suffix> + </template>
                    </el-statistic>
                  </div>
                  <div class="text">服务人次</div>
                </div>

                <img class="line1" src="@/assets/index/about/line5.png" />
              </div>
            </div>

            <img class="line" src="@/assets/index/about/line3.png" />
          </div>
        </div>
      </el-tab-pane>

      <el-tab-pane name="2">
        <template v-slot:label>
          <div class="head">
            <img src="@/assets/index/about/icon2.png" />
            发展历程
          </div>
        </template>

        <div class="tab_wrap2">
          <div class="title_box2">
            <div class="title">发展历程</div>
          </div>

          <div class="content2">
            <img src="@/assets/index/about/fzlc.png" />
          </div>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { ref } from "vue";
import { useTransition } from "@vueuse/core";

export default {
  setup() {
    function countTo(num, duration) {
      let source = ref(0);
      let outputValue = useTransition(source, {
        duration: duration || 1500,
      });

      setTimeout(() => {
        source.value = num;
      }, 500);

      return outputValue;
    }

    const numCount1 = countTo(2013, 1000);
    const numCount2 = countTo(114000000, 1000);
    const numCount3 = countTo(100000, 1000);

    return {
      numCount1,
      numCount2,
      numCount3,
    };
  },
  data() {
    return {
      activeName: "1",

      numtext1: 0,
    };
  },
  mounted() {},
  methods: {
    handleChange() {
      // console.log(this.activeName);

      // if (window.AOS) {
      //   this.$nextTick(() => {
      //     window.AOS.init();
      //     window.AOS.refresh();
      //     console.log(window.AOS);
      //   });
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.about_wrap {
  height: 100%;
  padding-top: 100px;

  &.wrap_bg1 {
    background: url("@/assets/index/about/bg1.png") no-repeat;
    background-size: cover;
  }

  &.wrap_bg2 {
    background: url("@/assets/index/about/bg2.png") no-repeat;
    background-size: cover;
  }

  /deep/.el-tabs {
    --el-tabs-header-height: 40px;

    .el-tabs__header {
      margin: 0;

      .el-tabs__nav-wrap {
        &::after {
          height: 1px;
          background-color: #fff;
          opacity: 0.2;
        }

        .el-tabs__nav-scroll {
          display: flex;
          justify-content: center;

          .el-tabs__nav {
            .el-tabs__active-bar {
              height: 2px;
              bottom: 1px;
              background: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%);
            }

            .el-tabs__item {
              align-items: flex-start;
              color: #fff;
              font-weight: 400;

              &.is-active {
                font-weight: 600;
              }

              .head {
                font-size: 14px;
                display: flex;
                align-items: center;

                img {
                  width: 14px;
                  height: 14px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }

  .tab_wrap1 {
    .title_box1 {
      text-align: center;
      margin-top: 100px;

      .title {
        font-size: 40px;
        color: #5dacff;
      }

      .sub_title {
        width: 70%;
        margin: 60px auto 0;
        color: #fff;
        font-size: 16px;
        line-height: 28px;
        letter-spacing: 2px;
      }
    }

    .content1 {
      margin-top: 140px;

      .line {
        width: 100%;
        display: block;
      }

      .line1 {
        width: 1%;
        display: block;
      }

      .cont {
        display: flex;

        .cont_item {
          width: 50%;
          color: #fff;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          .text_box {
            .num {
              font-size: 30px;

              /deep/ .el-statistic__content {
                font-size: 30px;
                color: #fff;
              }
            }

            .text {
              font-size: 12px;
              margin-top: 6px;
            }
          }

          &.cont_item1 {
            padding-right: 1.5%;
            justify-content: end;

            .text_box {
              padding-right: 6.2%;

              .num {
                width: 72px;
              }
            }
          }

          &.cont_item2 {
            .text_box {
              padding: 0 4% 0 2.5%;
              width: 120px;
            }
          }

          &.cont_item3 {
            justify-content: end;

            .text_box {
              padding-right: 10%;

              .num {
                width: 212px;
              }

              .text {
                padding-left: 3px;
              }
            }
          }

          &.cont_item4 {
            .text_box {
              padding: 0 9%;

              .num {
                width: 174px;
              }

              .text {
                padding-left: 3px;
              }
            }
          }
        }
      }
    }
  }

  .tab_wrap2 {
    .title_box2 {
      margin-top: 100px;
      padding-left: 10%;

      .title {
        display: inline-block;
        font-size: 40px;
        color: #5dacff;
        animation: zoomIn 1s ease-out forwards;
      }

      @keyframes zoomIn {
        from {
          opacity: 0;
        }
        to {
          opacity: 1;
        }
      }
    }

    .content2 {
      margin-top: -28px;
      opacity: 0;
      animation: fadeIn 1.5s linear forwards;
      animation-delay: 200ms;

      img {
        width: 100%;
        display: block;
      }

      @keyframes fadeIn {
        from {
          opacity: 0; /* 开始时完全透明 */
        }
        to {
          opacity: 1; /* 结束时完全不透明 */
        }
      }
    }
  }
}
</style>
