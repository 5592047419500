import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

const Vue = createApp(App)

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    Vue.component(key, component)
}

import VueWechatTitle from 'vue-wechat-title'

import axios from 'axios'
Vue.config.globalProperties.$axios = axios

import Cookies from 'js-cookie'
Vue.config.globalProperties.Cookies = Cookies

import common from "./utils/common";
Vue.config.globalProperties.COMMON = common  // 3.0全局挂载

import api from './utils/api'
Vue.config.globalProperties.API = api  // 3.0全局挂载

import AOS from 'aos';
import 'aos/dist/aos.css';

window.AOS = AOS;

window.AOS.init({
    offset: 0,
    delay: 200,
    duration: 1000,
});

Vue.use(router).use(VueWechatTitle).use(ElementPlus, { locale: zhCn }).mount('#app')


import { debounce } from "lodash";
const resizeObserver = (window).ResizeObserver;
(window).ResizeObserver = class ResizeObserver extends resizeObserver {
    constructor(callback) {
        callback = debounce(callback, 10);
        super(callback);
    }
};