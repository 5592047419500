<template>
  <div class="shgsyx_wrap">
    <div class="head_box">
      <div class="title">
        上市公司研学<br />
        益学堂面对面对话上市公司，实地了解最新情况
      </div>
    </div>

    <div class="shgsyx_container">
      <div class="list_box">
        <div
          class="item_box top_item_box"
          v-for="(item, index) in topList"
          :key="index"
          @click="researchClick(item)"
        >
          <img :src="item.cover" />

          <div class="title">{{ item.title }}</div>
        </div>
      </div>

      <div class="list_box">
        <div
          class="item_box notop_item_box"
          v-for="(item, index) in list"
          :key="index"
          @click="researchClick(item)"
        >
          <img :src="item.cover" />

          <div class="title">{{ item.title }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      topList: [],
      list: [],
    };
  },
  created() {
    this.getResearchList(); // 获取 研学 list
  },
  mounted() {},
  methods: {
    // 获取 研学 list
    getResearchList() {
      let data = {};

      this.API.CLOUDGet(
        "cms/api/website/material/research-material/list",
        data
      ).then((res) => {
        // console.log(res);
        let datas = res.data.data;
        // console.log(datas);

        datas = datas.map((item) => {
          item.cover = item.cover.replace(/https?:\/\//g, "//");
          return item;
        });

        this.topList = datas
          .filter((item) => item.top === 1)
          .sort((a, b) => a.sort - b.sort);

        this.list = datas
          .filter((item) => item.top === 0)
          .sort((a, b) => a.sort - b.sort);

        // console.log(this.topList);
        // console.log("--------------------------------");
        // console.log(this.list);
      });
    },

    // 研学 click
    researchClick(item) {
      this.COMMON.routerSkip("/aboutUs/researchInfo", {
        id: item.id,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.shgsyx_wrap {
  min-height: calc(100vh - 109px);
  background: url("@/assets/about_us/focus/shgsyx/bg.png") no-repeat #000;
  background-size: 100%;
  color: #fff;
  padding: 80px 36px 50px;
  box-sizing: border-box;

  .head_box {
    .title {
      font-size: 30px;
      line-height: 48px;
    }
  }

  .shgsyx_container {
    margin-top: 30px;

    img {
      width: 100%;
      display: block;
    }

    .list_box {
      display: flex;
      flex-wrap: wrap;

      .item_box {
        margin-right: 1.4%;
        margin-bottom: 30px;
        cursor: pointer;
        position: relative;

        .title {
          font-size: 14px;
          text-align: justify;
        }

        &.top_item_box {
          width: 49.3%;

          &:nth-child(2n) {
            margin-right: 0;
          }

          .title {
            width: 100%;
            padding: 8px;
            box-sizing: border-box;
            background-color: rgba(0, 0, 0, 0.6);
            position: absolute;
            bottom: 0;
          }
        }

        &.notop_item_box {
          width: 32.4%;

          &:nth-child(3n) {
            margin-right: 0;
          }

          .title {
            margin-top: 12px;
          }
        }
      }
    }
  }
}
</style>
