<template>
  <div class="lxfs_wrap">
    <div class="head_box">
      <img src="@/assets/about_us/contact/lxfs/bg1.png" />

      <div class="title">联系我们</div>
    </div>

    <div class="lxfs_container">
      <div class="lxfs_box">
        <div class="lxfs_item lxfs_item1">
          <img src="@/assets/about_us/contact/lxfs/icon1.png" class="icon" />

          <div class="list_box list_box1 list_box4">
            <div class="item_box">
              <div class="name">公司地址：</div>
              <div class="text">上海市徐汇区古美路1515号19号楼21层</div>
            </div>

            <div class="item_box item_box1">
              <div class="name">ADDRESS：</div>

              <div class="text">
                Rm. 21, Building.19#, No.1515, Gumei Road,Xuhui D.C Shanghai<br />
                Shanghai Yixue Zhixun Technology Development Co., Ltd
              </div>
            </div>

            <div class="item_box item_box1">
              <div class="name">附近交通：</div>
              <div class="text">
                9号线漕河泾开发区站、公交700路、92路、92B路、171路、735路、205路、763路、沪松线等
              </div>
            </div>
          </div>
        </div>

        <div class="lxfs_item lxfs_item1 lxfs_item2">
          <img src="@/assets/about_us/contact/lxfs/icon2.png" class="icon" />

          <div class="list_box list_box2">
            <div class="item_box item_box2">
              <div class="item">
                <div class="name">电子邮箱/EMAIL：</div>
                <div class="text">yx@yxcps.com</div>
              </div>

              <div class="item item1">
                <div class="name">投诉及建议QQ：</div>
                <div class="text">2881075048</div>
              </div>
            </div>

            <div class="item_box item_box2 item_box3">
              <div class="item">
                <div class="name">投诉及建议电子邮箱/EMAIL：</div>
                <div class="text">ts@yxcps.com</div>
              </div>

              <div class="item item1">
                <div class="name">投诉及建议微信/WeChat：</div>
                <div class="text">
                  <img src="@/assets/about_us/contact/lxfs/wx.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="lxfs_box lxfs_box1">
        <div class="lxfs_item">
          <img src="@/assets/about_us/contact/lxfs/icon3.png" class="icon" />

          <div class="list_box list_box3">
            <div class="item_box item_box4">
              <div class="name">销售服务专线/TEL：</div>
              <div class="text">400-673-6018</div>
            </div>

            <div class="item_box item_box4">
              <div class="name">VIP服务专线/TEL：</div>
              <div class="text">400-673-6019</div>
            </div>

            <div class="item_box item_box4">
              <div class="name">益学法律事务联系电话/TEL：</div>
              <div class="text">021-6084-0777</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.lxfs_wrap {
  .head_box {
    position: relative;

    img {
      width: 100%;
      display: block;
    }

    .title {
      font-size: 30px;
      color: #fff;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .lxfs_container {
    background: url("@/assets/about_us/contact/lxfs/bg2.png") no-repeat #fff;
    background-size: 100%;
    padding: 100px 10%;

    .lxfs_box {
      display: flex;

      .lxfs_item {
        display: flex;

        .icon {
          width: 50px;
          height: 50px;
        }

        .list_box {
          margin-left: 25px;

          .item_box {
            .name {
              font-size: 16px;
            }

            .text {
              margin-top: 10px;
              font-size: 12px;
              line-height: 20px;

              img {
                width: 140px;
                display: block;
              }
            }

            &.item_box1 {
              margin-top: 30px;
            }

            &.item_box2 {
              // display: flex;

              .item1 {
                margin-top: 30px;
              }
            }

            &.item_box3 {
              margin-left: 50px;
            }
          }

          &.list_box2 {
            display: flex;
          }

          &.list_box4 {
            // width: 75%;
          }
        }

        &.lxfs_item1 {
          width: 50%;
        }

        &.lxfs_item2 {
          justify-content: flex-end;
          margin-left: 30px;
        }
      }

      &.lxfs_box1 {
        margin-top: 100px;

        .list_box3 {
          display: flex;

          .item_box4 {
            margin-left: 60px;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1921px) {
  .lxfs_wrap {
    .lxfs_container {
      padding: 160px 14%;

      .lxfs_box {
        &.lxfs_box1 {
          margin-top: 160px;
        }
      }
    }
  }
}
</style>
