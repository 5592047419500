<template>
  <div class="home_wrap">
    <navbar_ :Lucency="false" :isBlack="true" :activeIndex="''" />

    <div class="home_container">
      <div class="affix_box">
        <el-affix target=".home_container" :offset="60">
          <div class="tab_list">
            <div
              class="tab_item"
              :class="{ tab_active: index == menuIndex }"
              v-for="(item, index) in menuList"
              :key="index"
              @click="tabClick(index)"
            >
              {{ item.name }}
            </div>
          </div>
        </el-affix>
      </div>

      <div class="main_content">
        <div v-for="(item, index) in menuList" :key="index">
          <component v-if="index == menuIndex" :is="item.comName"></component>
        </div>
      </div>
    </div>

    <footer_ />
  </div>
</template>

<script>
import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部

import grzl_ from "@/views/home/grzl/index.vue"; // 个人资料
import mmsz_ from "@/views/home/mmsz/index.vue"; // 密码设置
import smrz_ from "@/views/home/smrz/index.vue"; // 实名认证
import fxpc_ from "@/views/home/fxpc/index.vue"; // 风险评测

export default {
  data() {
    return {
      isLogin: localStorage.getItem("yx_isLogin") || false,
      userInfo: JSON.parse(localStorage.getItem("yx_userInfo")) || null,

      menuIndex: this.$route.query.tabId || 0,
      menuList: [
        {
          name: "个人资料",
          comName: "grzl_",
        },
        {
          name: "密码设置",
          comName: "mmsz_",
        },
        {
          name: "实名认证",
          comName: "smrz_",
        },
        {
          name: "风险评测",
          comName: "fxpc_",
        },
      ],
    };
  },
  components: {
    navbar_,
    footer_,
    grzl_,
    mmsz_,
    smrz_,
    fxpc_,
  },
  created() {
    if (!this.isLogin) {
      this.$router.push("/");
      return;
    }
  },
  mounted() {},
  methods: {
    // 左侧 tab click
    tabClick(index) {
      if (this.menuIndex == index) return;

      // console.log(index);

      // this.menuIndex = index;
      // window.scrollTo({
      //   top: 0,
      //   // behavior: "smooth", // 平滑滚动
      // });

      this.$router.push({
        path: "/home",
        query: {
          tabId: index,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.home_wrap {
  padding-top: 60px;

  .home_container {
    min-height: calc(100vh - 60px);
    display: flex;

    .affix_box {
      width: 10%;
      background: #000;

      /deep/.el-affix {
        width: auto !important;
      }

      .tab_list {
        height: calc(100vh - 60px);
        background: linear-gradient(
          -20deg,
          #04080f 0%,
          #0e2b4c 53%,
          #040a0e 100%
        );

        .tab_item {
          height: 75px;
          line-height: 75px;
          font-size: 14px;
          color: #fff;
          text-align: center;
          cursor: pointer;
          box-sizing: border-box;

          &.tab_active {
            font-weight: bold;
            background: linear-gradient(
              90deg,
              rgba(18, 133, 255, 0.25) 0%,
              rgba(44, 219, 255, 0.25) 100%
            );
            border-right: 3px solid;
            border-image: linear-gradient(180deg, #2cdbff 0%, #1285ff 100%) 1;
          }
        }
      }
    }

    .main_content {
      width: 90%;
      // padding-left: 10%;
    }
  }
}
</style>
