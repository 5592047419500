<template>
  <div class="set_pwd_wrap">
    <navbar_ :Lucency="false" :isBlack="true" :activeIndex="''" />

    <mmsz_ />

    <footer_ />
  </div>
</template>

<script>
import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部
import mmsz_ from "@/views/home/mmsz/index.vue"; // 密码设置

export default {
  data() {
    return {};
  },
  components: {
    navbar_,
    footer_,
    mmsz_,
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.set_pwd_wrap {
  padding-top: 60px;
}
</style>