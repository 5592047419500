// 个人中心
import home from '@/views/home/index.vue' // 首页
import protocol from '@/views/home/protocol/index.vue' // 注册协议
import set_pwd from '@/views/home/set_pwd/index.vue' // 设置密码（单页）
import order from '@/views/home/order/index.vue' // 我的订单

const routes = [
    {
        path: '/home',
        component: home,
        meta: {
            title: '益学堂-个人中心',
        },
    },
    {
        path: '/protocol',
        component: protocol,
        meta: {
            title: '益学堂-用户注册协议',
        },
    },
    {
        path: '/set_pwd',
        component: set_pwd,
        meta: {
            title: '益学堂-忘记密码',
        },
    },
    {
        path: '/order',
        component: order,
        meta: {
            title: '益学堂-我的订单',
        },
    },
]

export default routes;