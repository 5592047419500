<template>
  <div class="wrap_404">
    <navbar_ :Lucency="false" :isBlack="true" :activeIndex="''" />

    <div class="box_404">
      <img class="icon" src="@/assets/404/404.png" />

      <div class="text">
        Start from
        <a class="home" href="https://www.yxcps.com/"> home page</a>
      </div>
    </div>

    <footer_ />
  </div>
</template>

<script>
import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部

export default {
  name: "notFound",
  data() {
    return {};
  },
  components: {
    navbar_,
    footer_,
  },
};
</script>

<style lang="less" scoped>
.wrap_404 {
  box-sizing: border-box;
  padding-top: 60px;

  .box_404 {
    min-height: calc(100vh - 60px);
    box-sizing: border-box;
    background: url("@/assets/404/bg.png") no-repeat #000;
    background-size: 100%;
    color: #fff;
    padding-bottom: 10%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .icon {
      width: 36%;
      display: block;
      user-select: none;
    }

    .text {
      margin-top: 16px;
      font-size: 18px;

      .home {
        color: inherit;
        text-decoration: none;
        border-bottom: 1px solid #fff;
      }
    }
  }
}

@media (max-width: 980px) {
  .wrap_404 {
    .box_404 {
      .text {
        font-size: 14px;
      }
    }
  }
}
</style>