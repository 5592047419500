<template>
  <div class="yxgy_wrap">
    <div class="head_box">
      <div class="title">
        知识改变命运，爱心传递希望——<br />
        “益学堂公益图书馆”雅安之行
      </div>

      <div class="text">
        益学堂作为中国一家发展迅猛的在线投资者教育平台，一直以来都深知学习对人的重要性，用知识武装自己的必要性；同时益学堂作为“善的商业表达”践行者，又情系传播善意、传递温暖的热心公益事业。2016年4月13日，益学堂派出爱心代表前往四川雅安，向两所贫困学校捐赠了两所“益学公益图书馆”，这仅仅是我们爱心公益的第一步。
      </div>
    </div>

    <div class="yxgy_container">
      <div class="yxgy_list">
        <div class="item_title">
          <img src="@/assets/about_us/focus/yxgy/1.png" />
          满载爱心，关注教育---大坪乡中心小学
        </div>

        <div class="text">
          2016年4月13日上午十时，益学堂爱心代表启程前往四川雅安天全县大坪乡中心小学。大坪乡是典型的全国贫困乡，是“420雅安地震”后的重灾乡，而大坪乡中心小学是天全县仅有的几所小学之一。
        </div>

        <div class="text">
          大坪乡中心小学现有教学班14个，教工27人，在校学生337人，在灾后重建时拥有了基本的教学基础设施，但其他办学设施、教学硬件都非常不完善，这直接影响学校办学水平，间接影响学生们的学习质量。益学堂针对大坪乡中心小学的情况，捐赠15万元，用于建设图书馆。
        </div>

        <div class="img_box img_box1">
          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t1.png" />

            <div>爱心代表与大坪乡中心小学胡校长在沟通</div>
          </div>

          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t2.png" />

            <div>益学堂公益图书馆捐赠仪式</div>
          </div>
        </div>

        <div class="text">
          益学堂爱心代表在大坪乡中心小学“益学堂公益图书馆”捐赠仪式上发表了讲话，表示“捐赠图书馆是一次善的传递和交融，益学作为在线教育企业时刻关注孩子们的教育，因为孩子们是祖国的未来，只有照亮孩子们的前进道路，祖国的明天才是光明的”。
        </div>

        <div class="text">
          益学堂爱心代也向大坪乡全体学生们转达了刘荣桃董事长的千万叮嘱，希望孩子们：珍惜学习机会、克服学习困难，勤奋学习，回馈社会！
        </div>

        <div class="img_box img_box2">
          <img src="@/assets/about_us/focus/yxgy/t3.png" />

          <div>15万元捐助给大坪乡中心小学的学生们</div>
        </div>
      </div>

      <img src="@/assets/about_us/focus/yxgy/line.png" class="yxgy_line" />

      <div class="yxgy_list">
        <div class="item_title">
          <img src="@/assets/about_us/focus/yxgy/2.png" />
          公益在路上，爱的传递---圆梦新江小学
        </div>

        <div class="text">
          下午，益学一行爱心代表没有休息，直接来到位于宝兴县陇东镇的新江小学。沿路都是崎岖的山路，很不好走。“崎岖的山路孩子们如何上学”带着这样的疑问，益学爱心代表抵达了新江小学。
        </div>

        <div class="text">
          新江小学是一所乡村小学，学校规模很小，全校教职工11人，在校学生三十余人。在“420雅安地震”后，这里的校舍虽然已经全部重建，但教学条件还是非常的不好。老师们的办公座椅是从乡长那里拉来的旧椅子，每把椅子款式各异并且破烂不堪。由于大部分都是山路，有部分孩子们要走上一个小时的山路来学校上课，基于现实情况，益学堂捐助10万元人民币，除了用于“益学公益图书馆”的建设外，还包括老师办公用品的采购、学生们校服和运动鞋的购买。
        </div>

        <div class="text">
          益学爱心代表表示，“益学爱心捐助会根据各地方情况进行调整，帮助新江小学的孩子们一年四季的校服和运动鞋，一是希望帮助他们的家庭缓解一部分开支，二是希望提升学校的整体感觉。同时，我们也会对善款的用途加以监督，把钱用在最需要帮助的地方，落实到每一个环节上。公益是一种爱的传递，每个人都有义务让爱有效地传递下去。”
        </div>

        <div class="img_box img_box1">
          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t4.png" />

            <div>10万元捐助给圆梦新江小学的学生们</div>
          </div>

          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t5.png" />

            <div>益学堂公益图书馆捐赠仪式</div>
          </div>
        </div>

        <div class="text">
          同时益学堂爱心代表也代表益学堂董事长刘荣桃向同学们进行的问候，并转告孩子们：山路虽然崎岖，只要有爱在，人生依旧是坦途！
        </div>

        <div class="img_box img_box2">
          <img src="@/assets/about_us/focus/yxgy/t6.png" />

          <div>左二：新江小学汪校长 左三：益学堂爱心代表</div>
        </div>

        <div class="text">
          益学的公益事业在雅安迈出了第一步，这仅仅是一个开始。公益不是百米冲刺，公益是细水长流，是万众一心，益学堂欢迎更多在益学平台听课并热心公益的人一起加入我们的公益慈善事业！
        </div>
      </div>

      <img src="@/assets/about_us/focus/yxgy/line.png" class="yxgy_line" />

      <div class="yxgy_list">
        <div class="item_title">
          <img src="@/assets/about_us/focus/yxgy/3.png" />
          爱不止步，温暖继续——“益学堂”公益活动红十字会
        </div>

        <div class="text">
          帮助孩子实现梦想，让老人们老有所乐，这就是益学堂的“益”，我们要一直做“善的商业表达”的践行者！
        </div>

        <div class="text">实现梦想、老有所乐—走进湖南永锋村</div>

        <div class="text">
          7月14日，益学堂公益志愿者走进湖南省长沙市青山桥镇永锋村，由上海益学公益志愿者，代表公司向长沙市红十字会捐赠人民币20万元，长沙市红十字会党组书记、专职副会长唐俊杰代表市红十字会接受了捐赠。
        </div>

        <div class="text">
          永锋村位于宁乡县偏远山区青山桥镇，与涟源交界。2005年有永宁、石龙、梅树三村合并的行政村，村域面积5.8平方公里，水田旱土面积2500亩。全村共有11个村民小组，776户2876人，党员72人，其中贫困人口440人，三无人员和低保户135人，为省级贫困村。
        </div>

        <div class="img_box img_box2">
          <img src="@/assets/about_us/focus/yxgy/t7.png" />

          <div>红十字会工作人员向益学堂爱心代表介绍永锋村的概况</div>
        </div>

        <div class="text">
          益学堂这笔捐款将定向用于省级贫困村宁乡县青山桥镇永锋村村小学和老年活动中心建设，以切实改善村小办学条件，为老师学生创造更好的学习工作环境，并为该村老年村民提供配套完善的休闲娱乐活动场所，让老人们老有所乐。
        </div>

        <div class="text">
          长沙市红十字会党组书记、专职副会长唐俊杰表示，一定按照捐赠方的意愿，和长沙市文广新局一起，督导永锋村做好村小学和老年活动中心的基础设施建设。
        </div>

        <div class="img_box img_box1">
          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t8.png" />

            <div>10万元捐助给圆梦新江小学的学生们</div>
          </div>

          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t9.png" />

            <div>
              左：长沙市文化广电新闻出版局派驻永锋村负责精准扶贫工作的第一书记朱鹏；中：上海益学爱心代表；右：长沙市红十字会党组书记、专职副会长唐俊杰
            </div>
          </div>
        </div>
      </div>

      <img src="@/assets/about_us/focus/yxgy/line.png" class="yxgy_line" />

      <div class="yxgy_list">
        <div class="item_title">
          <img src="@/assets/about_us/focus/yxgy/4.png" />
          六一礼物——浦东旅游小达人之公益餐布市集
        </div>

        <div class="text">
          2016年6月5日，由上海市浦东新区商务委（旅游局）联合东方财经·浦东频道共同主办的"六一礼物——浦东旅游小达人之公益餐布市集"于上海浦东世纪公园热烈举行。
        </div>

        <div class="text">
          作为"浦东旅游小达人暨微电影小主角选拔活动"下的人气拉票活动，携手新闻晨报、小荧星及近十家社会爱心企业，通过全天慈善义卖、义捐的方式，通过上海仁德基金会支持下的上海惠迪吉公益人心理关爱中心"爱的共振腔"建设陕西三原行公益项目，为陕西三原县的留守儿童送上了一份暖心的"六一"礼物。
        </div>

        <div class="img_box img_box1">
          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t10.png" />

            <div>小朋友们积极参与爱心募捐</div>
          </div>

          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t11.png" />

            <div>十余位小朋友现场献上精彩表演</div>
          </div>
        </div>

        <div class="text">
          活动中，30组来自"浦东旅游小达人暨微电影小主角选拔活动"的报名家庭在自家的帐篷中义卖闲置的书籍、玩具、学习用品等，几位才华横溢的小朋友还在现场即兴表演，为自己拉票鼓劲的同时也为义卖活动助力。
        </div>

        <div class="text">
          十余位小朋友现场献上精彩表演，这批未来的小达人们能说会道，载歌载舞，赢得掌声一片。在记者的采访中，家长们纷纷表示：这样的活动非常有意义，不仅能将闲置的资源循环利用，还能为山区留守儿童献一份爱心，在实践体验中教会孩子绿色环保和慈善之心。
        </div>

        <div class="img_box img_box1">
          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t12.png" />

            <div>益学堂爱心代表与参与活动家庭一起玩耍</div>
          </div>

          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t13.png" />

            <div>
              益学堂爱心代表与浦东新区商务委员会（旅游局）旅游会展处张奎堂处长在交流
            </div>
          </div>
        </div>

        <div class="text">
          当天现场收到个人和企业的爱心捐款总计17223.2元，上海市浦东新区商务委员会（旅游局）旅游会展处张奎堂处长代表主办方，向上海仁德基金会支持上海惠迪吉公益人心理关爱中心"爱的共振腔"建设陕西三原行送上爱心支票。
        </div>

        <div class="text">益学堂公益行，爱不止步，温暖继续…</div>

        <div class="img_box img_box1">
          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t14.png" />

            <div>益学堂爱心代表现场接受东方财经频道采访</div>
          </div>

          <div class="img_item">
            <img src="@/assets/about_us/focus/yxgy/t15.png" />

            <div>益学堂爱心代表现场慷慨捐赠了一万元善款</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.yxgy_wrap {
  .head_box {
    background: url("@/assets/about_us/focus/yxgy/bg.png") no-repeat;
    background-size: 100%;
    color: #fff;
    padding: 80px 4% 40px;

    .title {
      font-size: 30px;
      line-height: 48px;
    }

    .text {
      font-size: 14px;
      text-align: justify;
      margin-top: 40px;
      line-height: 24px;
    }
  }

  .yxgy_container {
    padding: 50px 8%;

    img {
      width: 100%;
      display: block;
    }

    .yxgy_list {
      .item_title {
        font-size: 16px;
        color: #1285ff;
        font-weight: bold;
        padding-bottom: 4px;
        display: inline-flex;
        align-items: center;

        border-bottom: 2px solid;
        border-image: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%) 1;

        img {
          width: 18px;
          margin-right: 6px;
        }
      }

      .text {
        margin-top: 30px;
        font-size: 14px;
        text-align: justify;
      }

      .img_box {
        margin-top: 30px;
        text-align: center;

        div {
          margin-top: 15px;
          font-weight: bold;
          font-size: 14px;
        }

        &.img_box1 {
          display: flex;
          justify-content: space-between;

          .img_item {
            width: 49%;
          }
        }

        &.img_box2 {
        }
      }
    }

    .yxgy_line {
      margin: 30px 0 50px;
    }
  }
}
</style>
