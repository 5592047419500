// 会员中心
import vip from '@/views/vip_center/index.vue' // 首页

const routes = [
    {
        path: '/vip',
        name: 'vip',
        component: vip,
        meta: {
            title: '益学堂-会员中心',
        },
    },
]

export default routes;