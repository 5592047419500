<template>
  <router-view :key="$route.fullPath" />

  <websocket />
</template>

<script>
import websocket from "@/views/websocket/index.vue";

export default {
  components: {
    websocket,
  },
  mounted() {
    window.addEventListener("storage", (event) => {
      if (event.key === "yx_isLogin") {
        // console.log("yx_isLogin:", event.newValue);

        // 更新页面状态
        location.reload();
      }
    });
  },
};
</script>

<style lang="less">
* {
  margin: 0;
  padding: 0;
}

body {
  letter-spacing: 1px;
}
</style>

