<template>
  <div class="gsjs_wrap">
    <!-- <div class="video_box">
      <div class="video">
        <video
          ref="myVideo"
          class="myVideo"
          poster="//stocksf.yxcps.com/cc/media/about_gsjs_bg.png"
          src="//stocksf.yxcps.com/cc/media/about_gsjs.mp4"
          :muted="shouldMute"
          loop
        ></video>

        <div class="mute_box" @click="shouldMute = !shouldMute">
          <img
            :src="
              shouldMute
                ? require('@/assets/about_us/about/nosound.png')
                : require('@/assets/about_us/about/sound.png')
            "
            class="sound"
          />
        </div>
      </div>
    </div> -->

    <div class="gsjs_container">
      <div class="idea_box">
        <div class="title">经营理念</div>

        <div class="text_box">
          <div class="text">
            在线学炒股
            就上益学堂。公司始终秉持以“授人以渔”为核心的培训模式，以“易学、易懂、易掌握”为根本的经营理念，建立全方位客户服务、多渠道培训体系，为投资者创造更便利、更轻松、更专业的学习服务体验。
          </div>

          <div class="text">
            “授人以鱼不如授人以渔”，益学堂在选时、选股、买卖、风控和心态等5个方面，帮助投资者构建专属的操盘知识体系。希望每一位投资者快乐炒股，有效提升投资技能，尽可能规避股市风险，获得更好的收益。
          </div>
        </div>

        <div class="text_box2">
          <div class="text_item">
            <img src="@/assets/about_us/about/gsjs/icon1.png" />

            <div class="item_box">
              <div class="text_title">公司愿景：</div>
              <div class="text">具有专业品质和职业精神的投资者教育专业机构</div>
            </div>
          </div>

          <div class="text_item">
            <img src="@/assets/about_us/about/gsjs/icon2.png" />

            <div class="item_box">
              <div class="text_title">公司价值观：</div>
              <div class="text">守法、合规，专业、敬业、感恩、互助</div>
            </div>
          </div>

          <div class="text_item">
            <img src="@/assets/about_us/about/gsjs/icon3.png" />

            <div class="item_box">
              <div class="text_title">公司的使命：</div>
              <div class="text">提升客户的抗风险能力</div>
            </div>
          </div>
        </div>
      </div>

      <div class="honor_box">
        <div class="title">我们的荣誉</div>

        <div class="swiper_box">
          <div class="arrow_left" @click="arrowClick('left')"></div>

          <el-carousel
            ref="honor_swiper"
            type="card"
            :autoplay="false"
            indicator-position="none"
            arrow="never"
          >
            <el-carousel-item v-for="(item, index) in dataList1" :key="index">
              <img :src="item.image" />
            </el-carousel-item>
          </el-carousel>

          <div class="arrow_right" @click="arrowClick('right')"></div>
        </div>
      </div>

      <div class="brand_box">
        <div class="title">品牌力量</div>

        <div class="text">
          曾经以北上广作为业务发展核心，覆盖全国及重点区域推广。推广范围遍及机场巨幅广告、公交车身广告、的士、传媒杂志、电视电台广告等。"在线学炒股，就上益学堂。"这句理念广告推广语已然成为投资教育行业极具份量的宣传热点。
        </div>

        <div class="brand_list">
          <el-row :gutter="28">
            <el-col :span="8">
              <img src="@/assets/about_us/about/gsjs/brand1.png" />
            </el-col>
            <el-col :span="8">
              <img src="@/assets/about_us/about/gsjs/brand2.png" />
            </el-col>
            <el-col :span="8">
              <img src="@/assets/about_us/about/gsjs/brand3.png" />
            </el-col>
          </el-row>
          <el-row :gutter="28">
            <el-col :span="8">
              <img src="@/assets/about_us/about/gsjs/brand4.png" />
            </el-col>
            <el-col :span="8">
              <img src="@/assets/about_us/about/gsjs/brand5.png" />
            </el-col>
            <el-col :span="8">
              <img src="@/assets/about_us/about/gsjs/brand6.png" />
            </el-col>
          </el-row>
        </div>
      </div>

      <div class="research_box">
        <div class="title">研发力量</div>

        <div class="sub_title">益学堂拥有强大的研发团队与实力</div>
      </div>

      <div class="research_box2">
        <div class="list_box">
          <div
            class="item_box"
            v-for="(item, index) in dataList2"
            :key="index"
            :class="'item_box1'"
            :style="{
              '--dataLength': dataList2.length,
              '--dataIndex': index + 1,
              '--duration': duration,
            }"
          >
            <img :src="item.image" />
          </div>
        </div>

        <div class="list_box list_box2">
          <div
            class="item_box"
            v-for="(item, index) in dataList2"
            :key="index"
            :class="['item_box1', 'item_box2']"
            :style="{
              '--dataLength': dataList2.length,
              '--dataIndex': index + 1,
              '--duration': duration,
            }"
          >
            <img :src="item.image" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      shouldMute: true,
      dataList1: [],
      dataList2: {
        even: [],
        odd: [],
      },
      duration: "30s",
    };
  },
  created() {
    this.getMaterialList(); // 获取 荣誉素材 list
  },
  mounted() {},
  methods: {
    // 获取 荣誉素材 list
    getMaterialList() {
      let data = {};

      this.API.CLOUDGet(
        "cms/api/website/material/honor-material/list",
        data
      ).then((res) => {
        // console.log(res);
        let datas = res.data.data;
        // console.log(datas);

        this.dataList1 = datas[0].items;

        this.dataList2 = datas[1].items;

        // for (let i = 0; i < datas[1].items.length; i++) {
        //   if (i % 2 === 0) {
        //     this.dataList2.even.push(datas[1].items[i]);
        //   } else {
        //     this.dataList2.odd.push(datas[1].items[i]);
        //   }
        // }
      });
    },

    // 荣誉 左右箭头 click
    arrowClick(text) {
      if (text == "left") this.$refs.honor_swiper.prev();

      if (text == "right") this.$refs.honor_swiper.next();
    },
  },
};
</script>

<style lang="less" scoped>
.gsjs_wrap {
  .video_box {
    min-height: calc(100vh - 109px);
    background: url("@/assets/about_us/about/gsjs/bg.png") no-repeat;
    background-size: cover;

    display: flex;
    justify-content: center;
    align-items: center;

    .video {
      width: 76%;
      padding: 1.6% 2.3% 2.65%;
      box-sizing: border-box;
      background: url("@/assets/about_us/about/gsjs/bg1.png") no-repeat;
      background-size: 100% 100%;
      position: relative;

      .myVideo {
        width: 100%;
        display: block;
      }

      .mute_box {
        cursor: pointer;
        position: absolute;
        bottom: 6%;
        right: 4%;

        .sound {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .gsjs_container {
    .honor_box {
      // min-height: calc(100vh - 109px);
      background: url("@/assets/about_us/about/gsjs/bg2.png") no-repeat;
      background-size: cover;
      padding: 80px 0;

      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;

      .title {
        padding-bottom: 60px;
        font-size: 30px;
        color: #1285ff;
      }

      .swiper_box {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .arrow_left,
        .arrow_right {
          width: 48px;
          height: 48px;
          cursor: pointer;
        }

        .arrow_left {
          margin-right: 36px;
          background: url("@/assets/about_us/about/gsjs/left.png") no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("@/assets/about_us/about/gsjs/left_active.png")
              no-repeat;
            background-size: 100% 100%;
          }
        }

        .arrow_right {
          margin-left: 36px;
          background: url("@/assets/about_us/about/gsjs/right.png") no-repeat;
          background-size: 100% 100%;

          &:hover {
            background: url("@/assets/about_us/about/gsjs/right_active.png")
              no-repeat;
            background-size: 100% 100%;
          }
        }

        /deep/.el-carousel {
          width: 62%;

          .el-carousel__container {
            height: 500px;

            .el-carousel__item {
              display: flex;
              align-items: center;

              img {
                width: 100%;
                display: block;
              }
            }
          }
        }
      }
    }

    .idea_box {
      background: url("@/assets/about_us/about/gsjs/bg3.png") no-repeat;
      background-size: cover;
      color: #fff;
      font-size: 14px;
      padding: 120px 40px 180px;

      .title {
        font-size: 30px;
        color: #1285ff;
      }

      .text_box {
        margin-top: 140px;

        .text {
          margin-top: 18px;
        }
      }

      .text_box2 {
        margin-top: 140px;
        display: flex;
        justify-content: space-between;

        .text_item {
          display: flex;
          align-items: center;

          img {
            width: 48px;
            margin-right: 14px;
          }

          .item_box {
            .text_title {
              font-weight: bold;
            }

            .text {
              margin-top: 8px;
            }
          }
        }
      }
    }

    .brand_box {
      background: url("@/assets/about_us/about/gsjs/bg4.png") no-repeat;
      background-size: cover;
      color: #fff;
      font-size: 14px;
      padding: 80px 40px;

      .title {
        font-size: 30px;
        color: #1285ff;
      }

      .text {
        margin-top: 30px;
      }

      .brand_list {
        margin-top: 30px;

        .el-row {
          margin-bottom: 24px;

          &:last-child {
            margin-bottom: 0;
          }

          img {
            width: 100%;
            display: block;
          }
        }
      }
    }

    .research_box {
      min-height: calc(100vh - 109px);
      box-sizing: border-box;
      background: url("@/assets/about_us/about/gsjs/bg5.png") no-repeat #fff;
      background-size: 100%;
      padding-top: 4.5%;
      text-align: center;

      .title {
        font-size: 30px;
        color: #1285ff;
      }

      .sub_title {
        font-size: 18px;
        margin-top: 16px;
      }
    }

    .research_box2 {
      min-height: calc(100vh - 109px);
      box-sizing: border-box;
      background: url("@/assets/about_us/about/gsjs/bg6.png") no-repeat #fff;
      background-size: 100%;
      padding: 80px 0;

      .list_box {
        width: 60vw;
        height: 281.25px;
        margin: 0 auto;
        mask-image: linear-gradient(
          90deg,
          hsl(0 0% 0% / 0),
          hsl(0 0% 0% / 1) 20%,
          hsl(0 0% 0% / 1) 80%,
          hsl(0 0% 0% / 0)
        );
        background: url("@/assets/about_us/about/gsjs/bg6_1.png") no-repeat;
        background-size: 100%;
        background-position: bottom center;
        padding-bottom: 4.5%;
        position: relative;

        &:has(.item_box:hover) {
          .item_box {
            animation-play-state: paused;
          }
        }

        .item_box {
          width: 200px;
          position: absolute;
          top: 0;
          left: 0;
          animation: scrolling var(--duration) linear infinite;
          cursor: pointer;
          z-index: 2;

          img {
            width: 200px;
            display: block;
          }

          &.item_box1 {
            transform: translateX(calc(200px * var(--dataLength)));
            animation-delay: calc(
              (var(--duration) / var(--dataLength)) *
                (var(--dataLength) - var(--dataIndex)) * -1
            );
          }

          &.item_box2 {
            animation-direction: reverse;
          }

          @keyframes scrolling {
            to {
              transform: translateX(calc(200px * -1));
            }
          }
        }

        &.list_box2 {
          margin-top: 40px;
        }
      }
    }
  }
}

@media (min-width: 1921px) {
  .gsjs_wrap {
    .gsjs_container {
      .honor_box {
        padding: 120px 0 140px;

        .title {
          padding-bottom: 100px;
        }
      }

      .research_box2 {
        padding: 140px 0 60px;

        .list_box {
          &.list_box2 {
            margin-top: 80px;
          }
        }
      }
    }
  }
}
</style>
