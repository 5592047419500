<template>
  <div class="protocol_wrap">
    <p>&nbsp;</p>
    <h4 align="center">用户注册协议</h4>
    <p>&nbsp;</p>
    <p><strong>1.</strong><strong>益学堂服务条款的确认和接受</strong></p>
    <p>
      益学堂的各项网络服务的所有权和运营权归上海益学智迅科技发展有限公司（以下称&ldquo;益学堂&rdquo;）所有。益学堂依照本服务条款及其不时发布的操作规则提供网络服务，此外，当使用益学堂各项服务的学员（以下称&ldquo;学员&rdquo;）使用益学堂各项分类服务时，学员应当同意本服务条款的全部内容并遵守与该项服务相关的规则与要点。前述所有规则与要点均构成本服务条款的一部分。学员必须完全同意所有服务条款并完成注册程序，才能成为益学堂的正式学员。
    </p>
    <p><strong>2.</strong><strong>服务说明</strong></p>
    <p>
      益学堂仅为学员提供相关的网络服务。学员必须自行配备上网的所需设备（如个人电脑、手机、调制解调器或其他必备上网装置）及所需费用（如上网所支付的与此服务有关的电话费用、
      网络费用、手机费用等）。 基于益学堂所提供的服务的重要性，学员应同意：
    </p>
    <ul>
      <li>(1)提供详尽、准确的个人资料。</li>
      <li>(2)不断更新注册资料，符合及时、详尽、准确的要求。</li>
    </ul>
    <p><strong>3.</strong><strong>服务的变更、中断或终止</strong></p>
    <p>
      鉴于网络服务的特殊性，益学堂有权随时变更或中断或终止部分或全部网络服务，不需对学员或第三方负责。
      为了网站的正常运行，益学堂定期或不定期地对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护而造成网络服务的中断，益学堂将尽力避免服务中断或将中断时间限制在最短时间内，在合理时间内的服务中断，益学堂无需为此承担任何责任。
      如发生下列任何一种情形，益学堂有权随时中断或终止向学员提供网络服务（包括收费网络服务）而无需对学员或任何第三方承担任何责任：
    </p>
    <ul>
      <li>（1） 学员提供的资料不真实；</li>
      <li>（2） 学员违反本服务条款的有关规定；</li>
      <li>（3） 学员在使用收费网络服务时未按规定向益学堂支付相应的服务费。</li>
    </ul>
    <p><strong>4.</strong><strong>网上注册</strong></p>
    <p>
      学员在申请使用益学堂网络服务时，必须向益学堂提供准确的个人资料，如个人资料有任何变动，必须及时更新。学员一旦注册成功，成为益学堂的合法学员，将得到一个密码和学员名。每个学员应当对以其学员名进行的所有活动和事件承担全部责任。
      学员在此同意接受益学堂通过电子邮件或其他方式向学员发送宣传推广或者其他相关商业信息。
      在不对外披露单个学员隐私资料的前提下，益学堂有权对整个学员数据库进行分析并对学员数据库进行商业上的利用。
    </p>
    <p><strong>5.</strong><strong>学员名、学员密码和安全性</strong></p>
    <p>
      学员将对学员名和密码安全承担全部责任。学员可随时根据提示改变学员的密码。学员不应将其帐号、密码转让或出借予他人使用。如学员发现其帐号遭他人非法使用或存在安全漏洞的情况，应立即通知益学堂。因黑客行为或学员的保管疏忽导致帐号、密码遭他人非法使用，益学堂不承担任何责任。
      如学员注册的免费网络服务的帐号在任何连续180日内未实际使用，或者学员注册的收费网络服务的帐号在其订购的收费网络服务的服务期满之后连续180日内未实际使用，则益学堂有权删除该帐号并停止为该学员提供相关的网络服务。
    </p>
    <p><strong>6.</strong><strong>学员隐私制度</strong></p>
    <p>
      尊重和保护学员个人隐私是益学堂的一项基本政策。益学堂不会在未经合法学员授权时对外披露学员注册资料及学员保存在益学堂网中的非公开内容，但下列情况除外：
    </p>
    <ul>
      <li>(1) 事先获得学员的明确授权；</li>
      <li>(2) 根据有关法律法规的规定或相关政府主管部门的要求；</li>
      <li>(3) 为维护社会公共利益的需要；</li>
      <li>(4) 为维护益学堂的合法权益。</li>
    </ul>
    <p><strong>7.</strong><strong>对学员的管理</strong></p>
    <p>学员必须遵守以下原则</p>
    <ul>
      <li>(1) 遵守中国有关法律和法规；</li>
      <li>(2) 遵守所有使用网络服务的网络协议、规定、程序和惯例；</li>
      <li>(3) 不得为任何非法目的而使用网络服务系统；</li>
      <li>
        (4)
        不得传输任何骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗的、淫秽的、教唆他人实施犯罪行为等非法的信息资料；
      </li>
      <li>
        (5)
        不得侵犯其他任何第三方的专利权、著作权、商标权、名誉权或其他任何合法权益；
      </li>
      <li>(6) 不得利用益学堂网络服务系统进行任何不利于益学堂的行为；</li>
      <li>(7) 不干扰或混乱网络服务；</li>
      <li>(8) 未经许可不得非法进入其它电脑系统。</li>
    </ul>
    <p>
      学员需独立对自己在网上的行为承担法律责任。若学员的行为不符合上述服务条款，堂益学堂有权做出独立判断立即取消学员的服务帐号，学员若在益学堂网上散布和传播反动、色情或其他违反国家法律的信息，益学堂的系统记录有可能作为学员违反法律的证据。
    </p>
    <p>
      益学堂不对学员所发布信息的删除或储存失败承担任何责任。益学堂有权判定学员的行为是否符合中国法律法规的规定以及益学堂服务条款的要求，如果学员违背了中国法律法规的规定或服务条款的规定，益学堂有中断对其提供服务的权利。对于学员通过益学堂网络技术服务（包括但不限于论坛、BBS、新闻评论、博客、图片、网摘等频道）上传到益学堂网上可公开获取区域的任何内容，学员同意授予益学堂在全世界范围内永久性的、不可撤销的、免费的、非独家的和再许可的权利和许可，益学堂可以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示该等内容的全部或部分，和/或将此等内容的全部或部分编入其他任何形式的作品、媒体或技术中。
    </p>
    <p><strong>8.</strong><strong>网络服务内容的所有权</strong></p>
    <p>
      益学堂定义的网络服务内容包括：益学堂提供的文字、软件、声音、图片、录象、图表、广告中的全部内容、电子邮件的全部内容以及益学堂益学堂为学员提供的其他信息。所有这些内容受版权、商标和/或其它财产所有权法律的保护。所以，学员只能在益学堂和相关权利人授权下才能使用这些内容，而不能擅自复制、发布、转载、播放、改编、汇编或以其他方式使用这些内容或将该等内容用于其他任何商业目的。
    </p>
    <p><strong>9.</strong><strong>链接</strong></p>
    <p>
      益学堂网站内设有通往其他网站和网页的链接，但这些网站和网页并非由益学堂经营或控制，益学堂不承担责任。学员启动任何此类链接或网页，离开益学堂网站进入其他网站或网页，所有风险自负，益学堂不承担一切责任和债务。
    </p>
    <p><strong>10.</strong><strong>免责声明</strong></p>
    <p>
      学员同意对益学堂网络服务的使用承担全部风险，并对因其使用益学堂网络服务而产生的一切后果承担全部风险，益学堂对学员不作任何类型的担保，也不承担任何责任。益学堂不担保网络服务一定能满足学员的要求，也不担保网络服务不会中断，对服务的及时性、安全性、准确性都不作担保。对于因不可抗力或益学堂不能避免或控制的原因造成的网络服务中断或其它缺陷，益学堂不承担任何责任，但将尽力减少因此而给学员造成的损失和影响。
    </p>
    <p><strong>11.</strong><strong>损害赔偿责任</strong></p>
    <p>
      学员同意保障和维护益学堂及其他学员的利益，如因学员违反有关法律、法规或本服务条款的任何条款而给益学堂或任何其他第三人造成损失，学员同意承担由此造成的损害赔偿责任。
      益学堂对学员使用网络服务所产生的任何直接、间接、偶然、特殊及继起的损害不负责任，这些损害可能来自：不正当使用网络服务、在网上购买商品或进行同类型服务、在网上进行交易、非法使用网络服务或学员传送的信息有所变动。
    </p>
    <p><strong>12.</strong><strong>侵权责任</strong></p>
    <p>
      网络学员、网络服务提供者利用网络侵害他人民事权益的，应当承担侵权责任。学员利用益学堂实施侵权行为的，被侵权人有权通知益学堂采取删除、屏蔽、断开链接等必要措施。益学堂接到通知后未及时采取必要措施，对损害的扩大部分与该学员承担连带责任。益学堂知道学员利用益学堂网络服务侵害他人民事权益，未采取必要措施，与学员承担连带责任。
    </p>
    <p><strong>13.</strong><strong>服务条款的变更和修订</strong></p>
    <p>
      益学堂定义的网络服务内容包括：益学堂提供的文字、软件、声音、图片、录象、图表、广告中的全部内容、电子邮件的全部内容以及益学堂为学员提供的其他信息。所有这些内容受版权、商标和/或其它财产所有权法律的保护。所以，学员只能在益学堂和相关权利人授权下才能使用这些内容，而不能擅自复制、发布、转载、播放、改编、汇编或以其他方式使用这些内容或将该等内容用于其他任何商业目的。
    </p>
    <p><strong>14.</strong><strong>适用法律</strong></p>
    <p>
      本服务条款的订立、执行和解释及争议的解决均应适用中国法律并受中国法院管辖。如本服务条款与中华人民共和国法律相抵触时，则该等条款将按法律规定重新修订，而其它条款则依旧有效并具有法律效力。
      如双方就本服务条款内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向益学堂所在地的人民法院提起诉讼。
    </p>
    <p><strong>15.</strong><strong>益学声明</strong></p>
    <p>
      本公司主要从事证券投资培训业务，在从事培训活动中无法避免地以个股为案例进行讲解、分析。本公司、讲师及员工对前述个股的引用不代表本公司讲师、及员工向学员推荐个股和/或提供其他任何证券投资方面的建议。本公司开展培训活动仅向客户收取培训费用，该等费用并不包含任何推荐个股、提供投资建议和咨询的费用。请学员自行判断，谨慎投资。
    </p>
    <p><strong>16.</strong><strong>其他规定</strong></p>
    <p>本服务条款中的标题仅为方便而设，在解释本服务条款时应被忽略。</p>
    <p>&nbsp;</p>
    <h4 align="center">培训服务协议</h4>
    <p>&nbsp;</p>
    <p>
      &nbsp;欢迎使用益学堂金融实战培训平台，在使用益学堂金融实战培训平台之前，乙方应当认真阅读并遵守《培训服务协议》（以下简称&ldquo;本协议&rdquo;）及《学员培训服务条款》，请乙方务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、争议解决和法律适用条款。如乙方对条款有任何疑问的，应向甲方客服咨询。
    </p>
    <p><strong>第一条、申明与提示</strong></p>
    <p>
      1、益学堂金融实战培训平台（www.yxcps.com/www.yxcps.cn，以下简称&ldquo;本公司官网&rdquo;）是甲方旗下网站，益学堂金融实战培训系统是甲方官网内置软件系统。甲方通过益学堂实战培训系统提供在线金融培训服务，提供的金融培训内容以购买的课程的订单信息为准，包括但不限于以下内容：
    </p>
    <p>&nbsp; &nbsp; ①、线上高级培训课（月课、季课、半年课、年课）</p>
    <p>&nbsp; &nbsp; ②、线下高级培训课（月课、季课、半年课、年课）</p>
    <p>
      2、乙方通过益学堂金融实战培训系统购买课程服务，加入益学堂金融实战培训平台，于合同期间内使用益学堂所提供的在线金融培训服务。课程类型、授课讲师、课时及价格都在系统界面已有明确的显示。乙方登录系统点击购买即视为同意接受该课程培训服务内容。如乙方非完全民事行为能力人，需由乙方的法定代理人（或监护人）阅读、了解且同意本协议全部条款并付费购买培训课程。
    </p>
    <p>
      3、乙方了解并同意，培训课程的授课内容以实际课程中所涉及的为准，允许授课讲师根据实时行情进行调整，但课程内容不会缩减。
    </p>
    <p>
      4、甲方及甲方授课讲师，以谨慎、诚实和勤勉尽责的态度完整、客观、准确的为乙方提供培训课程。
    </p>
    <p>
      5、乙方通过学习甲方提供的培训课程，掌握了相应的投资分析、判断的基本知识、方法和技巧后，可自主做出投资决策，并自行承担相应的投资风险和损失。
    </p>
    <p><strong>第二条 服务费用及付款方式</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      本协议服务费以官网所示课程价格为准。于购买服务时，应即以汇款、在线刷卡或其他甲方同意方式将款项支付予本公司。本服务将于乙方付清全额款项后开通使用。
    </p>
    <p><strong>第三条 合同期间</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      合同期间即服务有效使用期自课程开班起到课程结束以益学堂官网公布的课时为准。学员应于合同期间内使用完所有课时，于合同期间内未使用完毕的课时，于合同期间届满日自动失效。
    </p>
    <p><strong>第四条 服务使用规则</strong></p>
    <p>(一) 一般规则</p>
    <p>1、 线上高级培训课</p>
    <p>
      &nbsp; &nbsp; &nbsp; &nbsp;a)&nbsp;&nbsp;&nbsp;&nbsp;
      乙方购买课程后，可享有观看直播和点播的服务，以及下载课件的服务。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp; &nbsp;b)&nbsp;&nbsp;&nbsp;&nbsp;
      每次在线课程为2课时，每课时60 分钟。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp; &nbsp;c)&nbsp;&nbsp;&nbsp;&nbsp;
      直播课程结束后，才会有点播课程可以观看。每课时有5次点播。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp; &nbsp;d)&nbsp;&nbsp;&nbsp;&nbsp;
      如乙方错过直播课程，则无法再次观看该课程直播内容，仅支持以点播方式观看。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp; &nbsp;e)&nbsp;&nbsp;&nbsp;&nbsp;
      乙方点播次数全部使用完毕时或者本合同期满后，甲方对于乙方的服务即视为终止，乙方不得再有所主张或请求。
    </p>
    <p>2、 线下高级培训课</p>
    <p>
      &nbsp; &nbsp; &nbsp; &nbsp;a)&nbsp;&nbsp;&nbsp;&nbsp;
      乙方购买线下高级培训课后，可享有线下课程服务，培训地点以实际通知为准，甲方会通过官网、电话、短信等方式进行通知。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp; &nbsp;b)&nbsp;&nbsp;&nbsp;&nbsp;
      乙方购买的线下课程结束或者本合同期满后，甲方对于乙方的服务即视为终止，乙方不得再有所主张或请求。
    </p>
    <p>3、 乙方购买课程的总课时数以实际课表为准。</p>
    <p>4、 乙方了解并同意乙方使用权限账号须全数缴清所购买服务款项后开通。</p>
    <p>
      5、 如乙方将自有账号出借或售卖给其他第三人使用，则本公司不承担相应责任。
    </p>
    <p>（二）赠送课时规则</p>
    <p>
      1、乙方购买课程后，甲方及甲方授课讲师会视乙方的学习进度情况，增加课程时间。赠送课时数及课程内容以课表为准。
    </p>
    <p>
      2、乙方了解并同意甲方所赠予的课程，须缴清款项、购买课程使用完毕且合同未到期时有效，若乙方有提前解除或终止合同，或有重大违约事由被撤销乙方资格者，不得请求给付赠与课程或以之折换价金。
    </p>
    <p>（三）退换课程规则</p>
    <p>
      1、购买课程之日起7天之内，且未上课，可申请7天无理由退课，申请时间截止至开课前2小时；
    </p>
    <p>2、不符合7天无理由退课的，需经过乙方申请和甲方审批：</p>
    <p>①购买线上课程已开课，申请退课需扣除已开课课程费用，退回剩余金额。</p>
    <p>②购买线下课程已开课，则不支持退课。</p>
    <p>
      ③购买课程之日起超过7天（包含线上及线下课程），且未上课，需经过乙方申请和甲方审批后，方可退课。
    </p>
    <p>④购买的课程已经结束，则不支持退课。</p>
    <p>3、已开课课程费用=课程总价&divide;课程总课时&times;已开课课时</p>
    <p>
      &nbsp; &nbsp;
      &nbsp;课程总价是指原课程总价，课程总课时是指原课程总课时。即使学员享受了优惠价、折扣价或赠与课程，仍以原课程总价、原课程总课时为基数计算已开课课程费用。
    </p>
    <p>4、如乙方购买了线下课程，但并未参与，则根据实际情况进行课程延期。</p>
    <p>5、乙方如需要换课，则按照先退课后购课的流程进行。</p>
    <p>
      6、如甲方授课讲师因客观情况不能正常授课，甲方可安排换课或退课，并不承担其他违约责任。
    </p>
    <p>
      7、乙方在退货时，应同时返还下述资料，否则甲方公司有权拒绝办理退货手续：
    </p>
    <p>a)&nbsp;&nbsp;&nbsp;&nbsp; 所有发票和/或收据原件；</p>
    <p>
      b)&nbsp;&nbsp;&nbsp;&nbsp;
      购买相关培训课程时甲方赠送的所有赠品；如赠品已拆封或损坏或开始使用，乙方应按赠品市场价值向甲方支付补偿款，甲方将在向乙方退还的款项中直接扣除该部分款项，此种情形下赠品将交付乙方本人继续使用。
    </p>
    <p>c)&nbsp;&nbsp;&nbsp;&nbsp; 甲方公司赠送或交付的其他资料、物品原件。</p>
    <p>
      d)&nbsp;&nbsp;&nbsp;&nbsp; 甲方公司将在受理退货申请及退还上述资料后
      &nbsp;15&nbsp;
      &nbsp;日内，将应退款项支付至乙方指定账户，若乙方指定账户并非本人账户，则需乙方提供书面的同意书。
    </p>
    <p><strong>第五条 乙方使用规则</strong></p>
    <p>
      1、乙方在购买甲方培训课程时，必须向甲方提供真实、准确、完整及最新的个人资料，如个人资料有任何变动，必须及时更新。
    </p>
    <p>
      2
      、乙方对甲方按本协议提供的培训课程承担保密义务，未经甲方书面许可，不得向任何第三方透露。
    </p>
    <p>
      3、
      乙方应妥善保管甲方的登录帐号和密码，对以其乙方帐号发生的所有活动和事件负法律责任。乙方不得将其帐号、密码转让或借予他人使用。如乙方发现其帐号遭他人非法使用，应立即通知甲方。因黑客行为或乙方的保管疏忽导致帐号、密码遭他人非法使用，甲方不承担任何责任。
    </p>
    <p>
      4、
      乙方不得非法倒卖甲方提供的培训课程，如违反约定，乙方应赔偿甲方因此遭受的一切损失，甲方保留追究乙方法律责任的权利。
    </p>
    <p>
      5、
      乙方不得全权委托甲方及甲方员工对乙方账户进行任何操作，由此引起的一切后果由乙方自负。
    </p>
    <p>
      6
      、乙方在使用甲方培训课程过程中，须遵守中国现行法律、行政法规和其他规范性文件及所有与甲方培训课程有关的网络协议、规定和程序。乙方不得利用甲方提供的培训课程发表、散布、传播任何反动、色情及违反国家安全、扰乱社会秩序等有害信息，若乙方在甲方网站散布和传播上述信息，甲方的系统记录将会作为乙方违反法律的证据。若因此给甲方造成损失的，乙方应当承担赔偿责任。
    </p>
    <p>
      7、甲方有权对乙方使用甲方培训课程的情况进行审查和监督，如乙方在使用网络课程时违反任何上述规定，甲方或其授权的人有权要求乙方改正或直接采取一切必要的措施（包括但不限于暂停或终止乙方使用培训课程的权利）以减轻乙方不当行为造成的影响。
    </p>
    <p>
      8、甲方针对某些特定的甲方培训课程的使用，通过各种方式（包括但不限于网页公告、电子邮件、短信提醒等）作出的任何声明、通知、警示等内容视为本协议的一部分，乙方如使用该等甲方培训课程，视为乙方同意该等声明、通知、警示的内容。
    </p>
    <p><strong>第六条 培训课程的变更、中断或终止</strong></p>
    <p>
      1、鉴于培训课程的特殊性，乙方同意甲方有权随时变更、中断或终止部分或全部的培训课程，但甲方应当在变更、中断或终止之前事先通知乙方，双方协商变更或解除本协议。
    </p>
    <p>
      2、乙方理解，甲方需要定期或不定期地对提供网络课程平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护，如因此类情况而造成收费的网络课程在合理时间内的中断，甲方无需为此承担任何责任，但甲方应尽可能事先进行通告。
    </p>
    <p>
      3、如发生下列任何一种情形，甲方有权随时中断或终止向乙方提供本协议项下的网络课程，乙方不得请求退费，甲方无需对乙方或任何第三方承担任何责任，同时有权要求乙方承担赔偿责任：
    </p>
    <p>
      &nbsp; &nbsp; &nbsp; &nbsp;a)&nbsp;&nbsp;&nbsp;&nbsp;
      违反本协议第五条乙方使用规则或《学员培训服务条款》的。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp; &nbsp;b)&nbsp;&nbsp;&nbsp;&nbsp;
      乙方在购买培训课程时未按规定向甲方支付相应的费用。
    </p>
    <p><strong>第七条 其他</strong></p>
    <p>
      1、甲方通过媒体所发布的宣传资料、广告等文件与本协议有不一致的，以本协议为准。
    </p>
    <p>2、 本协议签订后，如需修改，须经双方协商一致。</p>
    <p>
      3、
      就本协议未尽事宜，双方同意依本协议内部或网站上有关的公告、说明、服务条款补充，并依中华人民共和国法律与其它相关法令解释并适用之。如果有争议，应提交至原告所在地法院通过诉讼方式解决。
    </p>
    <p>
      4、
      本协议经乙方确认并同意后，将以电子文件方式留存于甲方系统中，以为双方协议签署及遵循的依据。
    </p>
    <p><strong>附件：【学员培训服务条款】</strong></p>
    <p><strong>第一条 接受条款</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （一）上海益学智迅科技发展有限公司（以下简称&ldquo;本公司&rdquo;）以益学堂金融实战培训系统为学员提供金融在线培训服务。学员已通过本公司官网对本公司提供的培训服务及方式有充分了解，并同意遵守《学员培训服务条款》。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （二）本公司官网的各项网络服务的所有权和运营权归本公司所有。本公司依照本服务条款及其不时发布的操作规则提供网络服务。学员了解并完全同意所有服务条款成注册程序，才能成为益学堂的正式学员。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （三）本公司官网内容以及本公司向学员提供金融在线培训服务，不涉及任何股票咨询、股票推荐、代客理财等行为。学员了解并同意本公司培训讲师在培训中使用股市实时行情及个股仅作为案例教学之用，不作为投资建议，所教授的仅是金融知识和股票投资技巧。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （四）本公司开展培训活动仅向客户收取培训费用，该等费用并不包含任何推荐个股、提供投资建议和咨询的费用。
    </p>
    <p><strong>第二条 注册义务</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （一）学员注册时必须保证提供的个人资料（包括真实姓名及各项相关资料）详实、正确、完整，若个人资料有变更，应及时更新。如果学员提供的个人资料不实，或未及时更新资料而有任何延误或损失时，应由学员自行负责，本公司亦可随时终止学员的学员资格及使用各项服务之权利。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （二）学员了解并同意对以其学员名进行的所有活动和事件，并承担全部责任。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （三）学员了解并同意接受本公司通过电子邮件或其他方式向学员发送宣传推广或者其他相关商业信息。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （三）学员了解并同意，本公司在不对外披露单个学员隐私资料的前提下有权对整个学员数据库进行分析并对用户数据库进行商业上的利用。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （四）学员了解并同意在本公司官网上发表意见需遵守《全国人大常委会关于加强网络信息保护的决定》。
    </p>
    <p><strong>第三条 账户安全</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （一）学员应妥善保管学员账号及密码，且不得将学员账号密码提供给第三人使用，或以任何方式提供第三人使用学员的服务，无论是有偿还是无偿。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （二）学员须自行负责对学员的注册账号和密码保密，且须对在该登录名和密码下发生的所有活动（包括但不限于信息披露、发布信息、网上点击同意或提交各类规则协议、网上续签协议或购买服务等）承担责任。学员了解并同意：
    </p>
    <p>
      &nbsp;
      &nbsp;1、如发现任何人未经授权使用学员的账号和密码，或发生违反保密规定的任何其他情况，学员会立即通知本公司；
    </p>
    <p>
      &nbsp;
      &nbsp;2、学员确保在每个上网时段结束时，以正确步骤离开网站/服务。本公司不能也不会对因学员未能遵守本款规定而发生的任何损失负责。学员理解本公司对学员的请求采取行动需要合理时间，本公司对在采取行动前已经产生的后果（包括但不限于学员的任何损失）不承担任何责任。
    </p>
    <p><strong>第四条 服务期限</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （一）学员成为本公司金融实战培训系统正式学员，所选定购买课程、课时数、及合同服务期限，详见学员与本公司签订的合同书。学员了解并同意学员使用权限账号须全数缴清款项后始开通。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （二）学员已了解并同意，本公司于开放培训时段内，学员可随时享有学习及作业批改服务。合同服务期满或虽未期满或终止但课时已使用完毕时，本公司对于学员的服务即视为终止，学员不得再有所主张或请求。
    </p>
    <p><strong>第五条 使用义务与责任</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （一）学员使用本公司相关服务前，须自行配备上网所需计算机软硬件设备，并负担连接互联网及电话等相关费用。使用网站服务应遵守中国及学员联机所在地相关法令及互联网的国际使用惯例与礼节，并不得侵害任何第三人的权益。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp; （二）
      为确保学员个人使用专属性，学员同意本公司于学员使用的网络系统上设置相关的保护措施，以确保学员账号的安全性。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （三）股票投资有风险，学员了解并同意自行承担股票投资风险，学员投资所产生的任何损失与本公司无关。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （四）下列行为为学员禁止行为，学员如有下列行为，本公司有权暂停或撤销其学员资格并不予退费，同时有权追究学员法律责任：
    </p>
    <p>&nbsp; &nbsp;1、为任何非法目的使用益学堂金融实战培训平台的；</p>
    <p>
      &nbsp;
      &nbsp;2、以任何形式使用益学堂金融实战培训平台侵犯本公司及关联公司的商业利益，包括并不限于发布非经本公司许可的商业广告的；
    </p>
    <p>
      &nbsp;
      &nbsp;3、利用益学堂金融实战培训平台及本公司官网进行任何可能对互联网或移动网正常运转造成不利影响的；
    </p>
    <p>
      &nbsp;
      &nbsp;4、利用益学堂金融实战培训平台及本公司官网上传、展示或传播任何虚假的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、庸俗淫秽的或其他任何非法的信息资料的；
    </p>
    <p>
      &nbsp;
      &nbsp;5、侵犯本公司或第三人的专利权、著作权、商标权、名誉权或其他任何合法权益的；
    </p>
    <p>&nbsp; &nbsp;6、干扰或混乱网络服务；</p>
    <p>&nbsp; &nbsp;7、其他侵犯本公司或第三人合法权益的行为。</p>
    <p>
      &nbsp; &nbsp;
      &nbsp;（五）学员了解并同意独立对自己在网上的行为承担法律责任。学员若在本公司官网上散布和传播反动、色情或其他违反国家法律的信息，本公司的系统记录有可能作为用户违反法律的证据。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （六）本公司不对学员所发布信息的删除或储存失败承担任何责任。公司有权判定学员的行为是否符合中国法律法规的规定以及本服务条款的要求，如果学员违背了中国法律法规的规定或服务条款的规定，本公司有中断对其提供服务的权利。对于学员通过本公司网络技术服务（包括但不限于论坛、BBS、新闻评论、博客、微博、微信、图片、网摘等频道）上传到本公司网上可公开获取区域的任何内容，学员同意授予本公司在全世界范围内永久性的、不可撤销的、免费的、非独家的和再许可的权利和许可，本公司可以使用、复制、修改、改编、出版、翻译、据以创作衍生作品、传播、表演和展示该等内容的全部或部分，或将此等内容的全部或部分编入其他任何形式的作品、媒体或技术中。
    </p>
    <p><strong>第六条 知识产权</strong></p>
    <p>
      &nbsp; &nbsp;
      （一）本公司官网定义的网络服务内容包括：本公司官网提供的视频、文字、软件、声音、图片、录象、图表、广告中的全部内容、电子邮件的全部内容以及和其他信息。所有这些内容受著作权、商标等其它财产所有权法律的保护。所以，学员只能在和本公司和相关权利人授权下才能使用这些内容，而不能擅自复制、发布、转载、播放、改编、汇编或以其他方式使用这些内容或将该等内容用于其他任何商业目的。
    </p>
    <p>
      &middot;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      （二）学员了解并同意本公司提供的培训服务内容涉及的视频、文字、软件、声音、图片、录像、图表、邮件及广告中的全部内容拥有完全知识产权。学员应遵守法律及尊重本公司知识产权，除非本公司授权或用于学员本人学习目的，学员不得有将本公司提供的培训服务内容进行复制、传播、发行、改编、翻译、买卖等行为。学员亦不得冒用本公司及本公司培训讲师的名义招摇赚骗。否则，本公司有权撤销学员的学员资格，并追究法律责任。
    </p>
    <p><strong>第七条 系统安装与维护</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;（一）本公司于合同有效期限内，应提供学员在线系统的使用安装及该系统维护服务，使学员得以正常使用在线学习系统。如系学员个人计算机软硬件或电信业者因特网系统等障碍因素，则不在本公司提供维护服务范围之内。学员亦不得以网络带宽、其它环境或学员个人因素，致使其学习质量不佳时，向本公司要求或主张任何权利。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （二）本公司有例行性或因故需将本网站相关系统设备进行迁移、更换或维护，而须暂停或中断全部的服务时，本公司将于72小时前在系统内或网站上，以张贴告示的方式通知学员。本公司如未依约定方式通知致影响学员权益时，学员可请求将合同服务日数延长二日。但本公司遇有临时或紧急或不可抗力事由，或于事后并未关闭服务主机者，不在此限。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （三）本公司对相关系统应负维护之责，学员如遇系统无法正常使用或有异动的情形，应迅速与客服人员联系。本公司提供服务项目包括下列：
    </p>
    <p>&nbsp; &nbsp;1、对于网站咨询服务系统的故障排除及维护更新；</p>
    <p>&nbsp; &nbsp;2、学员对于咨询服务系统的使用建议或申诉；</p>
    <p>&nbsp; &nbsp;3、学员个人资料申请、变更的协助。</p>
    <p>&nbsp; &nbsp; &nbsp; （四）其它与学员权益有关并依约定提供服务的事宜。</p>
    <p><strong>第八条 服务费用缴付</strong></p>
    <p>
      学员根据自身需要选择课程。学员于签订本合同时，应以现金或转账汇款、信用卡刷卡或第三方支付平台为主要支付方式，并将款项壹次付清至本公司官方账户。学员选择现金支付、汇款或由金融机构办理分期支付时，请先与客户服务专员联系，以便提供相关信息数据。本公司于学员付清款项及本合同签署完成后，始为学员正式启用其上课及咨询资格。
    </p>
    <p><strong>第九条 学员资格专属性</strong></p>
    <p>
      &nbsp; &nbsp;
      &nbsp;（一）学员了解并同意，本公司提供金融在线培训服务，不仅按每位学员学习能力、个人背景、兴趣及学习偏好与学习比重，将学员特性、需求做适合的配对，教学与解答后再辅以学习追踪系统；该具体且完整的追踪学习效果，并为个人专属的管理学习资源而不可替代。因此，本公司对每位学员的前置投资作业及专属性，并非可按时间及课时数量化其价值。因而学员购买本公司产品服务后，不能以个人因素任意解除、终止合同，亦不得将本服务有偿或无偿提供予他人使用，若有解约或终止学员资格事宜，应依合同约定及本学员服务使用条款第十条及其他相关规定办理。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;（二）
      学员了解并同意，学员在行使培训服务时，系以本公司研发的培训系统，采取计算机比对方式，将学员职业、兴趣及背景等输入，配比出合适的顾问提供指导，教学与解答过程中并由顾问对学员学习过程记录分析，以完整追踪学习成效。因此，个人学员资格不得全部或部分、有偿或无偿转让，学员亦不得将学员资格、可使用课时数的全部或部分以出售、公开竞标、拍卖、出借、有偿或无偿转让等方式予他人使用，若有影响本公司操作系统及其它学员权益的，将视为无效行为。如经发现，本公司可终止合同并停止提供学员服务，学员不得请求费用的退还或补偿，其与受让人之间的权利义务关系概与本公司无涉。
    </p>
    <p><strong>第十条 违约处理</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （一）学员有违反本学员培训服务条款第五条第四款各项情形之一者，不予以退费，同时本公司有权追究赔偿损失。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （二）学员了解并同意本公司所赠予学员的课程，须缴清款项、购买课程使用完毕且合同未到期时有效，若学员有提前解除或终止合同，或有重大违约事由被撤销学员资格者，不得请求给付赠与课程或以之折换价金。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （三）学员有违反本学员服务使用条款第三条第一款情形者，本公司有权暂时停止学员账户使用，且若经确认违反本约定者，本公司有权解除或终止本合同，学员并须同时赔偿本公司以服务费用为基准计算的十倍惩罚性违约金。
    </p>
    <p><strong>第十一条 学员续约权利</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;学员购买的服务期满或课时数使用完毕后，可采纳续购方式或另订新约方式继续行使学员权益。相关续约手续及办法，依本公司规定办理。本公司保留是否续约或另订新约的权利。
    </p>
    <p><strong>第十二条 影像文件的使用</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;学员同意其于上课、解答及见面会等活动期间相关的录像、录音及文字、图片文件等内容，无偿提供给本公司系列产品或服务的编辑与研发、学员教学观摩、业务推广、产品发表或销售的使用。
    </p>
    <p><strong>第十三条 禁止劝诱</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;本学员了解并同意，学员不得唆使、劝诱本公司（及关系企业）的员工或顾问与本公司（及关系企业）终止或解除服务关系，或由学员自己或介绍他人予以雇用（无论正式/约聘/正职/兼职）、聘为顾问（如管理/语言顾问等）、代理人、受任人、使用人或提供其他职务，无论是否为定期及有无薪酬。如有违反，学员应负担本公司为填补该职缺所支出的费用，并赔偿本公司的损害，及支付惩罚性违约金（以该离职员工/顾问离职前的2倍年薪计算）。
    </p>
    <p><strong>第十四条 免责条款</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （一）本公司郑重提醒学员注意密码的保密。任何使用学员密码进行的资金转账操作均视为学员个人行为；由于密码失密给学员造成的损失，学员自行承担全部经济和法律后果。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;（二）因地震、台风、水灾、火灾、战争、瘟疫、社会动乱及其他不可抗力因素导致本公司金融实战培训系统无法为学员提供正常培训服务的，本公司不承担任何赔偿责任。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;（三）因本公司不可预测或无法控制的系统故障、设备故障、通讯故障、电力故障等突发事故及其他非乙方人为因素、监管机关或行业协会规定的其他免责情形，给学员造成的损失，乙方如无过错则不承担任何赔偿责任。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;（四）为了网站的正常运行，本公司定期或不定期对提供网络服务的平台（如互联网网站、移动网络等）或相关的设备进行检修或者维护而造成网络服务的中断，本公司将尽力避免服务中断或将中断时间限制在最短时间内，在合理时间内的服务中断，本公司无需为此承担任何责任。如发生下列任何一种情形，本公司有权随时中断或终止向学员提供网络服务（包括收费网络服务）而无需对学员或任何第三方承担任何责任：
    </p>
    <p>&nbsp; &nbsp;（1） 学员提供的资料不真实；</p>
    <p>&nbsp; &nbsp;（2） 学员违反本服务条款的有关规定；</p>
    <p>
      &nbsp; &nbsp;（3）
      学员在使用收费网络服务时未按规定向本公司支付相应的服务费。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （五）本公司官网内设有通往其他网站和网页的链接，但这些网站和网页并非由本公司经营或控制，本公司不承担责任。学员启动任何此类链接或网页，离开本公司官网进入其他网站或网页，所有风险自负，本公司不承担一切责任和债务。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （六）股市有风险，入市须谨慎。学员了解并同意自行承担股票投资的风险，学员投资股票的任何损失与本公司无涉。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      （七）因风险揭示书已经提示，经学员阅读知晓并承诺自行承担相关风险，本公司不承担任何赔偿责任。
    </p>
    <p><strong>第十五条 条款效力</strong></p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;学员同意遵守本公司的相关规则，包括现行实施或相关的公告。学员同意本公司关于本学员服务使用条款内容及相关信息为本公司单方不定期修订信息内容，并于公告后正式生效（敬请随时查询本公司官网公告）。若学员于本学员服务使用条款内容或相关信息修订后仍继续使用服务，则视为其同意该等内容信息的修订。
    </p>
    <p>&nbsp;</p>
    <h4 align="center">软件服务协议</h4>
    <p align="center"><strong>风险提示</strong></p>
    <p>
      <strong>1</strong
      ><strong
        >、用户应了解证券投资顾问业务的内涵和基本规则，趋势密码操盘软件所提供的所有数据与信息仅供用户参考，用户应自主做出投资决策并自行承担投资风险。</strong
      >
    </p>
    <p>
      <strong>2</strong
      ><strong
        >、证券市场具有较强的风险，趋势密码操盘软件不能保证用户盈利，也不承诺用户的投资不遭受损失。</strong
      >
    </p>
    <p>&nbsp;</p>
    <p align="center"><strong>《趋势密码操盘软件》终端用户使用协议</strong></p>
    <p align="center"><strong>（2017年第一版）</strong></p>
    <p align="center">&nbsp;</p>
    <p>
      本《&lt;趋势密码操盘软件&gt;终端用户使用协议》（以下简称&ldquo;本协议&rdquo;）是您与《趋势密码操盘软件》（以下简称&ldquo;本软件&rdquo;）权利人上海益学智迅科技发展有限公司（以下简称&ldquo;本公司&rdquo;）签订的，关于使用本软件，通过本软件接受本软件资讯信息,具有法律效力的协议。<strong>请您在使用本软件前仔细阅读和理解本协议，本协议不仅适用于本软件的授权使用，亦适用于本软件资讯信息的提供、本软件的后期升级。如果您对本协议条款有异议，您可以选择不使用本软件，在确保产品及组件的完整性的前提下，在本软件规定的退货期内按照本协议及相关规定办理退货手续。</strong>但您一旦使用本软件（包括但不限于安装、下载、复制或以其他方式使用），即表示您完全接受本协议，并同意接受本协议条款的约束。
    </p>
    <p>&nbsp;</p>
    <p><strong>第一条&nbsp; 本协议有关词语的定义</strong></p>
    <p>
      1.1证券投资顾问服务：按照中国证券监督管理委员会颁布的《证券投资顾问业务暂行规定》第二条规定，证券投资顾问业务是指证券投资咨询业务的一种基本形式，指证券公司、证券投资咨询机构接受客户委托，按照约定，向客户提供涉及证券及证券相关产品的投资建议服务，辅助客户做出投资决策，并直接或者间接获取经济利益的经营活动。投资建议服务内容包括投资的品种选择、投资组合以及理财规划建议等。
    </p>
    <p>
      1.2本软件：是上海益学智迅科技发展有限公司研发的软件产品平台，本平台可能兼容一款或多款享有独立著作权的趋势密码终端软件（以下简称&ldquo;被兼容软件&rdquo;），该种情形下，用户可在本软件界面中直接浏览和使用被兼容软件的功能，在本软件中展示的被兼容软件功能与本兼容软件独立客户端在排版、展示方式等方面可能存在一定差异，但该等差异不会影响软件信息内容。本软件在本协议中根据上下文义可理解为《趋势密码操盘软件》，或理解为《趋势密码操盘软件》和被兼容软件整体。
    </p>
    <p>
      1.3本软件资讯信息：您通过本软件从趋势密码操盘软件的服务器上接受，并通过本软件显示的与证券相关的资讯信息，具体提供的资讯信息以本软件实际提供内容为准。
    </p>
    <p>
      1.4用户名、密码：是指您购买本软件后，趋势密码操盘软件向您提供的本软件用户名、密码。您可使用上述用户名、密码登陆本软件，并使本软件与趋势密码操盘软件的服务器相连接，接受趋势密码操盘软件提供的服务。
    </p>
    <p>
      1.5链接：您使用用户名、密码使本软件与趋势密码操盘软件服务器相连接，接收趋势密码操盘软件提供的软件服务的行为。
    </p>
    <p>&nbsp;</p>
    <p><strong>第二条&nbsp; 协议签署</strong></p>
    <p>
      本协议以电子合同方式签署，您应在仔细阅读、充分理解并完全接受本协议的全部条款及各类规则后，应勾选位于页面下方的"同意"或与同意具有同等含义的选项，之后，本协议即产生法律效力。
    </p>
    <p>&nbsp;</p>
    <p><strong>第三条 &nbsp;用户个人资料</strong></p>
    <p>
      3.1您保证在本协议项下提交的用户信息的真实性、正确性及完整性，且上述资料发生变化，您应及时通过电话、或用户在线注册信息变更等方式进行更改。<strong
        >因您提供用户信息不实、不准确、不完整或您未及时更改用户信息而引起的一切后果由您本人承担。</strong
      >
    </p>
    <p>
      3.2趋势密码操盘软件将妥善保管您的用户资料，不得公开或向第三方透露您的注册信息，除非经司法机关、行政机关要求而透露。
    </p>
    <p>&nbsp;</p>
    <p><strong>第四条&nbsp; 软件产品许可使用</strong></p>
    <p>
      4.1在您遵守本协议条款的前提下，趋势密码操盘软件将授予您本软件的非专有的使用权，您可以、且仅可以在一台电脑上使用本软件，通过本软件与趋势密码操盘软件的服务器相链接，接受趋势密码操盘软件提供的本软件资讯信息及其它本软件服务。
    </p>
    <p>
      <strong>4.2</strong
      ><strong
        >您应对您的用户名、密码应进行妥善保管，在任何情况下，趋势密码操盘软件均无核查用户名真实合法性的义务，任何以您的用户名所做出的行为将被视为您的行为，您需对此承担全部法律责任。</strong
      >
    </p>
    <p>&nbsp;</p>
    <p><strong>第五条&nbsp; 软件服务</strong></p>
    <p>5.1本软件资讯信息内容</p>
    <p>
      5.1.1趋势密码操盘软件只在交易日通过本软件向您传送本软件资讯信息。本软件资讯信息属于增值信息，任何情况下均不替代证券交易所的即时行情。
    </p>
    <p>
      <strong>5.1.2</strong
      ><strong
        >您在购买本软件和/或被兼容软件的同时，须同时购买该软件首期信息服务，首期服务期限、服务种类及费用以您购买时趋势密码操盘软件相关规定为准。</strong
      >
    </p>
    <p>5.2本软件资讯信息接受方式</p>
    <p>
      5.2.1本软件须与趋势密码操盘软件的服务器相链接才能接收、下载符合格式要求的即时股市行情、相关数据，之后，作为客户端的本软件将在上述数据基础上进行运算，生成分析结果，但此过程不排除部分资讯信息是直接传输至本软件（客户端）的可能。
    </p>
    <p>
      5.2.2若您在服务期届满后选择不再续缴信息服务费的，则自服务期届满之日起您将不再享受趋势密码操盘软件提供的资讯信息服务，您的软件也将无法再与服务器相链接，并无法继续接收、下载任何上文所述符合趋势密码操盘软件格式要求的即时股市行情、相关数据，本软件也因为无法取得上述股市行情或相关数据而无法进行相应的数据运算和处理并获得的相应功能。但在服务期满前已经接受的行情数据、生成的分析结果，还是可以为您提供历史参考作用。上述规定不排除趋势密码操盘软件在服务期届满后，免费许可您继续使用部分基础资讯信息或服务，上述免费许可的内容以趋势密码操盘软件规定为准，且趋势密码操盘软件有权随时调整许可内容、或终止许可。
    </p>
    <p>5.2.3趋势密码操盘软件保留变更信息数据传送方式、内容的权利。</p>
    <p>5.3其他服务</p>
    <p>
      5.3.1趋势密码操盘软件将向您提供关于本软件的维护及支持服务，许可您使用本软件的任何补丁，在原版本上的升级。
    </p>
    <p>
      5.3.2<strong
        ><u
          >趋势密码操盘软件提供给您的本软件的任何修改、升级版本，应视为本软件的一部分，受到本协议条款的制约（除非本协议被替换、修改、升级版本的另外一份协议取代）。</u
        ></strong
      >
    </p>
    <p>
      5.3.3<strong
        ><u
          >趋势密码操盘软件保留在提供修改、升级版本时，终止您先前使用版本的使用权的权利。</u
        ></strong
      >
    </p>
    <p>&nbsp;</p>
    <p><strong>第六条 &nbsp;费用、服务期</strong></p>
    <p>6.1软件费用及服务费用</p>
    <p>6.1.1按照本平台上运营且您购买使用的软件定价/市场推广价执行；</p>
    <p>
      6.1.2趋势密码操盘软件有权根据营销计划制定优惠政策，在全国/部分城市执行。
    </p>
    <p>6.2&nbsp;每期服务期（包括但不限于首期服务期）届满后，自动终止。</p>
    <p>
      <strong><u>6.3</u></strong
      ><strong
        ><u
          >趋势密码操盘软件保留调整本软件、及本软件信息服务收费标准、办法的权利。</u
        ></strong
      >
    </p>
    <p>6.4趋势密码操盘软件一律以公司账户收取本软件费用及信息服务费。</p>
    <p>
      <strong><u>6.5</u></strong
      ><strong
        ><u
          >您同意，趋势密码操盘软件有权将本协议全部或部分权利义务转让给第三方，但转让方有责任保证该等转让将不会对您使用本软件、或享受本软件服务产生影响。信息服务商发生变化，您可在信息服务到期后，直接向新的服务商支付信息服务费用，并享受其提供的服务。</u
        ></strong
      >
    </p>
    <p>&nbsp;</p>
    <p>
      <strong><u>第七条</u></strong
      >&nbsp;&nbsp;<strong><u>您保证</u></strong>
    </p>
    <p>
      <strong><u>7.1</u></strong
      ><strong
        ><u
          >您承诺以终端用户的身份使用本软件接收并使用本软件资讯信息。</u
        ></strong
      >
    </p>
    <p>
      7.2&nbsp;<strong
        ><u
          >趋势密码操盘软件提供的或您获得的有关本软件的任何信息只能由您本人为本协议许可的目的而使用，不得从事下述行为：</u
        ></strong
      >
    </p>
    <p>
      <strong><u>7.2.1</u></strong
      ><strong
        ><u
          >不在本协议规定的条款之外，使用、复制、修改、租赁、出借或转让本软件或其中的任一部分；</u
        ></strong
      >
    </p>
    <p>
      <strong><u>7.2.2</u></strong
      ><strong
        ><u
          >不以任何方式向任何机构或个人提供全部或部分本软件资讯信息；</u
        ></strong
      >
    </p>
    <p>
      <strong><u>7.2.3</u></strong
      ><strong
        ><u
          >保证不将全部或部分本软件资讯信息用于开发衍生产品，或用于其它商业目的</u
        ></strong
      >
    </p>
    <p>
      <strong><u>7.2.4</u></strong
      ><strong
        ><u
          >保证不对本软件进行破解，不对本软件进行反向工程、反编译、反汇编或以其他方式尝试发现本软件的源代码或绕过该软件中的任何技术限制。</u
        ></strong
      >
    </p>
    <p>
      <strong><u>7.2.5</u></strong
      ><strong><u>不以违反法律的任何方式使用该软件。</u></strong>
    </p>
    <p>
      <strong><u>7.3&nbsp;</u></strong
      ><strong
        ><u
          >不侵犯趋势密码操盘软件及其子公司、关联方的任何合法权益，包括但不限于著作权、商标权及其他合法权益。</u
        ></strong
      >
    </p>
    <p>
      <strong><u>您理解</u></strong>
    </p>
    <p>
      <strong><u>7.4 &nbsp;&nbsp;</u></strong
      ><strong
        ><u
          >本公司已经明确告知您本公司的联系方式；您发现营销或者服务人员通过其他方式联系时，可以向本公司反映、举报，也可以向中国证监会及其派出机构投诉、举报。</u
        ></strong
      >
    </p>
    <p>
      <strong><u>7.5 &nbsp;&nbsp;</u></strong
      ><strong
        ><u
          >本公司已经明确告知您本公司及执业人员的证券投资咨询业务资格及查询方式。</u
        ></strong
      >
    </p>
    <p>
      <strong><u>7.6 &nbsp;&nbsp;</u></strong
      ><strong><u>本公司已经明确告知您趋势密码软件的作用及局限性。</u></strong>
    </p>
    <p>&nbsp;</p>
    <p><strong>第八条 证券投资顾问的职责和禁止行为</strong></p>
    <p>
      8.1趋势密码操盘软件仅从事&ldquo;趋势密码&rdquo;品牌证券软件的销售、服务业务，趋势密码操盘软件仅通过软件终终端提供证券信息服务，不提供任何形式的人工或&ldquo;一对一&rdquo;投资建议服务，您可以登录中国证券业协会官网查询证券投资顾问人员信息。
    </p>
    <p>8.2趋势密码操盘软件、证券投资顾问人员、其他员工不得从事下述行为：</p>
    <p>8.2.1不得代客户做出投资决策，或代理买卖证券；</p>
    <p>
      8.2.2&nbsp;不得索要客户证券账户、资金账户和相应密码，亦不得接受客户委托管理上述账户或密码；
    </p>
    <p>8.2.3不得与客户约定投资收益分成或分担投资损失；</p>
    <p>8.2.4不得超过趋势密码操盘软件提示数据提供证券投资建议；</p>
    <p>8.2.5不得私下、或以个人名义向投资和提供证券投资建议；</p>
    <p>8.2.6不得以任何方式承诺或变相承诺投资收益；</p>
    <p>8.2.7不得为买卖股票及具有股票性质、功能的证券；</p>
    <p>8.2.8不得利用咨询服务与他人合谋操纵市场或者进行内幕交易；</p>
    <p>8.2.9不得从事法律、法规、中国证监会禁止的行为。</p>
    <p>&nbsp;</p>
    <p><strong>第九条 &nbsp;知识产权保护</strong></p>
    <p>
      9.1本软件（包括但不限于本软件的结构、组织、代码及软件中所含的图像、照片、动画、录像、录音、音乐、文字和附加程序）、附随的印刷材料、及本软件任何复制品、副本的著作权，均由本公司拥有。
    </p>
    <p>
      9.2&nbsp;本协议对您使用本软件的授权并不意味着本软件著作权人对其享有的知识产权也进行了转让或进行独家授权使用。
    </p>
    <p>
      9.3&nbsp;虽然本软件可以兼容本兼容软件，但取得本软件许可或一款被兼容软件许可，不代表您取得其他被兼容软件许可，您通过本软件可以使用的被兼容软件，具体以您实际购买的本兼容软件为准。
    </p>
    <p>
      9.4&nbsp;为了防止复制品的损坏而制作备份复制品。这些备份复制品不得通过任何方式提供给他人使用，并在您丧失本软件的所有权时，负责将备份复制品销毁。
    </p>
    <p>
      9.5&nbsp;本协议项下的本软件资讯信息所有权归本软件著作权人、或本软件信息服务商、或相关证券交易所（如上海证券交易所、深圳证券交易所、中国金融期货交易所、香港联合交易所有限公司等）、或相关证券交易所授权经营证券信息的有关公司所有。<strong
        ><u
          >您在任何情况下均不得对本软件资讯信息进行永久储存或使用（包括但不限于复制、翻译、传播、编辑、转让、许可他人使用和开发衍生产品等。）</u
        ></strong
      >
    </p>
    <p>&nbsp;</p>
    <p><strong>第十条&nbsp; 售后服务</strong></p>
    <p>
      10.1<strong
        ><u
          >你同意：趋势密码操盘软件在提供本软件服务过程中可以各种方式发布趋势密码操盘软件各种产品信息，您同意趋势密码操盘软件通过电子邮件、手机短信或其他方式向您发送产品信息。</u
        ></strong
      >
    </p>
    <p>10.2退货期：</p>
    <p>
      10.2.1自趋势密码操盘软件向您交付您所购买的本软件及首期信息服务之日起，通用退货期为：1）7日内，若您对本软件不满意，可无理由书面通知趋势密码操盘软件解除协议，办理退货手续；2）超出7天，除出现法律规定情形外，趋势密码操盘软件以月为单位进行退货，不足一个月的按一个月计算。如您购买的趋势密码操盘软件退货政策与本协议约定不一致的，则执行该趋势密码操盘软件的特殊退货政策。
    </p>
    <p>
      10.2.2您在退货时，应同时返还下述资料，否则趋势密码操盘软件有权拒绝办理退货手续：10.2.2.1所有发票和/或收据原件；
    </p>
    <p>
      10.2.2.2趋势密码操盘软件赠送给您的所有赠品；如赠品已拆封或损坏或开始使用，您应按赠品市场价值向趋势密码操盘软件支付补偿款，趋势密码操盘软件将在向您退还的款项中直接扣除该部分款项，此种情形下赠品将交付您本人继续使用。
    </p>
    <p>10.2.2.3趋势密码操盘软件赠送或交付的其他资料、物品原件。</p>
    <p>
      10.2.3趋势密码操盘软件将在受理您的退货申请及您退还的上述资料后15日内，将应退款项支付至您指定账户，若您指定账户并非您本人账户，则您需提供书面的同意书。
    </p>
    <p>&nbsp;</p>
    <p><strong>第十一条&nbsp; 用户信息的保护及披露</strong></p>
    <p>
      11.1<strong>您在使用本软件时，趋势密码操盘软件或趋势密码操盘软件授权的第三方插件会自动接收并记录您使用本软件的相关数据，包括但不限于IP地址、网站cookie、网卡地址、手机设备号等信息。</strong>趋势密码操盘软件收集和储存您的信息的主要目的在于提高为用户提供服务的效率和质量。同时，趋势密码操盘软件会积极采取技术手段和有效的管理机制来保障您的账户安全。
    </p>
    <p>
      <strong><u>11.2</u></strong
      ><strong
        ><u
          >您同意趋势密码操盘软件在业务运营中使用您的信息，授予趋势密码操盘软件永久的、免费的及完整的许可使用权利(并且有权对该权利进行再授权)，使趋势密码操盘软件有权使用用户的数据参与制作其派生产品，例如但不限于市场调研、消费分析等，以已知或日后开发的任何形式的媒体、技术或纳入其他作品里。</u
        ></strong
      >
    </p>
    <p>
      <strong><u>11.3</u></strong
      ><strong
        ><u
          >您同意因使用需要，趋势密码操盘软件及相关服务软件、应用程序将会记录您的设备号。该记录仅用于设备识别及趋势密码操盘软件消息推送，不会用于其它任何用途。</u
        ></strong
      >
    </p>
    <p>11.4&nbsp;隐私权</p>
    <p>
      11.4.1趋势密码操盘软件对于您提供的、趋势密码操盘软件自行收集的个人信息将按照本协议予以保护、使用或者披露。趋势密码操盘软件无需您同意即可向趋势密码操盘软件所关联实体转让与趋势密码操盘软件有关的部分或全部权利和义务。
    </p>
    <p>
      11.4.2趋势密码操盘软件按照您在本软件上的行为自动追踪关于您的某些资料。在不透露您的隐私资料的前提下，趋势密码操盘软件有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。
    </p>
    <p>
      11.4.3您同意，趋势密码操盘软件可在本软件或趋势密码操盘软件的网站上使用诸如&ldquo;Cookies&rdquo;的资料收集装置。
    </p>
    <p>
      11.4.4&nbsp;虽有第11.4.1条之约定，但在下列情况下，趋势密码操盘软件有权全部或部分披露您的保密信息：
    </p>
    <p>
      11.4.4.1根据法律规定，或应行政机关、司法机关要求，向第三人或行政机关、司法机关披露；
    </p>
    <p>
      11.4.4.2提供独立服务且仅要求服务相关的必要信息的供应商，如印刷厂、邮递公司等；
    </p>
    <p>11.4.4.3提供给趋势密码操盘软件的关联实体；</p>
    <p>
      11.4.4.4根据法律、本协议约定及趋势密码操盘软件规定，趋势密码操盘软件因提供服务需要披露或其他趋势密码操盘软件认为适合披露的。
    </p>
    <p>&nbsp;</p>
    <p><strong>第十二条&nbsp; 免责条款</strong></p>
    <p>
      <strong
        ><u
          >本软件能为您提供证券交易相关的资讯信息，但您充分理解该信息不能完全等同于证券交易所的即时行情，且鉴于网络传输具有一定的不稳定性，趋势密码操盘软件对于以下情形免责：</u
        ></strong
      >
    </p>
    <p>
      <strong><u>12.1</u></strong
      ><strong
        ><u
          >本软件资讯信息属于辅助决策信息，投资决策必须由您自主作出，由此产生的投资风险由您独立承担。</u
        ></strong
      >
    </p>
    <p>
      <strong><u>12.2</u></strong
      ><strong
        ><u
          >趋势密码操盘软件通过本软件向您提供的本软件资讯信息在任何情况下均不替代证券交易所的即时行情作为交易服务信息，对于您参考本软件资讯信息作出的任何投资行为所可能引致的风险，趋势密码操盘软件免责。</u
        ></strong
      >
    </p>
    <p>
      <strong><u>12.3</u></strong
      ><strong
        ><u
          >趋势密码操盘软件不对因您自身原因（包括但不限于电脑病毒、错误操作、网络接连错误等）导致本软件资讯信息无法正常接收的后果承担责任。</u
        ></strong
      >
    </p>
    <p>
      <strong><u>12.4</u></strong
      ><strong
        ><u
          >趋势密码操盘软件将不对所提供信息的完整性、及时性、准确性承担任何责任。</u
        ></strong
      >
    </p>
    <p>
      <strong><u>12.5</u></strong
      ><strong
        ><u
          >由于政府禁令、政府管制、现行生效的适用法律或法规的变更、火灾、地震、动乱、战争、停电、通讯线路中断、他人蓄意破坏、黑客攻击、计算机病毒侵入或发作、电信部门技术调整之影响等不可预见、不可避免、不可克服和不可控制的事件，均属于不可抗力事件。由此造成您或任何第三人出现或蒙受任何损失或损害，趋势密码操盘软件不承担责任。但趋势密码操盘软件应当在不可抗力事件发生后或终止后，立即采取必要的措施，尽可能的降低或消除已经发生的损失或损害。</u
        ></strong
      >
    </p>
    <p>
      <strong><u>12.6</u></strong
      ><strong
        ><u
          >趋势密码操盘软件不对因任何非趋势密码操盘软件过错原因（如因证券交易所、卫星传输线路、电信部门及网络服务提供商等任何原因）造成的本软件资讯信息异常或本软件资讯信息传递异常情况所致后果承担责任，但有义务在能力范围内积极协助相关企业、单位、部门使之恢复正常。</u
        ></strong
      >
    </p>
    <p>
      <strong><u>12.7</u></strong
      ><strong
        ><u
          >因可归责于趋势密码操盘软件的原因造成本软件的信息异常或信息传递中断等异常情况，趋势密码操盘软件将尽力予以弥补，并于收到您的通知后按照中断、异常时间（不足1小时的按1小时计算）相应延长服务期，但您确认，趋势密码操盘软件不对因任何原因造成的信息异常或信息传递异常情况所致后果（包括但不限于您的各种投资决策、交易行为所引致的后果）承担责任。</u
        ></strong
      >
    </p>
    <p>&nbsp;</p>
    <p><strong>第十三条 &nbsp;公司联系方式</strong></p>
    <p>
      13.1公司联系电话：<br />客服热线：400-673-6018<br />投诉热线：021-61960608
    </p>
    <p>13.3邮箱：yx@yxcps.com</p>
    <p>
      13.4公司联系地址：上海市徐汇区古美路1515号19号楼1802室（邮编：200233）
    </p>
    <p>&nbsp;</p>
    <p><strong>第十四条&nbsp; 其他</strong></p>
    <p>
      <strong
        ><u
          >如您违反本协议所述任何条款，则趋势密码操盘软件有权随时终止向您提供本软件项下的服务，且趋势密码操盘软件无须向您承担任何补偿或赔偿；如因您违反本协议造成趋势密码操盘软件损失的，则趋势密码操盘软件有权要求您承担相应的赔偿责任。</u
        ></strong
      >
    </p>
    <p>&nbsp;</p>
    <p><strong>第十五条 &nbsp;争议解决及适用法律</strong></p>
    <p>
      <strong
        ><u
          >本协议受中华人民共和国法律管辖，如您与趋势密码操盘软件就本软件的相关问题发生争议，首先应友好协商，协商不能取得一致，您与趋势密码操盘软件均有权向上海市徐汇区人民法院提起诉讼。</u
        ></strong
      >
    </p>
    <p>&nbsp;</p>
    <h4 align="center">投顾业务风险揭示书</h4>
    <p>&nbsp;</p>
    <p>
      &nbsp;为了使您更好的了解证券投资顾问的基础知识和相关风险，我公司依据《证券投资顾问业务暂行规定》面向投资者提供《证券投资顾问业务风险揭示书》，以便充分揭示证券投资顾问服务中所存在的风险或潜在风险。请您在正式使用我公司提供的产品或服务前，仔细阅读。
    </p>
    <p align="center">&nbsp;</p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;一、您在接受证券投资顾问服务前，必须了解提供证券投资顾问服务的证券公司、证券投资咨询机构是否具备证券投资咨询业务资格，证券公司、证券投资咨询机构提供服务的人员是否具备证券投资咨询执业资格并已经注册登记为证券投资顾问。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;二、您在接受证券投资顾问服务前，必须理解证券投资顾问业务的含义，理解投资者接受证券投资顾问服务后需自主作出投资决策并独立承担投资风险。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;三、您在接受证券投资顾问服务前，必须了解证券公司、证券投资咨询机构及其人员提供证证券投资顾问服务不能确保投资者获得盈利或本金不受损失。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;四、您在接受证券投资顾问服务前，必须了解证券公司、证券投资咨询机构及其人员提供的投资建议具有针对性和时效性，不能在任何市场环境下长期有效。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;五、您在接受证券投资顾问服务前，必须了解作为投资建议依据的证券研究报告和投资分析意见等，可能存在不准确、不全面或者被误读的风险，投资者可以向证券投资顾问了解证券研究报告的发布人和发布时间以及投资分析意见的来源，以便在进行投资决策时作出理性判断。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;六、您在接受证券投资顾问服务前，必须了解所在的证券公司、证券投资咨询机构证券投资顾问服务的收费标准和方式，按照公平、合理、自愿的原则与证券公司、证券投资咨询机构协商并书面约定收取证券投资顾问服务费用的安排。证券投资顾问服务收费应向公司账户支付，不得向证券投资顾问人员或其他个人账户支付。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;七、您在接受证券投资顾问服务前，必须了解证券公司、证券投资咨询机构及其人员可能存在道德风险，如投资者发现投资顾问存在违法违规行为或利益冲突情形，如泄露客户投资决策计划、传播虚假信息、进行关联交易等，投资者可以向证券公司、证券投资咨询机构投诉或向有关部门举报。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;八、您在接受证券投资顾问服务前，必须了解证券公司、证券投资咨询机构存在因停业、解散、撤销、破产，或者被中国证监会撤销相关业务许可、责令停业整顿等原因导致不能履行职责的风险。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;九、您在接受证券投资顾问服务前，必须了解证券公司、证券投资咨询机构的投资顾问人员存在因离职、离岗等原因导致更换投资顾问服务人员并影响服务连续性的风险。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;十、您在接受证券投资顾问服务前，应向证券公司、证券投资咨询机构说明自身资产与收入状况、投资经验、投资需求和风险偏好等情况并接受评估，以便于证券公司、证券投资咨询机构根据投资者的风险承受能力和服务需求，向投资者提供适当的证券投资顾问服务。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;十一、您在接受证券投资顾问服务前，应向证券公司、证券投资咨询机构提供有效的联系方式和服务获取方式，如有变动须及时向所在的证券公司、证券投资咨询机构进行说明，如因投资者自身原因或不可抗力因素导致投资者未能及时获取证券投资顾问服务，责任将由投资者自行承担。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;十二、您在接受证券投资顾问服务时，应保管好自己的证券账户、资金账户和相应的密码，不要委托证券投资顾问人员管理自己的证券账户、资金账户，代理买卖证券，否则由此导致的风险将由投资者自行承担。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;十三、在您接受以软件工具、微信、终端设备等为载体的投资建议或者类似功能服务前，请务必仔细阅读相关说明书，了解其实际功能、信息来源、固有缺陷和使用风险。由于您自身原因导致该软件工具、微信、终端设备等使用不当或受到病毒入侵、黑客攻击等不良影响的，由此导致的风险将由您自行承担。我公司的软件工具、微信、终端设备等载体具有选择证券投资品种和提示买卖时机功能的，相关说明书中揭示了其功能原理和使用方法，并指出了其局限性和使用风险，请您认真阅读了解。
    </p>
    <p>
      &nbsp; &nbsp; &nbsp;
      &nbsp;十四、我公司证券投资顾问或其他人员违反《证券投资顾问服务协议》及本《证券投资顾问业务风险揭示书》之约定，擅自所做的任何承诺一律无效，由此导致的不利后果由您自行承担。市场上，时有第三方假冒我公司名义拟与投资者建立投资顾问服务关系并收取各类费用，其行为构成诈骗，请您予以辨别，避免上当受骗，因此而遭受的损失，由您自行承担。
    </p>
    <p>&nbsp;</p>
    <p>
      <strong
        >本风险揭示书的揭示事项仅为列举性质，未能详尽列明投资者接受证券投资顾问服务所面临的全部风险和可能导致投资者投资损失的所有因素。</strong
      >
    </p>
    <p>
      <strong
        >投资者在接受证券投资顾问服务前，应认真阅读并理解相关业务规则、证券投资顾问服务协议及本风险揭示书的全部内容。</strong
      >
    </p>
    <p>
      <strong
        >接受证券投资顾问服务的投资者，自行承担投资风险，证券公司、证券投资咨询机构不以任何方式向投资者作出不受损失或者取得最低收益的承诺。</strong
      >
    </p>
    <p>
      <strong
        >特别提示：投资者应签署本风险揭示书，表明投资者已经理解并愿意自行承担接受证券投资顾问服务的风险和损失。</strong
      >
    </p>
    <p>&nbsp;</p>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style lang="less" scoped>
.protocol_wrap {
  padding: 0 2%;

  p {
    margin: 20px 0;
    text-align: justify;
  }
}
</style>