<template>
  <div class="about_main">
    <el-tabs v-model="activeName" @tab-change="tabChange" @tab-click="tabClick">
      <el-tab-pane :name="item.id" v-for="(item, index) in tabs" :key="index">
        <template v-slot:label>
          <div class="head">
            <img :src="item.icon" />
            {{ item.name }}
          </div>
        </template>

        <component :is="item.comName" :ref="'view' + item.id"></component>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import gsjs_ from "@/views/about_us/about/gsjs/index.vue"; // 公司介绍
import qyzz_ from "@/views/about_us/about/qyzz/index.vue"; // 企业资质
import yxdsj_ from "@/views/about_us/about/yxdsj/index.vue"; // 益学大事记
import gsln_ from "@/views/about_us/about/gsln/index.vue"; // 公司理念
import hggl_ from "@/views/about_us/about/hggl/index.vue"; // 合规管理
import cyrygs_ from "@/views/about_us/about/cyrygs/index.vue"; // 从业人员公示

export default {
  data() {
    return {
      activeName: Number(this.$route.query.subTabId) || 1,
      tabs: [
        {
          id: 1,
          name: "公司介绍",
          icon: require("@/assets/about_us/about/icon1.png"),
          comName: "gsjs_",
        },
        {
          id: 2,
          name: "企业资质",
          icon: require("@/assets/about_us/about/icon2.png"),
          comName: "qyzz_",
        },
        {
          id: 3,
          name: "益学大事记",
          icon: require("@/assets/about_us/about/icon3.png"),
          comName: "yxdsj_",
        },
        {
          id: 4,
          name: "公司理念",
          icon: require("@/assets/about_us/about/icon4.png"),
          comName: "gsln_",
        },
        {
          id: 5,
          name: "合规管理",
          icon: require("@/assets/about_us/about/icon5.png"),
          comName: "hggl_",
        },
        {
          id: 6,
          name: "从业人员公示",
          icon: require("@/assets/about_us/about/icon6.png"),
          comName: "cyrygs_",
        },
      ],
    };
  },
  components: {
    gsjs_,
    qyzz_,
    yxdsj_,
    gsln_,
    hggl_,
    cyrygs_,
  },
  created() {},
  mounted() {
    this.$nextTick(() => {
      this.isVideoPlay(true);
    });
  },
  methods: {
    // 	tabs tab 被选中时触发
    tabClick(tab) {
      // 当点击标签页时，则暂停 原标签下 视频
      this.isVideoPlay(false);
    },
    // tabs activeName 改变时触发
    tabChange(name) {
      // console.log(name);

      // this.isVideoPlay(true);

      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth", // 平滑滚动
      // });

      this.$router.push({
        path: "/aboutUs",
        query: {
          tabId: this.$route.query.tabId || 0,
          subTabId: name,
        },
      });
    },

    // 是否播放
    isVideoPlay(isPlay) {
      // let Ovideo = this.$refs["view" + this.activeName][0].$refs.myVideo;

      // if (Ovideo) {
      //   if (isPlay) {
      //     Ovideo.currentTime = 0;
      //     Ovideo.play();

      //     return;
      //   }

      //   Ovideo.pause();
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.about_main {
  /deep/ .el-tabs {
    --el-tabs-header-height: 50px;

    .el-tabs__header {
      margin: 0;
      position: sticky;
      top: 60px;
      z-index: 10;

      .el-tabs__nav-wrap {
        .el-tabs__nav-scroll {
          .el-tabs__nav {
            width: 100%;
            background: #1a1a1a;

            .el-tabs__active-bar {
              height: 2px;
              bottom: 4px;
              background: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%);
            }

            .el-tabs__item {
              color: #fff;
              font-weight: 400;

              &.is-active {
                font-weight: 600;
              }

              &:nth-child(2) {
                padding-left: 20px;
              }

              .head {
                font-size: 14px;
                display: flex;
                align-items: center;

                img {
                  width: 14px;
                  height: 14px;
                  margin-right: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
