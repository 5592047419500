import axios from 'axios'
import qs from 'qs'
import common from './common'
import { hex_md5, getParamsStr } from "./valide_k";

axios.interceptors.request.use(config => {
    // loading
    return config
}, error => {
    return Promise.reject(error)
});

axios.interceptors.response.use(response => {
    return response
}, error => {
    return Promise.resolve(error.response)
});

function checkStatus(response) {
    // loading
    // 如果http状态码正常，则直接返回数据
    if (response && (response.status === 200 || response.status === 304 || response.status === 400)) {

        return response
        // 如果不需要除了data之外的数据，可以直接 return response.data
    }

    // 异常状态下，把错误信息返回去
    return response
}

function WWWYXCPSGet(url, data) {
    return axios({
        method: 'get',
        baseURL: common.YX_WWWYXCPS_API_URL,
        url,
        params: data,
        headers: {
            // "Content-Type": "application/x-www-form-urlencoded",
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function SZGet(url, data) {
    return axios({
        method: 'get',
        baseURL: common.YX_SZ_API_URL,
        url,
        params: data,
        headers: {}
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function SZPost(url, data) {
    return axios({
        method: 'post',
        baseURL: common.YX_SZ_API_URL,
        url,
        data: qs.stringify(data),
        headers: {}
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function CLOUDPost1(url, data) {
    return axios({
        method: 'post',
        baseURL: common.YX_CLOUD_API_URL,
        url,
        data: data,
        headers: {
            "Content-Type": "application/json",
            "appId": "180666",
            "isJsonPostFlag": "ture",
            "sign": _getCommonParam2(data)
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function CLOUDPost(url, data) {
    _getCommonParam(data)

    return axios({
        method: 'post',
        baseURL: common.YX_CLOUD_API_URL,
        url,
        data: qs.stringify(data),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function CLOUDGet(url, data) {
    _getCommonParam(data)

    return axios({
        method: 'get',
        baseURL: common.YX_CLOUD_API_URL,
        url,
        params: data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function quanPost(url, data) {
    _getCommonParam(data)

    return axios({
        method: 'post',
        baseURL: common.YX_QUAN_API_URL,
        url,
        data: qs.stringify(data),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function quanGet(url, data) {
    _getCommonParam(data)

    return axios({
        method: 'get',
        baseURL: common.YX_QUAN_API_URL,
        url,
        params: data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function stockGet(url, data, type) {
    if (type == 1) {
        _getCommonParam1(data)
    } else {
        _getCommonParam(data)
    }

    // console.log(data);
    return axios({
        method: 'get',
        baseURL: common.YX_STOCK_API_URL,
        url,
        params: data,
        headers: {
            // "Content-Type": "application/x-www-form-urlencoded",
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function stockPost(url, data) {
    _getCommonParam(data)

    return axios({
        method: 'post',
        baseURL: common.YX_STOCK_API_URL,
        url,
        data: qs.stringify(data),
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function CMSpost(url, data) {
    return axios({
        method: 'post',
        baseURL: common.YX_CMS_API_URL,
        url,
        data: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json",
            'sign': 'yixue'
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function CMSget(url, data) {
    return axios({
        method: 'get',
        baseURL: common.YX_CMS_API_URL,
        url,
        params: data, // get 请求时带的参数
        headers: {
            "Content-Type": "application/json",
            'sign': 'yixue'
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}

function CMSGetFrom(url, data) {
    return axios({
        method: 'get',
        baseURL: common.YX_CMS_API_URL,
        url,
        params: data,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'sign': 'yixue'
        }
    }).then(
        (response) => {
            return checkStatus(response)
        }
    )
}


// 公共参数
function _getCommonParam(data) {
    data['appId'] = 180666;

    data["sign"] = hex_md5(
        getParamsStr(data, "5af113ade1b6426ab4194d50caf7ac1d")
    ).toUpperCase();

    return data;
}
function _getCommonParam1(data) {
    let appInfo = window.appInfo;
    if (!appInfo) {
        return;
    }
    data['clientType'] = appInfo.clientType || '4';
    data['sellsId'] = appInfo.sellsId;
    data['limitSellsId'] = appInfo.sellsId;
    data['dataType'] = appInfo.dataType || 10;
    // data['deviceNo'] = appInfo.deviceNo;
    // data['version'] = appInfo.version;
    // data['packageName'] = appInfo.packageName;
    data['userNo'] = appInfo.userNo;
    data['appId'] = 180666;

    data["sign"] = hex_md5(
        getParamsStr(data, "5af113ade1b6426ab4194d50caf7ac1d")
    ).toUpperCase();

    return data;
}
function _getCommonParam2(data) {
    data = JSON.stringify(data) + '5af113ade1b6426ab4194d50caf7ac1d'

    let sign = hex_md5(data).toUpperCase();

    return sign;
}


export default {
    WWWYXCPSGet,
    CLOUDPost1,
    CLOUDPost,
    CLOUDGet,
    quanPost,
    quanGet,
    SZGet,
    SZPost,
    stockGet,
    stockPost,
    CMSpost,
    CMSget,
    CMSGetFrom,
}