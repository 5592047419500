<template>
  <div class="gsln_wrap">
    <div class="head_box">益学堂——<br />赋予投资者以智慧之剑</div>

    <div class="gsln_container">
      <div class="text">
        “‘益’字的上半部分象征着向上承接，下半部分则象征着向下播撒，它代表着从天而降的智慧和对大地的恩泽。”上海益学堂的创始人刘荣桃这样解释“益”字的含义，“‘益’与英文‘E’谐音，与我们的在线教育(E-Learning)理念不谋而合。”
      </div>

      <div class="text">
        作为在线金融教育的先锋，益学堂自成立之初就确立了宏伟的目标——成为中国投资者最信赖的在线金融实战学习平台。“益”不仅是我们事业的起点，更是我们追求的终极目标：帮助股民有效提升投资技能，最大限度地规避股市风险，享受投资的乐趣，轻松实现财富增长。
      </div>

      <div class="text">
        教育（educate）一词源自拉丁文，意为‘引出’或‘导出’，即通过教育手段激发个体内在潜能。“益学堂致力于为投资者提供学习工具和模拟实践的机会。凭借在证券行业二十多年的实战经验，深知投资者的需求和挑战。我们以实战模拟和多维度的跟踪服务，帮助学员避免走弯路，快速成长。”
      </div>

      <div class="text">
        在益学堂，我们相信学习是永无止境的，教学相长。益学堂不仅是一个教育平台，也是一个学习型组织。从员工到管理层，每个人都在不断学习和进步。
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.gsln_wrap {
  min-height: calc(100vh - 109px);
  background: url("@/assets/about_us/about/gsln/bg.png") no-repeat;
  background-size: cover;
  color: #fff;
  padding: 130px 4%;
  box-sizing: border-box;

  .head_box {
    font-size: 30px;
    line-height: 48px;
  }

  .gsln_container {
    margin-top: 120px;

    .text {
      font-size: 14px;
      text-align: justify;
      margin-top: 40px;
      line-height: 24px;
    }
  }
}

@media (min-width: 1921px) {
  .gsln_wrap {
    padding: 180px 4%;

    .gsln_container {
      margin-top: 200px;
    }
  }
}
</style>
