<template>
  <div class="grzl_wrap">
    <div class="title">个人资料</div>

    <div class="container_box">
      <div class="container">
        <div class="title_box">个人资料</div>

        <div class="form_box">
          <el-form :model="form" label-width="auto">
            <el-form-item label="用户ID：">
              <el-input v-model="form.userNo" disabled />
            </el-form-item>

            <el-form-item label="昵称：">
              <el-input v-model="form.nickname" placeholder="请输入昵称" />
            </el-form-item>

            <el-form-item label="性别：">
              <el-select v-model="form.sex" placeholder="请选择性别">
                <el-option label="男" :value="1" />
                <el-option label="女" :value="2" />
              </el-select>
            </el-form-item>

            <el-form-item label="生日：">
              <el-date-picker
                v-model="form.birthday"
                type="date"
                placeholder="请选择生日"
              />
            </el-form-item>

            <el-form-item label="姓名：">
              <el-input v-model="form.fullName" placeholder="请输入姓名" />
            </el-form-item>

            <el-form-item label="邮箱：">
              <el-input v-model="form.email" placeholder="请输入邮箱" />
            </el-form-item>

            <el-button type="primary" @click="onSubmit">保存</el-button>
          </el-form>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="tipsShow"
      title="提示"
      width="500"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :align-center="true"
      :before-close="tipsClose"
    >
      <span>个人信息修改成功</span>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="tipsClose()">确认</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      isLogin: localStorage.getItem("yx_isLogin") || false,
      userInfo: JSON.parse(localStorage.getItem("yx_userInfo")) || null,

      form: {
        userNo: "",
        nickname: "",
        sex: "",
        birthday: "",
        fullName: "",
        email: "",
      },

      tipsShow: false, // 成功 - 提示
    };
  },
  created() {
    this.getUserInfo(); // 根据 yx号 获取 用户信息
  },
  mounted() {},
  methods: {
    // 根据 yx号 获取 用户信息
    getUserInfo() {
      let data = {
        userNo: this.userInfo.userNo,
      };

      this.API.CLOUDGet("account/api/user/security/userInfodetail", data).then(
        (res) => {
          // console.log(res);
          let datas = res.data.data;
          // console.log(datas);

          this.form.userNo = "YX" + this.userInfo.userNo;
          this.form.nickname = datas.nickname;
          this.form.sex = datas.sex;
          this.form.birthday = datas.birthday;
          this.form.fullName = datas.fullName;
          this.form.email = datas.email;
        }
      );
    },

    // 保存 变更 用户信息
    onSubmit() {
      let data = {
        userNo: this.userInfo.userNo,
        nickname: encodeURIComponent(this.form.nickname),
        sex: this.form.sex,
        birthday: this.COMMON.getTimes(this.form.birthday, 2),
        fullName: encodeURIComponent(this.form.fullName),
        email: encodeURIComponent(this.form.email),
      };

      this.API.CLOUDGet("account/api/user/security/updateUserInfo", data).then(
        (res) => {
          // console.log(res);

          if (res.data.code == 200) {
            this.userInfo.userName = this.form.nickname;
            localStorage.setItem("yx_userInfo", JSON.stringify(this.userInfo));

            // ElMessage({
            //   message: "个人信息修改成功",
            //   type: "success",
            // });
            // location.reload();

            this.tipsShow = true;

            return;
          }

          ElMessage.error(res.data.msg);
        }
      );
    },

    // 提示 关闭
    tipsClose() {
      this.tipsShow = false;

      location.reload();
    },
  },
};
</script>

<style lang="less" scoped>
.grzl_wrap {
  min-height: calc(100vh - 60px);
  background: #eff1f3;

  .title {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: bold;
    background: #fff;
    padding-left: 28px;
  }

  .container_box {
    min-height: calc(100vh - 110px);
    padding: 16px;
    box-sizing: border-box;

    .container {
      min-height: calc(100vh - 142px);
      padding: 30px 20px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;

      .title_box {
        font-size: 16px;
        font-weight: bold;
      }

      .form_box {
        margin-top: 30px;

        /deep/ .el-form {
          .el-form-item {
            margin-bottom: 24px;

            .el-form-item__label-wrap {
              .el-form-item__label {
                font-weight: bold;
                height: 42px;
                line-height: 42px;
              }
            }

            .el-form-item__content {
              .el-input {
                .el-input__wrapper {
                  .el-input__inner {
                    height: 40px;
                  }
                }
              }

              .el-select {
                .el-select__wrapper {
                  height: 42px;
                }
              }

              .el-date-editor {
                height: 42px;
              }
            }
          }

          .el-button {
            width: 90px;
            margin-top: 36px;
            font-size: 16px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            border: none;
            background: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%);
            letter-spacing: 2px;
          }
        }
      }
    }
  }
}
</style>