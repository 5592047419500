<template>
  <div class="hggl_wrap">
    <div class="rule_box">
      <div class="text_box">
        <div class="text" v-for="(item, index) in hgList" :key="index">
          <img
            :src="
              require('@/assets/about_us/about/hggl/' + (index + 1) + '.png')
            "
          />
          {{ item }}
        </div>
      </div>

      <img src="@/assets/about_us/about/hggl/bg1_1.png" class="hg" />
    </div>

    <div class="hggl_box">
      <div
        class="hggl_list"
        :class="{ hggl_list_active: hgglActive == index }"
        v-for="(item, index) in hgglList"
        :key="index"
        @mouseenter="mouseChange(index)"
      >
        <div class="title">{{ item.title }}</div>

        <div class="content">
          <div class="text_box" v-show="hgglActive != index">
            <div class="sub_title">{{ item.subTitle }}</div>

            <div class="btn">
              <span>查看</span>&#8594
            </div>
          </div>

          <img class="info" :src="item.flow" v-show="hgglActive == index" />
        </div>
      </div>
    </div>

    <div class="system_box">
      <div class="title">一流投教团队，金牌课程体系，为您搭建专属知识体系</div>

      <div class="hggl_tab_box">
        <el-tabs class="hggl_tab" v-model="activeName">
          <el-tab-pane
            :name="item.id"
            v-for="(item, index) in tabs"
            :key="index"
          >
            <template v-slot:label>
              <div class="head">
                <img :src="item.icon" />
                {{ item.name }}
              </div>
            </template>

            <div class="content">
              <div
                class="info_list"
                v-for="(info, index) in item.list"
                :key="index"
              >
                <div class="name">{{ info.title }}</div>

                <div class="text">{{ info.text }}</div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <div class="hg_box">
      <div class="hg_bg">
        <img src="@/assets/about_us/about/hggl/bg4_1.png" class="hg_bg_item" />

        <div class="hg_list hg_left hg_list1">
          <div class="title">企业资质</div>

          <div class="text_box">
            <div class="text">中国十佳商业模式创新</div>
            <div class="text">深圳证券交易所专有信息经营许可证</div>
            <div class="text">上海证券交易所上证所LEVEL-1行情经营许可证</div>
            <div class="text">信用中国2015年度投资者教育行业领军人物</div>
            <div class="text">高新技术企业证书 上海市软件行业协会</div>
          </div>
        </div>

        <div class="hg_list hg_left hg_list2">
          <div class="title">诚信宣言</div>

          <div class="text_box">
            <div class="text">合规是安全、管理之本，违规是风险之源</div>
            <div class="text">
              秉持合规经营、诚信发展之道，严守国家法律法规，坚决执行证监会和公司制定的各项规章制度，自觉遵守和维护行业自律公约
            </div>
          </div>
        </div>

        <div class="hg_list hg_list3">
          <div class="title">五大雷区</div>

          <div class="text_box">
            <div class="text">严禁虚假、误导性宣传</div>
            <div class="text">严禁向客户承诺投资收益</div>
            <div class="text">严禁盗取、泄露、恶意修改客户信息</div>
            <div class="text">严禁代客户理财或约定投资收益分成</div>
            <div class="text">
              严禁无证人员以个人名义向客户推荐个股或相关分析、建议等
            </div>
          </div>
        </div>

        <div class="hg_list hg_list4">
          <div class="title">合规语录</div>

          <div class="text_box">
            <div class="text">合规创造价值 坚守风险底线</div>
            <div class="text">秉持合规经营 严守行业自律</div>
            <div class="text">崇尚客户为本 深研专业服务</div>
            <div class="text">形成合规文化 树立品牌形象</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hgList: [
        "严禁代客理财",
        "严禁与客户约定投资收益分成",
        "严禁无证券投资顾问资质人员以个人名义向客户推荐个股或提供证券投资建议或对证券市场、品种的走势，投资的可行性提供任何形式的分析、预测和建议",
        "严禁以个人名义向客户收取咨询费用",
        "严禁盗取、泄露、恶意修改客户信息",
        "严禁虚假宣传:严禁对公司、团队以及个人服务能力和过往业绩进行虚假、不实、误导性的营销宣传",
        "严禁在任何场合下，辱骂或不尊重客户",
        "严禁向客户承诺投资收益或保证风险",
        "严禁代客户签署协议、文书，代客户收取产品费用及其他损害客户合法权限的事情",
        "严禁代客户登陆账号，获悉密码并使用",
      ],

      hgglActive: 0,
      hgglList: [
        {
          title: "智能风控系统",
          subTitle: "智能风控系统",
          flow: require("@/assets/about_us/about/hggl/flow1.png"),
        },
        {
          title: "智能管理系统",
          subTitle: "智能管理系统",
          flow: require("@/assets/about_us/about/hggl/flow2.png"),
        },
        {
          title: "购课流程风控环节",
          subTitle: "购课流程风控环节",
          flow: require("@/assets/about_us/about/hggl/flow3.png"),
        },
        {
          title: "退款流程风控环节",
          subTitle: "退款流程风控环节",
          flow: require("@/assets/about_us/about/hggl/flow4.png"),
        },
        {
          title: "投诉流程风控环节",
          subTitle: "投诉流程风控环节",
          flow: require("@/assets/about_us/about/hggl/flow5.png"),
        },
      ],

      activeName: 1,
      tabs: [
        {
          id: 1,
          name: "精细化教学体系",
          icon: require("@/assets/about_us/about/hggl/icon1.png"),
          list: [
            {
              title: "汇集核心教育资源",
              text: "数十位导师专业问答 数万条业内专业资讯",
            },
            {
              title: "定制化系统课程",
              text: "系统、全面的线上课程 十大体系全方位学习",
            },
            {
              title: "智能化学习平台",
              text: "数据定位知识短板 智能推送 打造专属学习内容",
            },
            {
              title: "多角度全方位教学",
              text: "直播+回放 理论+案例 专业导师1对1答疑",
            },
          ],
        },
        {
          id: 2,
          name: "专业化导师团队",
          icon: require("@/assets/about_us/about/hggl/icon2.png"),
          list: [
            {
              title: "资深讲师团队",
              text: "强大的讲师队伍，从业多年，具备丰富教学经验",
            },
            {
              title: "专业服务团队",
              text: "全员持证上岗，信息可查",
            },
            {
              title: "智能研发团队",
              text: "反应迅速的研发团队，用前沿技术提供强大的软件支持",
            },
            {
              title: "安全风控团队",
              text: "智能质检+人工复检，全力保障服务安全",
            },
          ],
        },
        {
          id: 3,
          name: "人性化售后服务",
          icon: require("@/assets/about_us/about/hggl/icon3.png"),
          list: [
            {
              title: "强大的售后团队",
              text: "配备专门的服务人员1v1服务，确保用户遇到的问题第一时间解决",
            },
            {
              title: "完美的服务支持",
              text: "产品手册、视频教程、常见问题手册一应俱全",
            },
            {
              title: "响应迅速的服务",
              text: "微信、QQ、电话等方式随时在线",
            },
            {
              title: "全程的服务追踪",
              text: "持续跟进用户反馈，满足多方位需求",
            },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 移动 切换
    mouseChange(index) {
      setTimeout(() => {
        this.hgglActive = index;
      }, 200);
    },
  },
};
</script>

<style lang="less" scoped>
.hggl_wrap {
  .rule_box {
    min-height: calc(100vh - 109px);
    background: url("@/assets/about_us/about/qyzz/bg.png") no-repeat;
    background-size: cover;
    color: #fff;
    font-size: 16px;
    padding: 130px 4%;
    box-sizing: border-box;
    position: relative;

    .text_box {
      .text {
        margin-top: 24px;
        display: flex;

        img {
          width: 22px;
          height: 22px;
          margin-right: 16px;
        }
      }
    }

    .hg {
      width: 32%;
      display: block;
      position: absolute;
      bottom: 12%;
      right: 4%;
    }
  }

  .hggl_box {
    padding: 8% 2%;
    display: flex;
    // align-items: center;
    justify-content: space-between;

    .hggl_list {
      width: 17%;
      padding: 48px 32px;
      box-sizing: border-box;
      border-radius: 20px;
      background: url("@/assets/about_us/about/hggl/flow_bg.png") no-repeat;
      background-size: 100% 100%;
      cursor: pointer;
      transition: background 0.8s ease-in-out;

      .title {
        font-weight: bold;
        font-size: 24px;
      }

      .content {
        margin-top: 16px;

        .text_box {
          font-size: 14px;

          .sub_title {
          }

          .btn {
            margin-top: 72%;
            display: inline-block;
            padding: 8px 20px;
            border-radius: 10px;
            border: 1px solid #5dacff;

            span {
              padding-right: 8px;
            }
          }
        }

        .info {
          width: 100%;
        }
      }

      &.hggl_list_active {
        width: 28%;
        padding: 48px 64px;
        color: #fff;
        background: url("@/assets/about_us/about/hggl/flow_bg_active.png")
          no-repeat;
        background-size: 100% 100%;
        cursor: default;

        .content {
          margin-top: 32px;
        }
      }
    }
  }

  .system_box {
    min-height: calc(100vh - 109px);
    background: url("@/assets/about_us/about/hggl/bg3.png") no-repeat;
    background-size: cover;
    padding: 6% 10%;
    box-sizing: border-box;

    .title {
      font-size: 24px;
      color: #5dacff;
      text-align: center;
    }

    .hggl_tab_box {
      margin-top: 80px;

      /deep/ .hggl_tab {
        --el-tabs-header-height: auto;

        .el-tabs__header {
          margin: 0;
          position: static;

          .el-tabs__nav-wrap {
            &::after {
              height: 1px;
              background: #2f2f33;
            }

            .el-tabs__nav-scroll {
              .el-tabs__nav {
                width: 100%;
                box-sizing: border-box;
                background: transparent;
                display: flex;
                justify-content: space-between;

                .el-tabs__active-bar {
                  height: 2px;
                  bottom: 1px;
                  background: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%);
                }

                .el-tabs__item {
                  width: 33%;
                  box-sizing: border-box;
                  padding: 0;
                  color: #fff;
                  font-weight: 400;

                  &.is-active {
                    font-weight: 600;
                  }

                  &:nth-child(2) {
                    padding-left: 0;
                  }

                  .head {
                    font-size: 14px;
                    display: flex;
                    flex-direction: column;
                    padding: 16px 0;

                    img {
                      width: 45px;
                      height: 45px;
                      margin-right: 0;
                      margin-bottom: 16px;
                    }
                  }
                }
              }
            }
          }
        }

        .content {
          color: #fff;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-top: 36px;

          .info_list {
            width: 48.8%;
            margin-top: 36px;
            padding: 50px 40px;
            box-sizing: border-box;
            background: url("@/assets/about_us/about/hggl/bg3_1.png") no-repeat;
            background-size: 100% 100%;

            &:nth-child(1) {
              border-radius: 5px 50px 5px 5px;
            }
            &:nth-child(2) {
              border-radius: 50px 5px 5px 5px;
            }
            &:nth-child(3) {
              border-radius: 5px 5px 50px 5px;
            }
            &:nth-child(4) {
              border-radius: 5px 5px 5px 50px;
            }

            .name {
              font-size: 16px;
              font-weight: bold;
            }

            .text {
              margin-top: 24px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  .hg_box {
    background: url("@/assets/about_us/about/hggl/bg4.png") no-repeat #eef2fd;
    background-size: 100%;
    padding: 7% 0 15%;

    .hg_bg {
      width: 500px;
      margin: 0 auto;
      position: relative;

      .hg_bg_item {
        width: 100%;
        display: block;
      }

      .hg_list {
        width: 350px;
        position: absolute;

        .title {
          font-size: 20px;
          font-weight: bold;
        }

        .text_box {
          margin-top: 12px;
          font-size: 14px;

          .text {
            margin-top: 6px;
          }
        }

        &.hg_list1 {
          text-align: right;
          top: 22%;
          left: -73%;
        }

        &.hg_list2 {
          text-align: right;
          top: 87.5%;
          left: -60%;
        }

        &.hg_list3 {
          top: 22%;
          right: -73%;
        }

        &.hg_list4 {
          top: 87.5%;
          right: -63%;
        }
      }
    }
  }
}

@media (min-width: 1921px) {
  .hggl_wrap {
    .rule_box {
      .text_box {
        .text {
          margin-top: 36px;
        }
      }
    }

    .hggl_box {
      .hggl_list {
        .title {
          font-size: 30px;
        }

        .content {
          .text_box {
            .btn {
              margin-top: 78%;
            }
          }
        }
      }
    }
  }
}
</style>
