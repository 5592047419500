<template>
  <div class="smrz_wrap">
    <div class="title">实名认证</div>

    <div class="container_box">
      <div class="container">
        <div class="title_box">实名认证</div>

        <div class="form_box">
          <el-form
            :model="form"
            ref="formRef"
            label-width="auto"
            :rules="rules"
          >
            <el-form-item label="姓名：" prop="userName">
              <el-input v-model="form.userName" disabled />
            </el-form-item>

            <el-form-item label="证件号：" prop="userIdNum">
              <el-input v-model="form.userIdNum" disabled />
            </el-form-item>

            <el-form-item label="身份证上传：" prop="idCard" v-if="isAuth">
              <div class="id_card_upload">
                <div class="uploader_box">
                  <el-upload
                    class="uploader"
                    ref="front_upload"
                    :class="{ uploader_hide: frontHide }"
                    action="#"
                    list-type="picture-card"
                    :limit="1"
                    :auto-upload="false"
                    :on-change="handleIdCardFrontChange"
                    :on-remove="handleFrontRemove"
                    :on-preview="handlePreview"
                  >
                    <img src="@/assets/home/smrz/front.png" class="id_img_bg" />
                  </el-upload>

                  <div class="upload_text">点击上传<span>（人像面）</span></div>
                </div>

                <div class="uploader_box">
                  <el-upload
                    class="uploader"
                    ref="back_upload"
                    :class="{ uploader_hide: backHide }"
                    action="#"
                    list-type="picture-card"
                    :limit="1"
                    :auto-upload="false"
                    :on-change="handleIdCardBackChange"
                    :on-remove="handleBackRemove"
                    :on-preview="handlePreview"
                  >
                    <img src="@/assets/home/smrz/back.png" class="id_img_bg" />
                  </el-upload>

                  <div class="upload_text">点击上传<span>（国徽面）</span></div>
                </div>
              </div>
            </el-form-item>

            <el-button type="primary" @click="onSubmit" v-if="isAuth"
              >提交</el-button
            >
          </el-form>
        </div>
      </div>
    </div>

    <el-dialog
      v-model="isAuthTips"
      title="提示"
      class="auth_tips"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
    >
      <div class="text">
        根据中华人民共和国国家互联网信息办公室发布的<a
          href="http://www.cac.gov.cn/2016-06/28/c_1119122192.htm"
          target="_blank"
          >《移动互联网应用程序信息服务管理规定》</a
        >，及证监会公告[2015]19号：<a
          href="http://www.csrc.gov.cn/pub/zjhpublic/g00306201/201507/t20150712_280907.htm"
          target="_blank"
          >《关于清理整顿违法从事证券业务活动的意见》</a
        >等相关文件要求，用户需使用有效身份证件进行实名认证。
      </div>

      <div class="text">
        您在认证时提交给本平台的认证资料，即不可撤销的授权由本平台保留。本平台承诺除法定或约定的事由外，不公开或编辑或透露您的认证资料及保存在本平台的非公开内容用于商业目的，但下列情况除外：
      </div>

      <div class="text">1）您授权本平台透露的相关信息；</div>

      <div class="text">2）本平台向国家司法及行政机关提供；</div>

      <div class="text">
        3）第三方和本平台一起为用户提供服务时，该第三方向您提供服务所需的相关信息。
      </div>

      <div class="text">
        我们将采取合理的方式以保护您个人资料的安全。我们将使用必要的可以获得的安全技术和程序来保护您的个人资料不被未经授权的访问、使用或泄漏。
      </div>
    </el-dialog>

    <el-dialog v-model="dialogVisible" title="预览" class="img_preview">
      <img :src="dialogImageUrl" />
    </el-dialog>

    

    <el-dialog
      v-model="tipsShow"
      title="提示"
      width="500"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :align-center="true"
      :before-close="tipsClose"
    >
      <span>保存成功</span>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" @click="tipsClose()">确认</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElMessage } from "element-plus";

export default {
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("yx_userInfo")) || null,

      isAuth: false, // 是否实名
      isAuthTips: false, // 实名提示
      tipsShow: false, // 成功 - 提示

      form: {
        userName: "",
        userIdNum: "",
        idCard: {
          front_img: "",
          frontList: {},
          back_img: "",
          backList: {},
        },
      },
      rules: {
        idCard: [
          {
            validator: (rule, value, callback) => {
              if (!value.front_img) {
                callback(new Error("请上传身份证正面照片"));
              } else if (!value.back_img) {
                callback(new Error("请上传身份证反面照片"));
              } else {
                callback();
              }
            },
            trigger: "change",
          },
        ],
      },

      frontHide: false, // 正面 上传框 是否显示
      backHide: false, // 反面 上传框 是否显示

      dialogVisible: false, // 预览
      dialogImageUrl: "", // 预览
    };
  },
  created() {
    this.getUserAuthInfo(); // 获取 是否 实名认证
  },
  mounted() {},
  methods: {
    // 获取 是否 实名认证
    getUserAuthInfo() {
      let data = {
        userNo: this.userInfo.userNo,
      };

      this.API.CLOUDGet("account/api/user/security/userAuthInfo", data).then(
        (res) => {
          // console.log(res);
          let datas = res.data.data;
          // console.log(datas);

          if (datas.isAuth == 1) {
            this.isAuth = false;
            this.form.userName = datas.fullName + "（已实名）";
            this.form.userIdNum = datas.idNo;
          } else {
            this.isAuth = true;
            this.isAuthTips = true;
          }
        }
      );
    },

    // 正面 change
    handleIdCardFrontChange(file, fileList) {
      this.frontHide = fileList.length >= 1;

      this.upLoadAndIdCard("FRONT", file); // 上传 图片并识别
    },
    // 反面 change
    handleIdCardBackChange(file, fileList) {
      this.backHide = fileList.length >= 1;

      this.upLoadAndIdCard("BACK", file); // 上传 图片并识别
    },

    // 正面 删除
    handleFrontRemove(file, fileList) {
      this.frontHide = fileList.length >= 1;

      this.form.userName = "";
      this.form.userIdNum = "";
    },
    // 反面 删除
    handleBackRemove(file, fileList) {
      this.backHide = fileList.length >= 1;
    },

    // 预览
    handlePreview(file) {
      this.dialogImageUrl = file.url;

      this.dialogVisible = true;
    },

    // 上传 图片并识别
    upLoadAndIdCard(tower, file) {
      let data = {
        userNo: this.userInfo.userNo,
        tower: tower,
        file: file,
        clientType: 1,
      };

      this.$axios
        .post("//quanapi.yxcps.cn/userFile/upLoadAndIdCard", data, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 1) {
            let datas = res.data.result;

            if (tower == "FRONT") {
              this.form.idCard.front_img = file.url;
              this.form.idCard.frontList = datas;

              this.form.userName = datas.idCard.name;
              this.form.userIdNum = datas.idCard.idNum;
            } else if (tower == "BACK") {
              this.form.idCard.back_img = file.url;
              this.form.idCard.backList = datas;
            }
          } else {
            if (tower == "FRONT") {
              this.frontHide = false;
              this.$refs.front_upload.clearFiles();
            } else if (tower == "BACK") {
              this.backHide = false;
              this.$refs.back_upload.clearFiles();
            }

            ElMessage.error(res.data.msg);
          }
        });
    },

    // 提交保存
    onSubmit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.saveUserAuth(); // 实名认证信息保存
        } else {
          console.log("表单验证失败");
          return false;
        }
      });
    },

    // 实名认证信息保存
    saveUserAuth() {
      let data = {
        userNation: encodeURIComponent(
          this.form.idCard.frontList.idCard.nation
        ),
        userSex: encodeURIComponent(this.form.idCard.frontList.idCard.sex),
        userBirth: this.form.idCard.frontList.idCard.birth,
        userName: encodeURIComponent(this.form.idCard.frontList.idCard.name),
        userAddres: encodeURIComponent(
          this.form.idCard.frontList.idCard.address
        ),
        coverFront: this.form.idCard.frontList.imageUrl,
        userIdNum: this.form.idCard.frontList.idCard.idNum,
        userAuthority: encodeURIComponent(
          this.form.idCard.backList.idCard.authority
        ),
        coverBack: this.form.idCard.backList.imageUrl,
        userValidDate: this.form.idCard.backList.idCard.validDate,

        userNo: this.userInfo.userNo,
        userAuthId: 3,
        clientType: 1,
        channel: "",
        supplierName: "",
        sourceId: 3,
        deviceNo: "",
        version: "",
        imei: "",
        packageName: "",
      };

      this.API.quanGet("userFile/saveUserAuth", data).then((res) => {
        console.log(res);
        if (res.data.status == 1) {
          // ElMessage({
          //   message: "保存成功",
          //   type: "success",
          // });
          // location.reload();

          this.tipsShow = true;
        } else {
          ElMessage.error(res.data.msg);
        }
      });
    },

    // 提示 关闭
    tipsClose() {
      this.tipsShow = false;

      location.reload();
    },
  },
};
</script>

<style lang="less" scoped>
/deep/.img_preview {
  img {
    width: 100%;
  }
}
/deep/ .auth_tips {
  background: #2a2a2c;
  border-radius: 10px;
  padding: 0;

  .el-dialog__header {
    padding: 14px 20px 10px;
    border-bottom: 1px solid #3e3e40;

    .el-dialog__title {
      color: #fff;
      font-size: 14px;
      font-weight: 600;
    }

    .el-dialog__headerbtn {
      .el-dialog__close {
        color: #fff;
      }

      &:hover {
        .el-dialog__close {
          color: #409eff;
        }
      }
    }
  }

  .el-dialog__body {
    padding: 16px 20px;

    .text {
      color: #fff;
      margin-top: 24px;
      line-height: 24px;
      text-align: justify;
      text-indent: 2em;

      a {
        text-decoration: none;
        color: #46a1ff;
      }

      &:first-child {
        margin-top: 0;
      }
    }
  }
}

.smrz_wrap {
  min-height: calc(100vh - 60px);
  background: #eff1f3;

  .title {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-weight: bold;
    background: #fff;
    padding-left: 28px;
  }

  .container_box {
    min-height: calc(100vh - 110px);
    padding: 16px;
    box-sizing: border-box;

    .container {
      min-height: calc(100vh - 142px);
      padding: 30px 20px;
      box-sizing: border-box;
      border-radius: 10px;
      background: #fff;

      .title_box {
        font-size: 16px;
        font-weight: bold;
      }

      .form_box {
        margin-top: 30px;

        /deep/ .el-form {
          .el-form-item {
            margin-bottom: 24px;

            .el-form-item__label-wrap {
              .el-form-item__label {
                font-weight: bold;
                height: 42px;
                line-height: 42px;
              }
            }

            .el-form-item__content {
              line-height: normal;

              .el-input {
                .el-input__wrapper {
                  .el-input__inner {
                    height: 40px;
                  }
                }
              }

              .id_card_upload {
                display: flex;

                .uploader_box {
                  margin-left: 50px;

                  &:first-child {
                    margin-left: 0;
                  }

                  .uploader {
                    .el-upload--picture-card {
                      width: 341px;
                      height: 224px;
                      padding: 16px;
                    }

                    &.uploader_hide {
                      .el-upload-list__item {
                        margin: 0;
                        width: 341px;
                        height: 224px;
                        padding: 16px;
                      }

                      .el-upload--picture-card {
                        display: none;
                      }
                    }

                    .id_img_bg {
                      width: 100%;
                    }
                  }

                  .upload_text {
                    margin-top: 10px;
                    text-align: center;

                    span {
                      color: #1285ff;
                    }
                  }
                }
              }
            }
          }

          .el-button {
            width: 90px;
            margin-top: 36px;
            font-size: 16px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            border: none;
            background: linear-gradient(92deg, #2cdbff 0%, #1285ff 100%);
            letter-spacing: 2px;
          }
        }
      }
    }
  }
}
</style>