<template>
  <div class="yx_study_wrap">
    <navbar_ :Lucency="false" :isBlack="true" :activeIndex="'/yxStudy'" />

    <div class="team_box">
      <img src="@/assets/yx_study/team_bg18_14.png" class="team_bg" />

      <div class="team_text">
        益学研究苑<br />
        <span>强大投研团队，陪伴教学</span>
      </div>
    </div>

    <div class="teacher_wrap">
      <div class="title_box">
        <div class="title">我们的团队</div>

        <div class="sub_title">深耕金融领域多年，拥有各自特色的操盘心得</div>
      </div>

      <div class="teacher_info_box">
        <div class="info_box">
          <img class="info_bg" src="@/assets/yx_study/bg1.png" />

          <div class="info_list">
            <div
              class="info_item_box"
              :class="{ info_item_box_active: activeIndex == index }"
              v-for="(item, index) in teacherList"
              :key="index"
            >
              <div class="info_item" v-if="activeIndex == index">
                <div class="intro_box">
                  <div class="name_box">
                    <div class="name">{{ item.name }}</div>
                    {{ item.title2 }}
                  </div>

                  <div class="intro1">{{ item.intro1 }}</div>

                  <div class="intro2">{{ item.intro2 }}</div>
                </div>

                <img class="teacher" :src="item.img2" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="teacher_list">
        <div
          class="teacher_item"
          v-for="(item, index) in teacherList"
          :key="index"
          @mouseenter="teacherClick(index)"
        >
          <img
            class="light1"
            :src="
              activeIndex == index
                ? require('@/assets/index/teacher/light_after_active.png')
                : require('@/assets/index/teacher/light_after.png')
            "
          />

          <img class="teacher" :src="item.img1" />

          <img
            class="light2"
            :src="
              activeIndex == index
                ? require('@/assets/index/teacher/light_before_active.png')
                : require('@/assets/index/teacher/light_before.png')
            "
          />

          <div class="name_box">
            <div class="name">{{ item.name }}</div>
            <div class="title">{{ item.title1 }}</div>
          </div>
        </div>
      </div>
    </div>

    <footer_ />
  </div>
</template>

<script>
import navbar_ from "@/components/navbar/index.vue"; // 顶部导航
import footer_ from "@/components/footer/index.vue"; // 底部

export default {
  data() {
    return {
      activeIndex: 2,
      teacherList: [
        {
          name: "邹海洋",
          title1: "益学资深导师",
          title2: "益学资深导师",
          intro1: "职业证书编号：A1220623080003",
          intro2:
            "深耕专业，持投顾基金期权等多重执照，拥有多家知名机构就职经验，擅长多种指标工具组合把控情绪节奏追踪热点，倾力加码挖掘机会，教你做更主流的交易！",
          img1: require("@/assets/index/teacher/zhy1.png"),
          img2: require("@/assets/index/teacher/zhy2.png"),
        },
        {
          name: "李正闯",
          title1: "益学资深导师",
          title2: "益学资深导师",
          intro1: "职业证书编号：A1220624080002",
          intro2:
            "行业经验21年，经历几轮市场牛熊，曾任职多家投资机构的投资顾问，受邀做过湖北卫视和浙江经广等媒体的节目嘉宾。独创“牛回头战法”、“首板战法”的选股逻辑以及量价关系的择时体系，熊市短逻辑，牛市抓波段，希望帮助更多投资者从稳健的投资思路中获取利益最大化。",
          img1: require("@/assets/index/teacher/lzc1.png"),
          img2: require("@/assets/index/teacher/lzc2.png"),
        },
        {
          name: "杨凯",
          title1: "益学资深导师",
          title2: "益学资深导师",
          intro1: "执业证书编号：A1220616030001",
          intro2:
            "超15年股海征战之旅的匠心导师，拥有上海交通大学&瑞士中央管理大学MBA双造诣背景，曾任中央人民广播电台《经济之声》以及第一财经等等主流官方平台特邀嘉宾，历经牛熊洞察敏锐深谙机构操盘体系，还精通期货等多维市场组合投资，开班带队多年出手快准稳，为你做看得懂的交易！",
          img1: require("@/assets/index/teacher/yk1.png"),
          img2: require("@/assets/index/teacher/yk2.png"),
        },
        {
          name: "曾海龙",
          title1: "益学资深导师",
          title2: "益学资深导师",
          intro1: "职业证书编号：A1220619030001",
          intro2:
            "前私募基金经理、职业投资人，上交所首批期权策略顾问，曾任上海第一财经等长期特邀嘉宾。擅长投资者心理情绪分析，立足企业价值成长和市场人性博弈，道与术的融合，突出机构视角技巧看盘识强弱，个股强弱辨机会，逻辑研判定买点，走势跟踪找卖点,行情大势锁仓位，人性心态稳情绪。",
          img1: require("@/assets/index/teacher/zhl1.png"),
          img2: require("@/assets/index/teacher/zhl2.png"),
        },
        {
          name: "张帅",
          title1: "益学资深导师",
          title2: "益学资深导师",
          intro1: "职业证书编号：A1220619040003",
          intro2:
            "创新研究宏观政策题材并深入微观动态跟踪，具有超强硬核逻辑与前沿眼光并且独创了六势操盘战法，第一财经未来理财师特邀评委导师是浙江等多个知名经济电台论坛特邀嘉宾，打法惊奇出其不意，让你做更科学的交易！",
          img1: require("@/assets/index/teacher/zs1.png"),
          img2: require("@/assets/index/teacher/zs2.png"),
        },
      ],
    };
  },
  components: {
    navbar_,
    footer_,
  },
  created() {},
  mounted() {},
  methods: {
    // 老师 click
    teacherClick(index) {
      setTimeout(() => {
        this.activeIndex = index;
      }, 300);
    },
  },
};
</script>

<style lang="less" scoped>
.yx_study_wrap {
  .team_box {
    position: relative;

    .team_bg {
      width: 100%;
      display: block;
    }

    .team_text {
      font-size: 40px;
      line-height: 60px;
      color: #fff;
      position: absolute;
      top: 50%;
      left: 10%;
      transform: translateY(-50%);

      span {
        font-size: 18px;
      }
    }
  }

  .teacher_wrap {
    background: url("@/assets/yx_study/bg.png") no-repeat #fff;
    background-size: 100%;

    .title_box {
      padding-top: 60px;
      padding-left: 10%;

      .title {
        display: inline-block;
        font-size: 40px;
        color: #5dacff;
      }

      .sub_title {
        margin-top: 16px;
        font-size: 18px;
      }
    }

    .teacher_info_box {
      margin-top: 100px;
      box-sizing: border-box;
      background: url("@/assets/yx_study/bg1.png") no-repeat;
      background-size: 100%;
      background-position: bottom;

      .info_box {
        position: relative;

        .info_bg {
          width: 100%;
          display: block;
        }

        .info_list {
          padding: 0 15%;
          box-sizing: border-box;
          position: absolute;
          bottom: 0;

          .info_item_box {
            transition: opacity 2s ease, transform 2s ease;
            opacity: 0;

            &.info_item_box_active {
              opacity: 1;
            }

            .info_item {
              display: flex;
              // align-items: center;
              justify-content: center;

              .intro_box {
                width: 50%;
                padding-top: 18%;
                margin-right: 7%;
                font-size: 16px;

                .name_box {
                  display: flex;
                  align-items: flex-end;

                  .name {
                    font-size: 30px;
                    font-weight: bold;
                    margin-right: 24px;
                  }
                }

                .intro1 {
                  margin-top: 16px;
                }

                .intro2 {
                  margin-top: 26px;
                  text-align: justify;
                }
              }

              .teacher {
                width: 33%;
              }
            }
          }
        }
      }
    }

    .teacher_list {
      margin-top: 24px;
      box-sizing: border-box;
      padding: 0 10%;
      display: flex;

      .teacher_item {
        width: 25%;
        cursor: pointer;
        position: relative;

        img {
          width: 100%;
          display: block;

          &.teacher,
          &.light2 {
            position: absolute;
            top: 0;
          }
        }

        .name_box {
          position: absolute;
          top: 22%;
          right: 22%;
          display: flex;

          div {
            writing-mode: vertical-lr;
          }

          .name {
            font-size: 14px;
            font-weight: 700;
            margin-right: 4px;
          }

          .title {
            font-size: 12px;
          }
        }
      }
    }
  }
}
</style>
