import { createRouter, createWebHistory } from 'vue-router'
import yxtIndex from '../views/yxt_index.vue' // 首页
import notFound from '../views/404.vue' // 404

import aboutUsRoutes from "./modules/about_us" // 关于我们
import joinUsRoutes from "./modules/join_us" // 加入我们
import yxStudysRoutes from "./modules/yx_study" // 益学研究苑
import productRoutes from "./modules/product_center" // 产品中心
import vipRoutes from "./modules/vip_center" // 会员中心
import homeRoutes from "./modules/home" // 个人中心

const routes = [
  {
    path: '/',
    name: 'yxtIndex',
    component: yxtIndex,
    meta: {
      title: '益学堂-上海益学智迅科技发展有限公司',
    },
  },

  ...aboutUsRoutes, // 关于我们
  ...joinUsRoutes, // 加入我们
  ...yxStudysRoutes, // 益学研究苑
  ...productRoutes, // 产品中心
  ...vipRoutes, // 会员中心
  ...homeRoutes, // 个人中心

  {
    path: '/:catchAll(.*)',
    name: 'notFound',
    component: notFound,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 返回的对象将会被传递给 window.scrollTo 方法
    return { top: 0 };
  },
})

router.beforeEach((to, from, next) => {
  // 从路由的 meta 信息中获取 title
  document.title = to.meta.title || '益学堂-上海益学智迅科技发展有限公司';
  next();
});

export default router
